.searchContainerDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 2rem 1rem;
  background-color: rgb(16, 37, 63);

  .searchField {
    display: flex;
    width: unset;
    align-self: stretch; // MANUAL OVERRIDE
  }
  
  @media (min-width: 1024px) {
    align-items: center;
    padding: 1.5rem 0rem 4.5rem 0rem;

    .searchField {
      width: 40rem;
      align-self: center; // MANUAL OVERRIDE
    }
    
  }
}

.searchContainerShowRecentSearches {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 2rem 1rem;
  background-color: rgb(16, 37, 63);

  .searchField {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .recentSearch {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: unset;
    align-self: stretch;
    overflow: auto; // MANUAL OVERRIDE
    margin-top: 1.5rem; // MANUAL OVERRIDE
  }
  
  .label {
    display: flex;
    align-self: center; // MANUAL OVERRIDE
  }
  
  .buttonPairList {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 1rem;

    // MANUAL OVERRIDE STARTS
    flex-wrap: wrap;
    > * {
      margin-left: 0.5rem;
      min-width: 4.5rem;
    }
    // MANUAL OVERRIDE ENDS
  }
  
  @media (min-width: 1024px) {
    align-items: center;
    padding: 1.5rem 1.5rem 4.5rem 1.5rem;

    .searchField {
      width: 40rem;
      align-self: center; // MANUAL OVERRIDE
    }
    
    .recentSearch {
      width: 40rem;
      align-self: center; // MANUAL OVERRIDE
    }
    
    .buttonPairList {
      height: unset;
      align-self: stretch;
      overflow: hidden; // MANUAL OVERRIDE
      flex-wrap: nowrap; // MANUAL OVERRIDE
    }

  }
}
