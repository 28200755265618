.customShippingInfoBlock {
  .customTextField {
    & input {
      line-height: 1.25rem;
      padding: 0;
    }
  }

  .customDropdown {
    .customDropdownSelect {
      outline: 1px solid dimgray;
      outline-offset: 0;
      border: none;
    }

    .customDropdownSelectError {
      outline: 2px solid #CC2427;
      outline-offset: 0;
      border: none;
    }
  }
}