.sportsPrecheckoutBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // MANUAL OVERRIDE STARTS
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  // MANUAL OVERRIDE ENDS

  .image {
    display: flex;
    width: unset;
    align-self: stretch;
    height: 20rem; // MANUAL OVERRIDE
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .ticketInformation {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .additionalTicketInformationList {
    display: flex;
    width: unset;
    align-self: stretch;
    border-radius: 0px; // MANUAL OVERRIDE
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    // justify-content: flex-end; // MANUAL OVERRIDE
    align-items: flex-start;
    background-color: rgb(244, 244, 244);
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .ticketQuantitySelection {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

// MANUAL OVERRIDE
@media (min-width: 1024px) {
  .sportsPrecheckoutBlock {
    display: none;
  }
}