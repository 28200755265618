.topNavCheckout {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  background-color: rgb(16, 37, 63);

  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 11.875rem; // MANUAL OVERRIDE
  }
  
  .logo {
    display: block;
    width: 7.661290168762207rem;
    height: 1.25rem;
    margin-top: 0.625rem; // MANUAL OVERRIDE
  }
  
  .accountMenu {
    display: flex;
    margin-left: 1rem;
  }
  
  @media (min-width: 1024px) {
    justify-content: flex-start;
    align-items: center;

    .logoContainer {
      justify-content: flex-start;
      padding: 1.0625rem 0rem 1.75rem 0rem;
      margin-left: 1rem; // MANUAL OVERRIDE
    }
    
    .logo {
      width: 11.875rem;
      height: 2rem; // MANUAL OVERRIDE
    }
    
    .accountMenu {
      margin-left: 1.5rem;
    }
    
  }
}

.topNavSearchInNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
  background-color: rgb(16, 37, 63);

  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 11.875rem; // MANUAL OVERRIDE
  }
  
  .logo {
    display: block;
    width: 7.661290168762207rem;
    height: 1.25rem;
  }
  
  .searchButton {
    display: flex;
  }
  
  .accountMenu {
    display: flex;
    margin-left: 1rem;
  }

  // MANUAL OVERRIDE STARTS
  .navMenuList {
    display: none;
  }
  // MANUAL OVERRIDE ENDS
  
  @media (min-width: 1024px) {
    gap: 1rem; // MANUAL OVERRIDE

    .logoContainer {
      justify-content: flex-start;
      padding: 1.0625rem 0rem 0rem 0rem;
      width: unset;
      flex: 0 1 11.875rem; // MANUAL OVERRIDE
      margin-left: 1rem; // MANUAL OVERRIDE
    }
    
    .logo {
      width: 11.875rem;
      height: 1.9375rem;
    }
    
    .accountMenu {
      margin-left: 0; // MANUAL OVERRIDE
      display: flex;
      align-self: flex-end;
    }

    // MANUAL OVERRIDE STARTS
    .searchButton {
      margin: 0rem 0.5rem;
    }
    .navMenuList {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 0 0 auto;
    }
    // MANUAL OVERRIDE ENDS
    
  }
}

.topNavSignedOut {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  background-color: rgb(16, 37, 63);

  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.875rem 0rem 0.875rem 0rem;
    width: unset;
    flex: 1 1 11.875rem; // MANUAL OVERRIDE
    margin-left: 1rem; // MANUAL OVERRIDE
  }
  
  .logo {
    display: block;
    width: 7.661290168762207rem;
    height: 1.25rem;
  }
  
  @media (min-width: 1024px) {
    justify-content: flex-start;
    align-items: center;

    .logoContainer {
      justify-content: flex-start;
      padding: 1.0625rem 0rem 1.75rem 0rem;
      height: unset;
      align-self: stretch;
    }
    
    .logo {
      width: 11.875rem;
      height: 2rem; // MANUAL OVERRIDE
    }
    
  }
}

.topNavTicketSeatSelection {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1rem 1rem 0.5rem !important; // MANUAL OVERRIDE
  background-color: rgb(16, 37, 63);
  align-self: stretch; // MANUAL OVERRIDE

  .logoContainer {
    display: none;
  }
  
  .backButton {
    display: flex;
    align-self: center; // MANUAL OVERRIDE
    margin-left: 0.5rem; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .topTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 0 1 auto; // MANUAL OVERRIDE
    max-height: 1.875rem; // MANUAL OVERRIDE
    overflow: hidden; // MANUAL OVERRIDE

    // MANUAL OVERRIDE STARTS
    h2 {
      display: unset;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100vw - 4.5rem); // full width - other elements' width
    }
    // MANUAL OVERRIDE ENDS
  }
  
  .button {
    display: flex;
    margin-left: 0.25rem;
    align-self: center; // MANUAL OVERRIDE
    margin-left: 0.5rem; // MANUAL OVERRIDE
  }
  
  .description {
    display: flex;
    // MANUAL OVERRIDE STARTS
    max-height: 1.125rem;
    overflow: hidden;
    // MANUAL OVERRIDE ENDS
  }
  
  .searchField {
    display: none;
  }
  
  .accountMenu {
    display: none;
  }
  
  @media (min-width: 1024px) {
    padding: 0rem 1rem 0rem 2rem !important; // MANUAL OVERRIDE

    .logoContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.0625rem 0rem 1.75rem 0rem;
      height: unset;
      align-self: stretch;
      flex: 0 1 11.875rem; // MANUAL OVERRIDE
      margin-left: 1rem; // MANUAL OVERRIDE
    }
    
    // MANUAL OVERRIDE STARTS
    .logo {
      display: block;
      width: 11.875rem;
      height: 1.9375rem; // MANUAL OVERRIDE
    }
    // MANUAL OVERRIDE ENDS

    .backButton {
      display: none;
    }
    
    .content {
      margin-left: 1rem; // MANUAL OVERRIDE
    }
    
    .title {
      width: unset;
      // MANUAL OVERRIDE STARTS
      h2 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: unset;
        flex: 1 1 auto;
      }
      // MANUAL OVERRIDE ENDS
    }
    
    .searchField {
      display: flex;
      width: 10rem;
      margin-left: 1.5rem;
    }
    
    .accountMenu {
      display: flex;
    }
    
  }
}

.topNavWithoutSearchInNav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  background-color: rgb(16, 37, 63);
  gap: 1rem; // MANUAL OVERRIDE

  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .logo {
    display: block;
    width: 7.661290168762207rem;
    height: 1.25rem;
  }
  
  .accountMenu {
    display: flex;
    margin-left: 1rem;
  }

  // MANUAL OVERRIDE STARTS
  .navMenuList {
    display: none;
  }
  // MANUAL OVERRIDE ENDS
  
  @media (min-width: 1024px) {
    justify-content: flex-start;
    align-items: center;

    .logoContainer {
      justify-content: flex-start;
      padding: 1.0625rem 0rem 0rem 0rem;
      width: unset;
      flex: 0 1 11.875rem; // MANUAL OVERRIDE
      margin-left: 1rem; // MANUAL OVERRIDE
    }
    
    .logo {
      width: 11.875rem;
      height: 1.9375rem;
    }
    
    .accountMenu {
      margin-left: 1.5rem;
    }

    // MANUAL OVERRIDE STARTS
    .navMenuList {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 0 auto;
    }
    // MANUAL OVERRIDE ENDS
    
  }
}
