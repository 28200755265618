.eventCardLightList {
  overflow: auto; // MANUAL OVERRIDE
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; // MANUAL OVERRIDE
  justify-content: flex-start;
  align-items: flex-start;
  // MANUAL OVERRIDE - removed align-content property
  padding: 1rem 0rem 1rem 0rem;
  gap: 1rem;
  min-width: 100%;

  // MANUAL OVERRIDE STARTS
  .eventCardLight {
    overflow: hidden;
    display: flex;
    height: 22.6875rem;
    flex: 0 0 16rem;
    max-width: calc(100% - 0rem);
  }
  // MANUAL OVERRIDE ENDS
  
  // MANUAL OVERRIDE STARTS
  @media (min-width: 768px) {
    width: unset;
    gap: 1.5rem;
    overflow: auto;

    .eventCardLight {
      max-width: calc(50% - 0.75rem);
    }
    
  }

  @media (min-width: 1024px) {
    // removed fixed width
    gap: 1.5rem;
    overflow: unset;

    .eventCardLight {
      max-width: calc(33.333333333333336% - 1rem);
    }
  }

  @media (min-width: 1440px) {
    // removed fixed width
    gap: 2rem;
    overflow: unset;

    .eventCardLight {
      width: calc(25% - 1.5rem);
      flex: 1 1 auto;
    }
    
  }
  // MANUAL OVERRIDE ENDS
}
