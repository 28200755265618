.billingInfoBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1.5rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  .blockTitle {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0; // MANUAL OVERRIDE
  }
  
  .highlightMessage {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1rem; // MANUAL OVERRIDE
  }
  
  .fieldGroup1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .firstName {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .lastName {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .address {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  // MANUAL OVERRIDE - resolved a typo
  .secondaryAddress {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .fieldGroup2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .city {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .state {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .fieldGroup3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .postalCode {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .country {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .phoneNumber {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
    // MANUAL OVERRIDE STARTS
    flex-direction: column;
    flex: 0 0 auto;
    // MANUAL OVERRIDE ENDS
  }
  
  .button {
    display: none;
  }
  
  @media (min-width: 1024px) {
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;

    .content {
      width: unset;
    }
    
    .fieldGroup1 {
      flex-direction: row;
      // MANUAL OVERRIDE - removed static width
      // MANUAL OVERRIDE STARTS
      > * {
        min-width: 50%;
      }
      // MANUAL OVERRIDE ENDS
    }
    
    .firstName {
      flex: 1 1 auto;
    }
    
    .lastName {
      flex: 1 1 auto;
      margin-top: 0;
      margin-left: 1rem;
    }
    
    // MANUAL OVERRIDE - removed static width for address
    
    // MANUAL OVERRIDE - removed static width for secondaryAddress
    
    .fieldGroup2 {
      flex-direction: row;
      // MANUAL OVERRIDE - removed static width
      // MANUAL OVERRIDE STARTS
      > * {
        min-width: 50%;
      }
      // MANUAL OVERRIDE ENDS
    }
    
    .city {
      flex: 1 1 auto;
    }
    
    .state {
      flex: 1 1 auto;
      margin-top: 0;
      margin-left: 1rem;
    }
    
    .fieldGroup3 {
      flex-direction: row;
      // MANUAL OVERRIDE - removed static width
      // MANUAL OVERRIDE STARTS
      > * {
        min-width: 50%;
      }
      // MANUAL OVERRIDE ENDS
    }
    
    .postalCode {
      flex: 1 1 auto;
    }
    
    .country {
      flex: 1 1 auto;
      margin-top: 0;
      margin-left: 1rem;
    }
    
    // MANUAL OVERRIDE - removed static width for phoneNumber
    
    .button {
      display: flex;
      margin-top: 1rem;
    }

    // there was a manual override for text variant in legacy version
    .blockTitle {
      h2 {
        font-size: 1.25rem;
      }
    }
    // MANUAL OVERRIDE ENDS
    
  }
}
