.searchFieldSearchBubble {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 100px;
  outline: 1px solid rgb(255, 255, 255);
  outline-offset: 0;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .textInput {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  @media (min-width: 1024px) {
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    width: 10rem;

  }
}

.searchFieldSearchLine {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 100px;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .textInput {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
    height: 0.1875rem; // MANUAL OVERRIDE
  }
  
  @media (min-width: 1024px) {
    width: 40rem;

  }
}
