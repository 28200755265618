.checkoutManualPage {
  // overflow: hidden; MANUAL OVERRIDE
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(244, 244, 244);

  .stepperBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .container {
    // MANUAL OVERRIDE - removed overflow: hidden
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0rem 1rem 0rem; // MANUAL OVERRIDE
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .customerInfoBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .leftContent {
    // MANUAL OVERRIDE STARTS - showing the leftContent in mobile breakpoint
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    padding: 0rem 0.25rem 0.25rem 0.25rem;
    margin-top: 0.625rem;
    // MANUAL OVERRIDE ENDS
  }
  
  .bottomContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin: 0.625rem 1rem 0 1rem; // MANUAL OVERRIDE
  }
  
  .message {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .button {
    display: flex;
    width: unset;
    align-self: stretch;
    // MANUAL OVERRIDE - removed margin-top
  }
  
  .rightContent {
    display: none;
  }
  
  @media (min-width: 1024px) {

    .stepperBlock {
      display: flex; // MANUAL OVERRIDE - showing stepperBlock in desktop breakpoint
    }
    
    .container {
      flex-direction: row;
      justify-content: center;
      padding: 1.5rem 3rem 1.5rem 3rem; // MANUAL OVERRIDE
      // MANUAL OVERRIDE - removed static width
      height: unset;
    }
    
    .customerInfoBlock {
      display: none;
      width: unset;
    }
    
    .leftContent {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      // MANUAL OVERRIDE - removed static width
      margin: 0.625rem 0 0 0.625rem; // MANUAL OVERRIDE
      gap: 1rem; // MANUAL OVERRIDE
    }
    
    .bottomContent {
      display: none;
      width: unset;
      height: unset;
    }
    
    .rightContent {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 20rem;
      // MANUAL OVERRIDE - removed left margin
      margin-top: 0.625rem; // MANUAL OVERRIDE
      flex: 0 0 auto; // MANUAL OVERRIDE
    }
    
  }
}
