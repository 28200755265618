.cardTitleBanner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  background-color: rgb(16, 37, 63);

  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .divider {
    display: none;
  }
  
  .message {
    display: none;
  }
  
  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    padding: 1rem 1.5rem 1rem 1.5rem;

    .title {
      width: unset;
    }
    
    .divider {
      display: flex;
      width: 0.0625rem;
      height: unset;
      align-self: stretch;
      margin-left: 1.5rem;
    }
    
    .message {
      display: flex;
      width: unset;
      flex: 1 1 auto;
      margin-left: 1.5rem;
    }
    
  }
}
