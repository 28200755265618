// @import '../../../../../styles/tokens';

.mapContainer {
    cursor: default !important;
    height: 100%;
    position: relative;
}

.mapControlsContainer {
    position: absolute;
    top: 0;
    right: 0;

    // .resetButton {
    //     background: rgb(255, 255, 255);
    //     border: 1px solid rgb(211, 211, 220);
    //     color: rgb(16, 104, 92);
    //     height: $sizing-3xl;
    //     width: $sizing-3xl;
    //     padding: 0;
    // }
}
