.submenuItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 0rem 0rem 0.5rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.25rem 0rem 0.25rem 0rem;
    width: unset;
    flex: 1 1 auto;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  // MANUAL OVERRIDE - removed divider
  
  &:active {
    background-color: rgb(244, 244, 244);
  
  }
  &:focus-visible {
    background-color: rgb(244, 244, 244);
    outline: 2px dashed rgb(16, 37, 63);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(244, 244, 244);
  
  }
}
