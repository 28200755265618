.ticketAvailabilityCardSoldOut {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  background-color: rgb(228, 229, 229);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .mainMessage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;
    width: unset;
    align-self: stretch;
  }
  
  .image {
    display: flex;
    width: 4.5rem;
    height: 4.5rem;
  }
  
  .label {
    display: flex;
    margin-top: 0.5rem;
  }
  
  .message {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
  .clickableSection {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
