.mobileContainer {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 0 auto;

  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.0625rem 0rem 1.0625rem 1rem;
    align-self: stretch;
    flex: 0 0 auto;

    .logo {
      overflow: hidden;
      align-self: flex-start;
      width: 11.875rem;
      flex: 0 0 1.9375rem;
    }
  }

  .step1View {
    background: linear-gradient(180deg, rgba(16, 37, 63, 0) 50%, rgba(16, 37, 63, 0.7) 80%, #10253F 100%), url('../../../resources/images/Step1.png') no-repeat left top, ;
    background-size: cover;
    height: 17.875rem;
    overflow: hidden;
    align-self: stretch;
    flex: 0 0 auto;
    background-position: 50% 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      padding: 0rem 1.54rem 0rem 1.54rem;

      h2 {
        font-size: 2rem;
        line-height: 2.625rem;
      }
    }
  }

  .step2View {
    background: linear-gradient(180deg, rgba(16, 37, 63, 0) 40%, rgba(16, 37, 63, 0.7) 75%, #10253F 100%), url('../../../resources/images/Step2Mobile.png') no-repeat left top, ;
    background-size: cover;
    height: 17.875rem;
    overflow: hidden;
    align-self: stretch;
    flex: 0 0 auto;
    background-position: 50% 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      padding: 0rem 1.54rem 0rem 1.54rem;

      h2 {
        line-height: 2.1rem;
      }
    }
  }

  .step3View {
    background: linear-gradient(180deg, rgba(16, 37, 63, 0) 45%, rgba(16, 37, 63, 0.7) 75%, #10253F 100%), url('../../../resources/images/Step3Mobile.png') no-repeat left top, ;
    background-size: cover;
    height: 17.875rem;
    overflow: hidden;
    align-self: stretch;
    flex: 0 0 auto;
    background-position: 50% 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      padding: 0rem 1.54rem 0rem 1.54rem;

      h2 {
        line-height: 1.94875rem;
      }
    }
  }

  .onBoardingBlockMobile {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    padding: 0rem 1.54rem 0rem 1.54rem;

    .message {
      margin-top: 1.5rem;
      p {
        font-size: 0.75rem;
        line-height: 1.125rem;
      }
    }

    .capOneLink {
      text-decoration: underline rgb(255, 255, 255) !important;
      font-weight: 700;
      color: rgb(255, 255, 255);
    }

    .divider {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      margin: 1.5rem 0rem;
    }

    .subHeader {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      padding: 0.625rem 0rem 0.875rem 0rem;

      h2 {
        font-size: 1.25rem;
        line-height: 1.875rem;
        font-weight: 400;
      }

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .description {
      padding: 1rem 0rem 0rem 0rem;

      >* {
        word-wrap: break-word;
      }

      &:nth-child(1) {
        padding: 0;
      }
    }
  }

  .bottomView {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: stretch;
    flex: 1 0 auto;
    bottom: 0;
    padding: 2.625rem 1.54rem 2rem 1.54rem;

    .button {
      display: flex;
      height: 3rem;
      flex: 0 0 auto;
      width: 100%;
      margin-bottom: 1.875rem;
    }

    .footer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      flex: 0 0 auto;

      p {
        text-align: center;
        font-size: 0.75rem;
        line-height: 1.5rem;
      }
    }
  }
}

.desktopContainer {
  display: none;
}

@media (min-width: 1024px) {
  .desktopContainer {
    overflow: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 0 auto;

    .onBoardingBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      width: 100%;
      flex: 1 1 auto;

      .message {
        p {
          font-size: 0.75rem;
          line-height: 1.125rem;
          margin-top: 0.8125rem
        }
      }

      .capOneLink {
        text-decoration: underline rgb(255, 255, 255) !important;
        font-weight: 700;
        color: rgb(255, 255, 255);
      }
    }

    .leftView {
      padding: 0rem 0rem 0rem 3rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 50%;

      .logoContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1.0625rem 0rem 0rem 0rem;
        align-self: stretch;
        flex: 0 0 auto;

        .logo {
          overflow: hidden;
          align-self: flex-start;
          width: 11.875rem;
          flex: 0 0 1.9375rem;
        }
      }

      .header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        align-self: stretch;
        flex: 0 0 auto;
        padding: 4.375rem 0rem 0rem 0rem;
      }

      .divider {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        align-self: stretch;
        flex: 0 0 auto;
        width: 33rem;
        margin: 1rem 0rem 1rem 0rem;

        @media (max-width: 1439px) {
          &:nth-child(1) {
            margin-top: 0;
          }
        }
      }

      .subHeader {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        align-self: stretch;
        flex: 0 0 auto;
        padding: 0.625rem 0rem 0.875rem 0rem;
        width: 33.125rem;

        h2 {
          font-weight: 600;
        }

        @media (max-width: 1439px) {
          &:nth-child(1) {
            margin-top: 0;
          }
        }
      }

      .button {
        display: flex;
        height: 3rem;
        flex: 0 0 auto;
        width: 13.445rem;
        margin-bottom: 3.31rem;
      }

      .description {
        padding: 1rem 0rem 0rem 0rem;

        &:nth-child(1) {
          padding: 0;
        }
      }

      .footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        align-self: stretch;
        flex: 0 0 auto;
        margin: 2.75rem 0 2.5rem 0;

        p {
          line-height: 1.5rem;
        }
      }

    }

    .step1View {
      overflow: hidden;
      align-self: stretch;
      height: 100%;
      flex: 1 1 auto;
    }

    .step2View {
      overflow: hidden;
      align-self: stretch;
      height: 100%;
      flex: 1 1 auto;
    }

    .step3View {
      height: 100%;
      background-position: 50% 50%;
      overflow: hidden;
      align-self: stretch;
      flex: 1 1 auto;
    }
  }

  .mobileContainer {
    display: none;
  }
}