.toolTip {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 4px;
  background-color: rgb(20, 20, 20);
  width: 16rem;

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
