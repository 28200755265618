.dropdown {
  display: flex;
}

.dropdownToggle {
  padding: 1.5rem 0.75rem 0rem 0.75rem;
}
.dropdownToggle:focus-visible {
  outline: 2px dashed rgb(255, 255, 255);
  outline-offset: -2px;
}

.dropdownMenu {
  width: 100%;
  z-index: 2;
  transform: translate3d(0, 4.75rem, 0) !important;
}
