/**
 *  Fonts:
 *    - Optimist (all weights)
 */
@font-face {
    font-family: 'Optimist';
    font-weight: 200;
    src: local('Optimist'), url(../fonts/Optimist/Optimist_W_XLt.woff) format('woff');
}

@font-face {
    font-family: 'Optimist';
    font-weight: 200;
    font-style: italic;
    src: local('Optimist'), url(../fonts/Optimist/Optimist_W_XLtIt.woff) format('woff');
}

@font-face {
    font-family: 'Optimist';
    font-weight: 300;
    src: local('Optimist'), url(../fonts/Optimist/Optimist_W_Lt.woff) format('woff');
}

@font-face {
    font-family: 'Optimist';
    font-weight: 300;
    font-style: italic;
    src: local('Optimist'), url(../fonts/Optimist/Optimist_W_LtIt.woff) format('woff');
}

@font-face {
    font-family: 'Optimist';
    font-weight: 400;
    src: local('Optimist'), url(../fonts/Optimist/Optimist_W_Rg.woff) format('woff');
}

@font-face {
    font-family: 'Optimist';
    font-weight: 400;
    font-style: italic;
    src: local('Optimist'), url(../fonts/Optimist/Optimist_W_It.woff) format('woff');
}

@font-face {
    font-family: 'Optimist';
    font-weight: 600;
    src: local('Optimist'), url(../fonts/Optimist/Optimist_W_SBd.woff) format('woff');
}

@font-face {
    font-family: 'Optimist';
    font-weight: 600;
    font-style: italic;
    src: local('Optimist'), url(../fonts/Optimist/Optimist_W_SBdIt.woff) format('woff');
}

@font-face {
    font-family: 'Optimist';
    font-weight: 700;
    src: local('Optimist'), url(../fonts/Optimist/Optimist_W_Bd.woff) format('woff');
}

@font-face {
    font-family: 'Optimist';
    font-weight: 700;
    font-style: italic;
    src: local('Optimist'), url(../fonts/Optimist/Optimist_W_BdIt.woff) format('woff');
}