.breakDownBlock {

  & ul {
    margin-top: 0 !important;
    padding-left: 0;
    align-self: stretch;

    & li {
      line-height:inherit;
      justify-content: space-between;
      flex-wrap: wrap;
      display:flex;
      &.lastRow{
        padding-top:8px;
      }
    }
  }

  span {
    font-size: 0.75rem;
    color:#7a7a7a;
    font-weight: 400;
    line-height:18px;

    &.alignLeft, &.totalLabel{
      flex-grow: 1;
    }

    &.totalLabel{
      vertical-align: bottom;
    }

    &.alignRight, &.total, &.delivery {
      justify-content: flex-end;
      flex-direction: row;
      align-items: flex-end;
      align-self: stretch;
      flex-grow: 3;
      text-align: right;
    }

    &.total{
      font-size:1.063rem;
      color: #444649;
    }

    &.delivery{
      color: #007F9B;
    }
  }
}
