// MANUAL OVERRIDE STARTS
.generalPrecheckoutBlock {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);

  .cardNavigation {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .exclusiveEventBanner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    > * {
      margin-top: 0.625rem;
    }
    align-self: stretch;
    flex: 0 0 20rem;
  }

  .cardInfoHeader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .ticketInformation{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    width: 100%; // MANUAL OVERRIDE
  }

  .additionalTicketInformationList, .breakdown  {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    align-self: stretch;
    flex: 0 0 auto;
    gap: 1rem; // MANUAL OVERRIDE
  }

  .breakdown{
    padding: 0 1rem 1rem 1rem;
  }

  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    background-color: rgb(255, 255, 255);
  }

  .ticketQuantitySelection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

}

@media (min-width: 1024px) {
  .generalPrecheckoutBlock {
    display: none;
  }
}
// MANUAL OVERRIDE ENDS
