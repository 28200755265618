.ticketTypeSelectionBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .image {
    display: none;
  }
  
  .exclusiveEventBanner {
    display: flex;
    width: unset;
    height: 11rem;
    align-self: stretch;
  }
  
  .content {
    display: none;
  }
  
  .cardInfoHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .ticketList {
    // display: flex; // MANUAL OVERRIDE
    // width: unset; // MANUAL OVERRIDE
    align-self: stretch;
  }
  
  .descriptionContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1rem 1.5rem 1rem;
    background-color: rgb(244, 244, 244);
    width: unset;
    align-self: stretch;
  }
  
  .description {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1024px) {
    padding: 1.5rem 0rem 1.5rem 0rem;
    background-color: rgb(244, 244, 244);

    .image {
      display: flex;
      width: unset;
      height: 10rem;
      align-self: stretch;
    }
    
    .exclusiveEventBanner {
      display: none;
      width: unset;
      height: unset;
    }
    
    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: unset;
      align-self: stretch;
      margin-top: 2rem;
      > * {
        margin-left: 2rem;
      }
    }
    
    .cardInfoHeader {
      display: none;
      width: unset;
    }
    
    .ticketList {
      display: none;
      width: unset;
    }
    
    .descriptionContainer {
      display: none;
      width: unset;
    }

    // MANUAL OVERRIDE STARTS
    .selectTicket {
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      max-height: calc(100vh - 18.75rem); // full screen height - header part
      flex: 0 0 22.5rem;
      &:nth-child(1) {
        margin-left: 0;
      }
    }

    // MANUAL OVERRIDE
    .ticketsSoldOut {
      width: 22rem;
    }

    .rightContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
      word-break: break-all;
    }
    // MANUAL OVERRIDE ENDS
    
  }
}

// MANUAL OVERRIDE STARTS
.preCheckOutContainer {
  margin-right: 2rem !important;
  margin-left: 0 !important;
}
.ticketsSoldOutMobile {
  width: 100%;
  @media (min-width: 1024px) {
    display: none !important;
  }
}
// MANUAL OVERRIDE ENDS