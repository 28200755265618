.searchMatchesBlockMatch {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(244, 244, 244);
  padding: 1rem 0rem 1rem 0rem; //MANUAL OVERRIDE


  .searchResult {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .eventsCardList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }

  .seeMoreButton {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }

}

.searchMatchesBlockNoMatch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 1rem 2.5rem 1rem;
  background-color: rgb(244, 244, 244);

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center; // MANUAL OVERRIDE
  }

  .image {
    display: flex;
    width: 6rem;
    height: 6rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 18rem;
    margin-top: 2rem;
  }

  .searchResult {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .label {
    display: flex;
  }

  .searchedValue {
    display: flex;
    margin-left: 0.5rem;
  }

  .message {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
    justify-content: center; //MANUAL OVERRIDE
  }

  .button {
    display: flex;
    width: 18rem;
    margin-top: 2rem;
  }

  @media (min-width: 1024px) {

    .image {
      width: 6rem; // MANUAL OVERRIDE
      height: 6rem; // MANUAL OVERRIDE
    }

    .content {
      width: unset;
    }

    .button {
      width: 18rem; // MANUAL OVERRIDE
    }

  }
}

// MANUAL OVERRIDE START
.searchMatchesBlockLoading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 1rem 2.5rem 1rem;

  >* {
    margin-top: 2rem;
  }

  align-self: stretch;
  flex: 0 0 17.75rem;

  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  background-color: rgb(244, 244, 244);

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    >* {
      margin-top: 2rem;
    }

    align-self: center;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    width: 2.5rem;
    flex: 0 0 2.5rem;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}

// MANUAL OVERRIDE END