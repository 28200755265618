.searchModalHeaderDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  background-color: rgb(0, 19, 43);

  .closeButton {
    display: flex;
    background-color: transparent; // MANUAL OVERRIDE
  }
  
  .searchField {
    display: flex;
    width: unset;
    max-height: 2.4375rem; // MANUAL OVERRIDE
    // height: unset; // MANUAL OVERRIDE
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 1rem;
  }
  
}

.searchModalHeaderShowRecentSearches {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  background-color: rgb(0, 19, 43);

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .closeButton {
    display: flex;
  }
  
  .searchField {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 1rem;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .label {
    display: flex;
    width: 20.125rem;
  }
  
  .buttonPairList {
    display: flex;
    width: 20.125rem;
    margin-top: 0.5rem;
  }
  
}
