.footerDark {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(0, 19, 43);
  width: unset; // MANUAL OVERRIDE

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 1rem 0.75rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .logo {
    display: block;
    width: 4.625rem;
    height: 1.25rem; // MANUAL OVERRIDE
  }
  
  .footerMenuList {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }

  // MANUAL OVERRIDE STARTS
  .divider {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    height: 2px;
  }
  // MANUAL OVERRIDE ENDS
  
  @media (min-width: 1024px) {
    align-items: center;
    width: unset;

    .content {
      padding: 1rem 1rem 1rem 1rem;
    }
    
    .logo {
      width: 4.289999961853027rem;
      height: 1.5rem;
    }
    
  }
}

.footerDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255); // MANUAL OVERRIDE
  // MANUAL OVERRIDE - removed static width
  align-self: stretch; // MANUAL OVERRIDE

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 1rem 0.75rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .logo {
    display: block;
    width: 4.625rem;
    height: 1.5625rem; // MANUAL OVERRIDE
  }

  // MANUAL OVERRIDE STARTS
  .divider {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    height: 2px;
  }
  // MANUAL OVERRIDE ENDS
  
  .footerMenuList {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  @media (min-width: 1024px) {
    align-items: center;
    width: unset;
    background-color: rgb(244, 244, 244); // MANUAL OVERRIDE

    .content {
      padding: 1rem 1rem 1rem 1rem;
    }
    
    .logo {
      width: 4.25rem; // MANUAL OVERRIDE
      height: 1.5rem;
    }
    
  }
}

// MANUAL OVERRIDE
@media (min-width: 1440px) {
  .footer {
    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1rem 2rem 1rem 2rem;
      align-self: stretch;
      flex: 0 0 auto;
    }
  }
}
