.sportsPrecheckoutBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .image {
    display: flex;
    width: unset;
    align-self: stretch;
    height: 20rem; // MANUAL OVERRIDE
  }
  
  .ticketInformation {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  // MANUAL OVERRIDE
  .additionalTicketInformationList, .breakdown {
    display: flex;
    width: unset;
    align-self: stretch;
    padding: 1rem 1rem 1rem 1rem;
    > * {
      margin-top: 1rem;
    }
    flex: 0 0 auto;
  }

  .breakdown{
    padding: 0 1rem 1rem 1rem;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    background-color: rgb(244, 244, 244);
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .ticketQuantitySelection {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

// MANUAL OVERRIDE
@media (min-width: 1024px) {
  .sportsPrecheckoutBlock {
    display: none;
  }
}
