.searchReturnItemDarkBackground {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(16, 37, 63);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.6875rem 1rem 0.75rem 1rem; // MANUAL OVERRIDE
    width: unset;
    align-self: stretch;
    border-left: 0.375rem solid transparent; // MANUAL OVERRIDE
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .description {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
    height: 0.0625rem; // MANUAL OVERRIDE
  }

  // MANUAL OVERRIDE STARTS
  .nextButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
  }

  &:hover {
    .content {
      border-left: 0.375rem solid rgb(255, 255, 255);
      background: linear-gradient(
        1.5708rad,
        rgba(255, 255, 255, 0.4000000059604645) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  @media (min-width: 1024px) {
    .nextButton {
      display: none;
    }
  }
  // MANUAL OVERRIDE END
  
}

.searchReturnItemLightBackground {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(62, 75, 89);

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.6875rem 1rem 0.75rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .description {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .nextButton {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .divider1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
