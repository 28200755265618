.seatSelectionBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden; // MANUAL OVERRIDE

  .selectTicket {
    display: none;
  }
  
  .instructionLabel {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .ticketList {
    overflow: auto; // MANUAL OVERRIDE
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1024px) {
    background-color: rgb(255, 255, 255);

    .selectTicket {
      display: flex;
      width: unset;
      align-self: stretch;
    }
    
    .instructionLabel {
      // display: none; // MANUAL OVERRIDE
      width: unset;
    }
    
    .ticketList {
      // display: none; // MANUAL OVERRIDE
      width: unset;
    }
    
  }
}
