.submenuItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .divider {
    display: block;
    width: 0.25rem;
    height: unset;
    align-self: stretch;
    background-color: transparent; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.25rem 0rem 0.25rem 0rem;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:active {
    background: linear-gradient(1.5708rad, rgba(255, 255, 255, 0.4000000059604645) 0%, rgba(255, 255, 255, 0) 100%);

    // MANUAL OVERRIDE STARTS
    .divider {
      background: rgb(255, 255, 255);
    }
    // MANUAL OVERRIDE ENDS
  
  }
  &:hover {
    background: linear-gradient(1.5708rad, rgba(255, 255, 255, 0.4000000059604645) 0%, rgba(255, 255, 255, 0) 100%);

    // MANUAL OVERRIDE STARTS
    .divider {
      background: rgb(255, 255, 255);
    }
    // MANUAL OVERRIDE ENDS
  
  }
}
