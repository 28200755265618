.eventTypeTagBlue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem 0.5rem 0.3125rem 0.5rem;
  border-radius: 12px;
  background-color: rgb(225, 245, 255);

  .label {
    display: flex;
  }
  
}

.eventTypeTagCardholderExclusive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem 0.5rem 0.3125rem 0.5rem;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  height: 1.375rem; // MANUAL OVERRIDE
  width: 8.125rem; // MANUAL OVERRIDE

  .label {
    display: flex;
  }
  
}
