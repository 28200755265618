.buttonIconTextMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.30000001192092896);
  width: 2rem;
  height: 2rem;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.5600000023841858);

  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.4000000059604645);

  }
}

.buttonIconTextSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 100px;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }

  &:active {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.20000000298023224);

  }

  &:focus-visible {
    border-radius: 4px;
    outline: 1px dashed rgb(255, 255, 255);
    outline-offset: 0;

  }

  &:hover {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.20000000298023224);

  }
}

.buttonIconTextTextOnGreyExtraSmall {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }

  .text {
    display: flex;
    margin-left: 0.25rem;
  }

  &:focus-visible {
    outline: 1px dashed rgb(2, 101, 151);
    outline-offset: 0;

  }
}

.buttonIconTextTextExtraSmall {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
  }

  .text {
    display: flex;
    margin-left: 0.25rem;
  }

  &:focus-visible {
    outline: 1px dashed rgb(2, 118, 177);
    outline-offset: 0;

  }
}

.buttonIconTextTextSmall {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }

  .text {
    display: flex;
    margin-left: 0.25rem;
  }

  &:focus-visible {
    outline: 1px dashed rgb(2, 118, 177);
    outline-offset: 0;

  }
}

.buttonTextIconTextMedium {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .text {
    display: flex;
  }

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.25rem;
  }

  &:focus-visible {
    outline: 1px dashed rgb(61, 61, 61);
    outline-offset: 0;

  }
}

.buttonTextIconTextSmall {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .text {
    display: flex;
  }

  .icon {
    display: flex; // MANUAL OVERRIDE
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.25rem;
    align-self: center; // MANUAL OVERRIDE
  }

  &:active {

    .content {
      align-items: center;
    }

  }

  &:focus-visible {
    outline: 1px dashed rgb(2, 101, 151);
    outline-offset: 0;

  }
}

.buttonTextIconTextUnderlineSmall {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .text {
    display: flex;
  }

  .icon {
    display: flex; // MANUAL OVERRIDE
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.25rem;
    align-self: center; // MANUAL OVERRIDE
  }

}

.buttonTextIconTextWhiteSmall {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .text {
    display: flex;
  }

  .icon {
    display: flex; // MANUAL OVERRIDE
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.25rem;
    align-self: center; // MANUAL OVERRIDE
  }

  &:active {

    .content {
      align-items: center;
    }

    // MANUAL OVERRIDE STARTS
    .text {
      & p {
        text-decoration: underline;
      }
    }

    // MANUAL OVERRIDE ENDS

  }

  &:focus-visible {
    outline: 1px dashed rgb(255, 255, 255);
    outline-offset: 0;

    // MANUAL OVERRIDE STARTS
    .text {
      & p {
        text-decoration: underline;
      }
    }

    // MANUAL OVERRIDE ENDS
  }

  // MANUAL OVERRIDE STARTS
  &:hover {
    .text {
      & p {
        text-decoration: underline;
      }
    }
  }

  // MANUAL OVERRIDE ENDS
}

.buttonTextContainedGreenLarge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5625rem 1rem 0.5625rem 1rem;
  border-radius: 4px;
  background-color: rgb(37, 129, 14);

  .text {
    display: flex;
  }

  &:active {
    background-color: rgb(24, 83, 9);

  }

  &:focus-visible {
    outline: 2px dashed rgb(255, 255, 255);
    outline-offset: 0;

  }

  &:hover {
    background-color: rgb(30, 106, 11);

  }
}

.buttonTextContainedGreenMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  border-radius: 2px;
  background-color: rgb(37, 129, 14);

  .text {
    display: flex;
  }

  &:active {
    background-color: rgb(24, 83, 9);

  }

  &:focus-visible {
    outline: 2px dashed rgb(255, 255, 255);
    outline-offset: 0;

  }

  &:hover {
    background-color: rgb(30, 106, 11);

  }
}

.buttonTextContainedLarge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5625rem 1rem 0.5625rem 1rem;
  border-radius: 4px;
  background-color: rgb(2, 118, 177);

  .text {
    display: flex;
  }

  &:active {
    background-color: rgb(1, 78, 116);

  }

  &:disabled {
    background-color: rgb(244, 244, 244);

  }

  &:focus-visible {
    outline: 2px dashed rgb(255, 255, 255);
    outline-offset: 0;

  }

  &:hover {
    background-color: rgb(2, 101, 151);

  }
}

.buttonTextContainedMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  border-radius: 2px;
  background-color: rgb(2, 118, 177);

  .text {
    display: flex;
  }

  &:active {
    background-color: rgb(1, 78, 116);

  }

  &:disabled {
    background-color: rgb(244, 244, 244);

  }

  &:focus-visible {
    outline: 2px dashed rgb(255, 153, 0);
    outline-offset: 0;

  }

  &:hover {
    background-color: rgb(2, 101, 151);

  }
}

.buttonTextContainedWhiteLarge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5625rem 1rem 0.5625rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);

  .text {
    display: flex;
  }

  &:active {
    background-color: rgb(228, 229, 229);

  }

  &:focus-visible {
    outline: 2px dashed rgb(2, 101, 151);
    outline-offset: 0;

  }

  &:hover {
    background-color: rgb(244, 244, 244);

  }
}

.buttonTextContainedWhiteMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0.3125rem 0.5rem 0.375rem 0.75rem;
  background-color: rgba(255, 255, 255, 0.3199999928474426);

  .text {
    display: flex;
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.5600000023841858);

  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.4000000059604645);

  }
}

.buttonTextOutlinePillMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  border-radius: 100px;
  outline: 1px solid rgb(2, 118, 177);
  outline-offset: 0;

  .text {
    display: flex;
  }

  &:active {
    background-color: rgb(1, 78, 116);
    outline: 1px solid rgb(1, 78, 116);

  }

  &:disabled {
    background-color: rgb(244, 244, 244);

  }

  &:focus-visible {
    outline: 1px dashed rgb(2, 118, 177);

  }

  &:hover {
    background-color: rgb(2, 101, 151);
    outline: 1px solid rgb(2, 101, 151);

  }
}

.buttonTextOutlineMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem 0.25rem 1rem;
  border-radius: 2px;
  outline: 1px solid rgb(2, 118, 177);
  outline-offset: 0;

  .text {
    display: flex;
  }

  &:active {
    background-color: rgb(1, 78, 116);
    outline: 1px solid rgb(1, 78, 116);

    // MANUAL OVERRIDE STARTS
    .text {

      & p {
        color: rgb(255, 255, 255);
      }
    }

    // MANUAL OVERRIDE ENDS

  }

  &:disabled {
    background-color: rgb(244, 244, 244);

  }

  &:focus-visible {
    outline: 1px dashed rgb(2, 118, 177);

  }

  &:hover {
    background-color: rgb(2, 101, 151);
    outline: 1px solid rgb(2, 101, 151);

    // MANUAL OVERRIDE STARTS
    .text {
      & p {
        color: rgb(255, 255, 255);
      }
    }

    // MANUAL OVERRIDE ENDS

  }
}

.buttonTextOutlineSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.0625rem 1rem 0.125rem 1rem;
  border-radius: 2px;
  outline: 1px solid rgb(255, 255, 255);
  outline-offset: 0;

  .text {
    display: flex;
  }

  &:active {
    background-color: rgb(229, 229, 229);
    outline: 1px solid rgb(228, 229, 229);

  }

  &:disabled {
    background-color: rgb(244, 244, 244);

  }

  &:focus-visible {
    outline: 1px dashed rgb(255, 255, 255);

  }

  &:hover {
    background-color: rgb(255, 255, 255);

  }
}

.buttonTextTextDarkMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  .text {
    display: flex;

    // MANUAL OVERRIDE STARTS
    p {
      text-decoration: underline;
    }

    // MANUAL OVERRIDE ENDS
  }

  &:focus-visible {
    outline: 1px dashed rgb(61, 61, 61);
    outline-offset: 0;

    // MANUAL OVERRIDE STARTS
    .text {
      & p {
        text-decoration: underline;
      }
    }

    // MANUAL OVERRIDE ENDS
  }

  // MANUAL OVERRIDE STARTS
  &:hover {
    .text {
      & p {
        text-decoration: underline;
      }
    }
  }

  &:active {
    .text {
      & p {
        text-decoration: underline;
      }
    }
  }

  // MANUAL OVERRIDE ENDS
}

.buttonTextTextGreySmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  .text {
    display: flex;

    // MANUAL OVERRIDE STARTS
    p {
      text-decoration: underline;
    }

    // MANUAL OVERRIDE ENDS
  }

  // MANUAL OVERRIDE STARTS
  &:hover {
    .text {
      & p {
        text-decoration: underline;
      }
    }
  }

  &:active {
    .text {
      & p {
        text-decoration: underline;
      }
    }
  }

  // MANUAL OVERRIDE ENDS

}

.buttonTextTextMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  .text {
    display: flex;

    // MANUAL OVERRIDE STARTS
    p {
      text-decoration: underline;
    }

    // MANUAL OVERRIDE ENDS
  }

  &:focus-visible {
    outline: 1px dashed rgb(2, 118, 177);
    outline-offset: 0;

    // MANUAL OVERRIDE STARTS
    .text {
      & p {
        text-decoration: underline;
      }
    }

    // MANUAL OVERRIDE ENDS

  }

  // MANUAL OVERRIDE STARTS
  &:hover {
    .text {
      & p {
        text-decoration: underline;
      }
    }
  }

  &:active {
    .text {
      & p {
        text-decoration: underline;
      }
    }
  }

  // MANUAL OVERRIDE ENDS
}

.buttonTextTextSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  .text {
    display: flex;

    // MANUAL OVERRIDE STARTS
    & p {
      text-decoration: underline;
    }

    // MANUAL OVERRIDE ENDS
  }

  &:focus-visible {
    outline: 1px dashed rgb(2, 118, 177);
    outline-offset: 0;

    .text {

      // MANUAL OVERRIDE STARTS
      p {
        text-decoration: underline;
      }

      // MANUAL OVERRIDE ENDS
    }
  }

  &:hover {
    .text {

      // MANUAL OVERRIDE STARTS
      p {
        text-decoration: underline;
      }

      // MANUAL OVERRIDE ENDS
    }
  }

  &:active {
    .text {

      // MANUAL OVERRIDE STARTS
      p {
        text-decoration: underline;
      }

      // MANUAL OVERRIDE ENDS
    }
  }
}

.buttonTextTextWhiteMedium {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  .text {
    display: flex;

    // MANUAL OVERRIDE STARTS
    p {
      text-decoration: underline;
    }

    // MANUAL OVERRIDE ENDS
  }

  &:focus-visible {
    outline: 1px dashed rgb(255, 255, 255);
    outline-offset: 0;

    // MANUAL OVERRIDE STARTS
    .text {
      & p {
        text-decoration: underline;
      }
    }

    // MANUAL OVERRIDE ENDS
  }

  // MANUAL OVERRIDE STARTS
  &:hover {
    .text {
      & p {
        text-decoration: underline;
      }
    }
  }

  &:active {
    .text {
      & p {
        text-decoration: underline;
      }
    }
  }

  // MANUAL OVERRIDE ENDS
}

.buttonTextTextWhiteSmall {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.0625rem 0rem 0.125rem 0rem;
  border-radius: 2px;

  .text {
    display: flex;

    // MANUAL OVERRIDE STARTS
    p {
      text-decoration: none;
    }

    // MANUAL OVERRIDE ENDS
  }

  &:focus-visible {
    outline: 1px dashed rgb(255, 255, 255);
    outline-offset: 0;

    // MANUAL OVERRIDE STARTS
    .text {
      & p {
        text-decoration: none;
      }
    }

    // MANUAL OVERRIDE ENDS
  }

  // MANUAL OVERRIDE STARTS
  &:hover {
    .text {
      & p {
        text-decoration: underline;
      }
    }
  }

  &:active {
    .text {
      & p {
        text-decoration: underline;
      }
    }
  }

  // MANUAL OVERRIDE ENDS
}