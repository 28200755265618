.clickableSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    flex: 1 1 auto;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;

    // MANUAL OVERRIDE STARTS
    & p {
      width: 100%;
      text-align: center;
    }
    // MANUAL OVERRIDE ENDS
  }
  
  .icon {
    display: flex; // MANUAL OVERRIDE
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.25rem;
    align-self: center; // MANUAL OVERRIDE
  }
  
  &:focus-visible {
    background-color: rgb(225, 245, 255);
    outline: 2px dashed rgb(2, 101, 151);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(225, 245, 255);
  
  }
}
