.customSelect {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('../../../resources/icons/ChevronDown.svg') 96% / 1.5rem no-repeat white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #3D3D3D; // MANUAL OVERRIDE
  border: 0;
}

.customOption {
  color: white;
  background-color: rgb(105, 105, 105);
  padding: 0 10px;
}