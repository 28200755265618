.primaryBannerBackground {
  background-image: url("../../../resources/legacyImages/PrimaryBanner.png");
  background-size: cover;
}

.exclusivePrimaryBannerBackground {
  background-image: url("../../../resources/legacyImages/ExclusiveEventBannerResponsive.png");
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  .message {
    min-height: 2.25rem;
  }

  @media (min-width: 1024px) {
    background-image: url("../../../resources/legacyImages/ExclusiveEventBanner.png");

    .message {
      min-height: auto;
    }
  }
}