.dropdown {
  display: flex;
  flex: 0 0 auto;
}
.dropdownToggle {
  display: flex;
}

.dropdownMenu {
  z-index: 2;
  margin-top: 0.5rem !important; // to override inline css
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}

.flatpickr {
  display: none;
}