.buttonPairList {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; // MANUAL OVERRIDE
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;

  .buttonPair {
    display: flex;
    width: calc(33.333333333333336% - 0.3333333333333333rem);
    overflow: hidden; // MANUAL OVERRIDE
    // MANUAL OVERRIDE
    p {
      font-size: 0.875rem;
    }
  }
  
}
