.dropdown {
  display: flex;
}
.dropdownToggle {
  display: flex;
}

.dropdownMenu {
  width: 100%;
  z-index: 2;
  transform: translate3d(0, 4rem, 0) !important;
}

.searchField {
  display: none;
}

@media (min-width: 1024px) {
  .searchField {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    align-self: center;
    // flex: 0 0 18rem; // MANUAL OVERRIDE - to have enough width to show close button
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }
}