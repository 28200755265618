.newDropdownMenuDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.20000000298023224);
  width: 20rem;

  .newDropdownSelectList {
    display: flex;
    width: unset;
    align-self: stretch;
    // MANUAL OVERRIDE STARTS
    max-height: 28rem;
    overflow: hidden;
    overflow-y: auto;
    // MANUAL OVERRIDE ENDS
  }
  
}

.newDropdownMenuWithSearch {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.20000000298023224);
  width: 20rem;

  .textInput {
    display: flex;
    width: unset;
    align-self: stretch;

    // MANUAL OVERRIDE STARTS
    & path {
      fill: rgb(20, 20, 20);
    }
    & input::placeholder {
      color: rgb(125, 125, 125);
    }
    & input {
      align-self: center;
    }
    // MANUAL OVERRIDE ENDS
  }
  
  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
  .newDropdownSelectList {
    display: flex;
    width: unset;
    align-self: stretch;
    // MANUAL OVERRIDE STARTS
    border-radius: 0rem 0rem 0.25rem 0.25rem;
    max-height: 24rem;
    overflow: hidden;
    overflow-y: auto;
    // MANUAL OVERRIDE ENDS
  }
  
}
