.topDisclaimerBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  background: #141414;
  position: fixed;
}

.topDisclaimerText {
  font-family: 'Optimist';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #fff;
}
