// MANUAL OVERRIDE START
.searchResultsBlockMobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // MANUAL OVERRIDE END

  .searchCategories {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .searchReturnList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    .nextButton {
      display: none;
    }
  }
}

.searchResultsBlock {
  
  .leftContent {
    display: none;
  }

  .rightContent {
    display: none;
  }
}

@media (min-width: 1024px) {

  // MANUAL OVERRIDE START
  .searchResultsBlockMobile {
    display: none;
  }
  // MANUAL OVERRIDE END

  .searchResultsBlock {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 1.5rem 0rem 3rem 0rem;
    background-color: rgb(255, 255, 255);
  
    .searchCategories {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .searchReturnList {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }
    
    .menuContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      > * {
        margin-left: 1rem;
      }
      align-self: flex-start;
      flex: 0 0 58rem;
    }
  
    .leftContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1; // MANUAL OVERRIDE
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  
    .rightContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1; // MANUAL OVERRIDE
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }
}