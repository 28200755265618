.shippingInfoBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1.5rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  .blockTitle {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .highlightMessage {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .checkBoxItem {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .fieldGroup1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .firstName {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .lastName {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .address {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .secondaryAddress {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .fieldGroup2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .city {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .state {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .fieldGroup3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .postalCode {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .country {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .phoneNumber {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .button {
    display: none;
  }
  
  @media (min-width: 1024px) {
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;

    .content {
      width: unset;
    }
    
    .fieldGroup1 {
      flex-direction: row;
      // width: 59rem; // MANUAL OVERRIDE
    }
    
    .firstName {
      flex: 1 1 auto;
    }
    
    .lastName {
      flex: 1 1 auto;
      margin-top: 0;
      margin-left: 1rem;
    }
    
    .address {
      // width: 59rem; // MANUAL OVERRIDE
    }
    
    .secondaryAddress {
      // width: 59rem; // MANUAL OVERRIDE
    }
    
    .fieldGroup2 {
      flex-direction: row;
      // width: 59rem; // MANUAL OVERRIDE
    }
    
    .city {
      flex: 1; // MANUAL OVERRIDE
    }
    
    .state {
      flex: 1; // MANUAL OVERRIDE
      margin-top: 0;
      margin-left: 1rem;
    }
    
    .fieldGroup3 {
      flex-direction: row;
      // width: 59rem; // MANUAL OVERRIDE
    }
    
    .postalCode {
      flex: 1; // MANUAL OVERRIDE
    }
    
    .country {
      flex: 1; // MANUAL OVERRIDE
      margin-top: 0;
      margin-left: 1rem;
    }
    
    .phoneNumber {
      // width: 59rem; // MANUAL OVERRIDE
    }
    
    .button {
      display: flex;
      margin-top: 1rem;
    }
    
  }
}
