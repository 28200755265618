.container {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    height: 100%;
    background-color: rgb(16, 37, 63);

    .capOneLink {
        text-decoration: underline !important;
        font-weight: 700;
        color: rgb(255, 255, 255);
    }
}

.loader {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background-color: rgb(16, 37, 63);
}

@media (min-width: 1024px) {
    .container {
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        align-self: stretch;
        flex: 0 0 auto;
        height: 100%;
        background-color: rgb(16, 37, 63);
    }

    .capOneLink {
        text-decoration: underline !important;
        font-weight: 700;
        color: rgb(255, 255, 255);
    }
}