.newDropdownClosedDark {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  border-radius: 100px;
  background-color: rgb(16, 37, 63);
  outline: 1px solid rgb(62, 75, 89);
  outline-offset: 0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.20000000298023224);

  .text {
    display: flex;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.25rem;
  }
  
  &:focus-visible {
    background-color: rgb(1, 61, 88);
    outline: 2px dashed rgb(255, 255, 255);
  
  }
  &:hover {
    background-color: rgb(1, 61, 88);
  
  }
}

.newDropdownClosedLight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  border-radius: 100px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.20000000298023224);

  .text {
    display: flex;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.25rem;
  }
  
  &:focus-visible {
    background-color: rgb(225, 245, 255);
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(225, 245, 255);
  
  }
}

.newDropdownDisabledDark {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  border-radius: 100px;
  background-color: rgb(16, 37, 63);
  outline: 1px solid rgb(62, 75, 89);
  outline-offset: 0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05000000074505806);

  .text {
    display: flex;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.25rem;
  }
  
}

.newDropdownDisabledLight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  border-radius: 100px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05000000074505806);

  .text {
    display: flex;

    // MANUAL OVERRIDE STARTS
    & p {
      color: rgba(2, 118, 177, 0.3); // added opacity
    }
    // MANUAL OVERRIDE ENDS
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.25rem;
    
    // MANUAL OVERRIDE STARTS
    & path {
      fill: rgba(2, 118, 177, 0.3); // added opacity
    }
    // MANUAL OVERRIDE ENDS
  }
  
}

.newDropdownOpenDark {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  border-radius: 100px;
  background-color: rgb(1, 61, 88);
  outline: 1px solid rgb(62, 75, 89);
  outline-offset: 0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.20000000298023224);

  .text {
    display: flex;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.25rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(255, 255, 255);
  
  }
}

.newDropdownOpenLight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  border-radius: 100px;
  background-color: rgb(225, 245, 255);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.20000000298023224);

  .text {
    display: flex;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.25rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  
  }
}
