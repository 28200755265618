.heroCTABlock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 0rem 1rem 0rem;
  background-color: rgb(2, 101, 151);

  .button {
    display: flex;
    // MANUAL OVERRIDE STARTS
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    // MANUAL OVERRIDE ENDS
  }

  .icon {
    overflow: hidden; // MANUAL OVERRIDE
    align-self: center; // MANUAL OVERRIDE
    height: 1.5rem;
    flex: 0 0 1.5rem; // MANUAL OVERRIDE
  }

}