.primaryBanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  // MANUAL OVERRIDE - moved all the background style to custom scss file
  height: 23.75rem;

  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 18rem;
  }
  
  .messageContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .divider {
    display: flex;
    width: unset;
    height: 0.125rem;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .message {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 25rem;

    .textContainer {
      justify-content: center;
      padding: 1rem 2rem 1rem 2rem;
      height: unset;
      flex: 1 1 auto;
    }
    
    .content {
      width: unset;
      height: unset;
      align-self: stretch;
      flex: 1 1 auto;
    }
    
    .messageContent {
      align-items: flex-start;
      width: 27.5rem;
      height: unset;
      align-self: stretch;
      flex: 0 0 auto; // MANUAL OVERRIDE
    }
    
    .divider {
      margin-top: 1.5rem;
    }
    
    .message {
      margin-top: 1.5rem;
    }
    
  }
  // MANUAL OVERRIDE STARTS
  @media (min-width: 768px) {
    .messageContent {
      margin-left: 0.625rem;
    }
  }
  // MANUAL OVERRIDE ENDS
  @media (min-width: 1024px) {

    .textContainer {
      justify-content: flex-start;
      align-items: center;
    }
    
    .content {
      width: 58rem;
      align-self: center; // MANUAL OVERRIDE
    }
    
  }
  @media (min-width: 1440px) {
    height: 37.5rem;

    .textContainer {
      justify-content: center;
      padding: 1rem 3rem 1rem 3rem;
    }
    
    .content {
      width: 84rem;
    }
    
    .messageContent {
      width: 41.25rem;
    }
    
  }
  @media (min-width: 1920px) {
    justify-content: center;

    .textContainer {
      justify-content: flex-start;
      padding: 1rem 0rem 1rem 0rem;
    }
    
  }
}
