.exclusivesEventBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(244, 244, 244);

  .cardTitleBanner {
    display: none;
  }
  
  .container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(244, 244, 244);
    width: unset;
    align-self: stretch;
  }
  
  .cardTitleBanner {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0rem 1rem 0rem; // MANUAL OVERRIDE
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
  }
  
  .exclusivesCardList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .button {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
    
    // MANUAL OVERRIDE
    p {
      text-decoration: underline;
    }
  }
  
  // MANUAL OVERRIDE - removed styles that hid content in mobile breakpoint
  
  @media (min-width: 1024px) {
    align-items: center;
    border-radius: 8px;

    .cardTitleBanner {
      display: flex;
      width: unset;
      align-self: stretch;
    }
    
    .container {
      // MANUAL OVERRIDE - showing container in desktop breakpoint
      border: 1px solid rgb(229, 229, 229); // MANUAL OVERRIDE
    }
    
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
      background-color: rgb(255, 255, 255);
      width: unset;
      align-self: stretch;
    }

    // MANUAL OVERRIDE STARTS
    .button {
      margin-top: 1.5rem;
    }
    // MANUAL OVERRIDE ENDS
    
  }
}
