.logoLogoC1Dark {
  display: block;
  // MANUAL OVERRIDE - removed background-color for dark logo variant
  width: 8.9375rem;
  height: 3.125rem;

  path[id=Content1], [id=Content1] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.logoLogoC1Default {
  display: block;
  // MANUAL OVERRIDE - removed background-color for dark logo variant
  width: 100%;
  height: 100%;

  path[id=Content1], [id=Content1] path {
    display: block;
    fill: rgb(0, 74, 121);
  }
  
}

.logoLogoC1EntertainmentBlue {
  overflow: hidden;
  display: block;
  width: 7.661290168762207rem;
  height: 1.25rem;

  path[id=Content2], [id=Content2] path {
    display: block;
    fill: rgb(204, 36, 39);
  }
  
  path[id=Content1], [id=Content1] path {
    display: block;
    fill: rgb(0, 74, 121);
  }
  
}

.logoLogoC1EntertainmentWhite {
  overflow: hidden;
  display: block;
  width: 7.661290168762207rem;
  height: 1.25rem;

  path[id=Content2], [id=Content2] path {
    display: block;
    fill: rgb(204, 36, 39);
  }
  
  path[id=Content1], [id=Content1] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.logoVividLogo {
  overflow: hidden;
  display: block;
  width: 7.375rem;
  height: 1.6875rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(4, 9, 44);
  }
  
}

.logoVividLogoWithColour {
  display: block;
  width: 6.25rem;
  height: 1.125rem;

  path[id=Content3], [id=Content3] path {
    display: block;
    fill: rgb(9, 131, 182);
  }
  
  path[id=Content2], [id=Content2] path {
    display: block;
    fill: rgb(9, 81, 182);
  }
  
  path[id=Content4], [id=Content4] path {
    display: block;
    fill: rgb(19, 189, 195);
  }
  
  path[id=Content1], [id=Content1] path {
    display: block;
    fill: rgb(206, 49, 151);
  }
  
  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(4, 9, 44);
  }
  
}
