.searchReturn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .searchModalHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .inModalSearchReturnByCategory {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
