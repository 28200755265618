.textFieldsDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .label {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .textInput {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
}

.textFieldsError {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .label {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .textInput {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
    outline: 2px solid rgb(204, 36, 39); // MANUAL OVERRIDE
  }
  
  .error { // MANUAL OVERRIDE - renamed prop
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
}
