// @import '../../../../../styles/tokens';
:global .original-map .hovered {
    fill: #f7b4df !important;
    stroke: #ce3197 !important;
    stroke-width: 2px !important;
}
.path {
    z-index: 1;
    -webkit-tap-highlight-color: red;
}

