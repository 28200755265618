.staticMapWrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.staticMap {
    max-height: 100%;
    max-width: 100%;
}
