.buttonPairList {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; // MANUAL OVERRIDE
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;

  .buttonPair {
    overflow: hidden; // MANUAL OVERRIDE
    display: flex;
    width: unset; // MANUAL OVERRIDE - to show more than 3 buttonPair in a single row
    // MANUAL OVERRIDE
    p {
      font-size: 0.875rem;
    }
  }
  
}
