.tooltip {
  color: white;
  background-color: black;
  z-index: 1;
  padding: 0.5rem 0.75rem 0.5rem;
  border-radius: 0.25rem;
  pointer-events: none;
}

.tooltipArrow {
  color: rgb(0, 0, 0);
  z-index: 1;
  padding: 0;
}
