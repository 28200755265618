.performerCardList {
  overflow: auto; // MANUAL OVERRIDE
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; // MANUAL OVERRIDE
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding: 1rem 0rem 1rem 0rem;
  gap: 1rem;

  .performerCard {
    display: flex;
    height: 14.625rem; // MANUAL OVERRIDE
    width: 16rem; // MANUAL OVERRIDE
    max-width: calc(100% - 0rem); // MANUAL OVERRIDE
    align-self: stretch;
  }
  
  @media (min-width: 768px) {
    gap: 1.5rem;

    // MANUAL OVERRIDE STARTS
    .performerCard {
      height: 22.6875rem;
      width: 16rem;
      max-width: calc(50% - 0.75rem);
    }
    // MANUAL OVERRIDE ENDS
    
  }
  
  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    gap: 1.5rem;
    overflow: unset;

    .performerCard {
      height: 22.6875rem;
      width: 16rem;
      max-width: calc(33.333333333333336% - 1rem);
    }
    
  }

  @media (min-width: 1440px) {
    gap: 2rem;
    overflow: unset;

    .performerCard {
      height: 22.6875rem;
      width: 16rem;
      max-width: calc(25% - 1.5rem);
    }
    
  }
  // MANUAL OVERRIDE ENDS
}
