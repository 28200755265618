.seatSelectionPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .leftContainer {
    display: none;
  }

  .divider {
    display: none;
  }

  .seatSelectionBlock {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }

  .seatMapBlock {
    display: block;
    width: unset;
    align-self: stretch;
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    background-color: rgb(244, 244, 244);

    .leftContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: unset;
      align-self: stretch;
    }

    .divider {
      display: flex;
      width: 0.0625rem;
      height: unset;
      align-self: stretch;
    }

    .seatSelectionBlock {
      display: none;
      width: unset;
      height: unset;
    }

    .seatMapBlock {
      height: unset;
      flex: 1 1 auto;
    }

  }
}

// MANUAL OVERRIDE STARTS
.seatSelectionPageListings {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 1 auto;
  background-color: rgb(255, 255, 255);

  .seatMapBlock {
    overflow: hidden;
    align-self: stretch;
    flex: 1 1 auto;
    min-height: 15rem;
  }

  .seatSelectionBlock {
    display: none;
  }

  .mobileContainer {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
  }

  .seatSelectionBlockMobile {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
  }

  .emptyStateBanner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    border: none;

    >* a {
      align-self: stretch;
    }
  }

  .emptyStateBannerDivider {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 0.0625rem;
  }

  .leftContainer {
    display: none;
  }

  .divider {
    display: none;
  }
}

.seatSelectionPagePrecheckout {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 1 1 auto;
  background-color: rgb(255, 255, 255);

  .seatMapBlock {
    display: none;
  }

  .seatSelectionBlock {
    display: none;
  }

  .mobileContainer {
    display: none;
  }

  .seatSelectionBlockMobile {
    display: none;
  }

  .leftContainer {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    height: 100%;
    padding: 0rem;
  }

  .divider {
    display: none;
  }
}

.modalDialog {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  height: 100%;
  padding: 0rem;

  @media (min-width: 1024px) {
    padding: 4.5rem 8rem 4.5rem 8rem;
  }

  @media (min-width: 1440px) {
    padding: 4.5rem 21rem 4.5rem 21rem;
  }
}

.modalContent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;

  .filterSetting {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    width: 100%;
    flex: 0 0 auto;
  }
}

.modalFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  margin-bottom: 0;
  padding: 1rem 1rem 1rem 1rem;

  >* {
    width: 100%;
    padding: 0.5rem;
  }
}

.filterModal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    align-self: stretch;
    flex: 0 0 3rem;
    background-color: rgb(255, 255, 255);
  }
}

@media (min-width: 1024px) {
  .seatSelectionPageListings {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    background-color: rgb(244, 244, 244);

    .seatSelectionBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      width: 22.5rem;
      flex: 1 1 auto;
    }

    .seatMapBlock {
      overflow: hidden;
      align-self: stretch;
      max-height: 100%;
      flex: 1 1 auto;
    }

    .leftContainer {
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 22.5rem;
    }

    .filterSetting {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      width: 22.5rem;
      flex: 0 0 7.5rem;
    }

    .divider {
      display: flex;
      height: unset; // MANUAL OVERRIDE
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
    }

    .seatSelectionBlockMobile {
      display: none;
    }

    .mobileContainer {
      display: none;
    }

    .emptyStateBanner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      align-self: center;
      flex: 1 1 auto;
      border: none;
      padding: 1rem;
      width: 100%;

      >* a {
        align-self: stretch;
      }
    }

    .emptyStateBannerDivider {
      display: none;
    }
  }

  .seatSelectionPagePrecheckout {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    background-color: rgb(244, 244, 244);

    .seatSelectionBlock {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      width: 22.5rem;
      flex: 1 1 auto;
    }

    .seatMapBlock {
      overflow: hidden;
      display: flex;
      align-self: stretch;
      max-height: 100%;
      flex: 1 1 auto;
    }

    .leftContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 22.5rem;
      background-color: rgb(255, 255, 255);
    }

    .filterSetting {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      width: 22.5rem;
      flex: 0 0 7.5rem;
    }

    .divider {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 0.0625rem;
    }

    .seatSelectionBlockMobile {
      display: none;
    }

    .mobileContainer {
      display: none;
    }
  }

  .filterModal {
    visibility: hidden;
  }
}

// To add scrolling when landscape mode in mobile
// 27rem = the height for the map + 'Choose ticket' Label + one ticket
@media (orientation:landscape) and (max-height: 27rem) {
  .seatSelectionPageListings {
    overflow: auto;

    .mobileContainer {
      overflow: auto;
      min-height: 10rem; // height of Choose Event Label and one ticket
    }
  }
}

// MANUAL OVERRIDE ENDS