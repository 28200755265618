.footerMenuList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: flex-start;
  gap: 1rem; // MANUAL OVERRIDE

  .button {
    display: flex;
  }

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    gap: 1.5rem;
  }
  // MANUAL OVERRIDE ENDS
  
}
