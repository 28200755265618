.ticketTypeSelectionBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  background-color: rgb(0, 19, 43);

  .content {
    display: none;
  }

  .cXEventInfoHeader {
    display: flex;
    width: unset;
    align-self: stretch;

    @media (max-width: 1023px) {

      // MANUAL OVERRIDE
      h1 {
        font-size: 1.25rem;
        line-height: 2rem;
      }
    }

  }

  .ticketOptionsContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .text {
    display: flex;
    width: unset;
    align-self: stretch;

    @media (max-width: 1023px) {

      // MANUAL OVERRIDE
      h2 {
        font-size: 1.25rem;
      }
    }
  }

  .ticketList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }

  .descriptionContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 0rem 1.5rem 0rem;
    width: unset;
    align-self: stretch;
  }

  .description {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  // MANUAL OVERRIDE
  .desktopContent {
    display: none;
  }

  // MANUAL OVERRIDE
  .rightContent {
    display: none;
  }

  // MANUAL OVERRIDE
  .mobileContent {
    width: 100%;
  }

  // MANUAL OVERRIDE
  .ticketsSoldOut {
    align-self: stretch;
  }

  @media (min-width: 768px) {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;

  }

  @media (min-width: 1024px) {
    padding: 2rem 2rem 2rem 2rem;

    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: unset;
      align-self: stretch;
    }

    // MANUAL OVERRIDE
    .desktopContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 2rem;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    // MANUAL OVERRIDE
    .mobileContent {
      display: none;
    }

    .ticketOptionsContainer {  // MANUAL OVERRIDE
      display: flex;
      flex: 0 0 auto;
      width: 19rem;
    }

    .ticketList {
      width: 19rem;
      flex: 0 0 12.625rem;
      margin-top: 0.625rem; // MANUAL OVERRIDE
    }

    // MANUAL OVERRIDE
    .rightContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }

  }

  @media (min-width: 1440px) {
    padding: 2rem 3rem 2rem 3rem;

    .ticketOptionsContainer {
      // MANUAL OVERRIDE STARTS
      display: flex;
      flex: 0 0 auto;
      // MANUAL OVERRIDE ENDS
      width: 26.6875rem;
    }

    .ticketList {
      width: 26.6875rem;
    }

  }
}