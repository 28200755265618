.ticketList {
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap; // MANUAL OVERRIDE
  justify-content: flex-start;
  align-items: flex-start;

  .ticketInformation {
    display: flex;
    width: unset;
    align-self: stretch;
  }

}