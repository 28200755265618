.navMenuItemBack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.6875rem 1rem 0.75rem 0.5rem;
    width: unset;
    align-self: stretch;
  }
  
  .backButton {
    display: flex;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
    align-self: center; // MANUAL OVERRIDE
  }
  
}

.navMenuItemCritical {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.6875rem 1rem 0.75rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    align-self: center; // MANUAL OVERRIDE
  }
  
  @media (min-width: 1024px) {
    padding: 0.5rem; // MANUAL OVERRIDE
    height: 4rem; // MANUAL OVERRIDE
  }
}

.navMenuItemDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.6875rem 1rem 0.75rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    align-self: center; // MANUAL OVERRIDE
  }
  
  @media (min-width: 1024px) {
    padding: 0.5rem; // MANUAL OVERRIDE
    height: 4rem; // MANUAL OVERRIDE
  }
}

.navMenuItemNext {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.6875rem 1rem 0.75rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    align-self: center; // MANUAL OVERRIDE
  }
  
  .nextButton {
    display: flex;
    margin-left: 0.5rem;
  }
  
}
