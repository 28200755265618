.accountMenuCollapsed {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 0rem 0.25rem 0rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .capitalOneCard {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .cardImage {
    display: flex;
    width: 1.25rem;
    height: 0.875rem;
  }
  
  .cardNumber {
    display: flex;
    margin-left: 0.25rem;
  }
  
  .greetingFirstName {
    display: none;
  }
  
  .loyaltyPoints {
    display: flex;
  }
  
  .cardInfo {
    display: none;
  }
  
  .expandButton {
    display: flex;
    margin-left: 0.5rem;
    align-self: center; // MANUAL OVERRIDE
  }

  // MANUAL OVERRIDE STARTS
  .divider {
    display: flex;
    height: auto;
    align-self: stretch;
  }
  // MANUAL OVERRIDE ENDS

  @media (min-width: 1024px) {
    align-items: flex-start;
    padding: 0rem 0rem 0.375rem 0rem;

    .capitalOneCard {
      display: none;
    }
    
    .greetingFirstName {
      display: flex;
    }
    
    .loyaltyPoints {
      display: none;
    }
    
    .cardInfo {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0.25rem; // MANUAL OVERRIDE

      // MANUAL OVERRIDE STARTS
      .capitalOneCard {
        display: flex;
        align-self: center;
      }

      .loyaltyPoints {
        display: flex;
        align-self: center;
      }
      // MANUAL OVERRIDE ENDS
    }
    
  }
}

.accountMenuExpanded {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.0625rem 0rem 0.3125rem 0rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .greetingFirstName {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .cardInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .capitalOneCard {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .cardImage {
    display: flex;
    width: 1.25rem;
    height: 0.875rem;
  }
  
  .cardNumber {
    display: flex;
    margin-left: 0.25rem;
  }
  
  .divider {
    display: flex;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
    margin-left: 0.25rem;
    // MANUAL OVERRIDE STARTS
    height: auto;
    // MANUAL OVERRIDE ENDS
  }
  
  .loyaltyPoints {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .collapseButton {
    display: none;
  }
  
  .closeButton {
    display: flex;
    margin-left: 0.5rem;
  }
  
  @media (min-width: 1024px) {
    align-items: flex-start;
    padding: 0rem 0rem 0.375rem 0rem;

    .content {
      width: unset;
    }
    
    .greetingFirstName {
      width: unset;
    }
    
    .cardInfo {
      width: unset;
    }
    
    .loyaltyPoints {
      width: unset;
    }
    
    .collapseButton {
      display: flex;
      margin-left: 0.5rem;
      align-self: center; // MANUAL OVERRIDE
    }
    
    .closeButton {
      display: none;
    }
    
  }
}
