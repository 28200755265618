.mainLayout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .navContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center; // MANUAL OVERRIDE
    align-self: stretch;
    // MANUAL OVERRIDE STARTS
    flex: 0 0 auto;
    background-color: rgb(0, 19, 43);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    // MANUAL OVERRIDE ENDS
  }

  .topNav {
    display: flex;
    // MANUAL OVERRIDE - removed flex-direction 
    // it was overriding topNav variant flex-direction
    justify-content: center;
    align-items: center; // MANUAL OVERRIDE
    // padding: 0; // MANUAL OVERRIDE
    align-self: stretch;
    flex: 0 0 3rem;
  }

  .topNavDisable {
    @media (max-width: 1023px) {
      display: none;
      visibility: hidden;
    }
  }

  .homepage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 1 1 auto;
    margin-top: 6.1875rem; // MANUAL OVERRIDE - height of header in mobile breakpoint
  }

  // MANUAL OVERRIDE STARTS - height of header in other pages in mobile breakpoint
  .otherPages {
    margin-top: 3rem;
  }

  // MANUAL OVERRIDE ENDS

  .footerContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    background-color: rgb(255, 255, 255); // MANUAL OVERRIDE
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }
}

@media (min-width: 1024px) {
  .mainLayout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .navContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      background-color: rgb(0, 19, 43);
      position: fixed;
      top: 0;
      width: 100%;
    }

    .topNav {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 1 1 auto;
    }

    .topNavDisable {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      background-color: rgb(16, 37, 63);
    }

    .homepage {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 1 1 auto;
      // MANUAL OVERRIDE - added top margin as height of topNav
      margin-top: 4rem;
    }

    // MANUAL OVERRIDE STARTS
    .otherPages {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 1 1 auto;
      // MANUAL OVERRIDE - added top margin as height of topNav
      // margin-top: 4rem;
    }

    // MANUAL OVERRIDE ENDS

    .footerContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      background-color: rgb(255, 255, 255); // MANUAL OVERRIDE
    }

    .footer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: center;
      flex: 0 0 auto;
      width: 100%; // MANUAL OVERRIDE
    }
  }
}