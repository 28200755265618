.seatInfo {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .section {
    display: flex;
  }
  
  .row {
    display: flex;
    margin-left: 1.5rem;
  }
  
  .quantity {
    display: flex;
    margin-left: 1.5rem;
  }
  
}
