.radioItemApplyDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    outline: 0; // MANUAL OVERRIDE
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0.75rem 0.5rem 0.75rem;
    border-radius: 4px;
    border: 1px solid rgb(212, 212, 212); // MANUAL OVERRIDE
    outline-offset: 0;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.75rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  // MANUAL OVERRIDE - removed .text

  .content1 {
    display: flex;
    width: unset;
    min-height: 3rem; // MANUAL OVERRIDE - to allow item to grow when in edit mode
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  &:focus-visible {
  
    .card {
      background-color: rgb(255, 255, 255);
      border: 1px dashed rgb(2, 101, 151); // MANUAL OVERRIDE
    }
    
  }
  &:hover {
  
    .card {
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(2, 101, 151); // MANUAL OVERRIDE
    }
    
  }
  @media (min-width: 1024px) {

    .card {
      padding: 1.5rem 1.5rem 0.75rem 1.5rem;
    }
    
    .title {
      width: unset;
    }
    
    .text {
      height: unset;
    }
    
  &:focus-visible {
  
    .card {
      background-color: rgb(255, 255, 255);
      border: 1px dashed rgb(2, 101, 151); // MANUAL OVERRIDE
    }
    
  }
  &:hover {
  
    .card {
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(2, 101, 151); // MANUAL OVERRIDE
    }
    
  }
  }
}

.radioItemApplySelected {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    outline: 0; // MANUAL OVERRIDE
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0.75rem 0.5rem 0.75rem;
    border-radius: 4px;
    border: 1px solid rgb(1, 78, 116); // MANUAL OVERRIDE
    outline-offset: 0;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.75rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  // MANUAL OVERRIDE - removed .text

  .content1 {
    display: flex;
    width: unset;
    min-height: 3rem; // MANUAL OVERRIDE - to allow item to grow when in edit mode
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  @media (min-width: 1024px) {

    .card {
      padding: 1.5rem 1.5rem 0.75rem 1.5rem;
    }
    
    .title {
      width: unset;
    }
    
    .text {
      height: unset;
    }
    
    // MANUAL OVERRIDE - removed height unset for keeping same height for selected and default state
    
  }
}

.radioItemNotApplyDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    outline: 0; // MANUAL OVERRIDE
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0.75rem 1rem 0.75rem;
    border-radius: 4px;
    border: 1px solid rgb(212, 212, 212); // MANUAL OVERRIDE
    outline-offset: 0;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.75rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  &:focus-visible {
  
    .card {
      border: 1px dashed rgb(2, 101, 151); // MANUAL OVERRIDE
    }
    
  }
  &:hover {
  
    .card {
      border: 1px solid rgb(2, 118, 177); // MANUAL OVERRIDE
    }
    
  }
  @media (min-width: 1024px) {

    .card {
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
      height: 4.3125rem;
    }
    
  &:focus-visible {
  
    .card {
      border: 1px dashed rgb(2, 101, 151); // MANUAL OVERRIDE
    }
    
  }
  &:hover {
  
    .card {
      border: 1px solid rgb(2, 118, 177); // MANUAL OVERRIDE
    }
    
  }
  }
}

.radioItemNotApplySelected {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .icon {
    display: block;
    width: 1rem;
    height: 1rem;
    outline: 0; // MANUAL OVERRIDE
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .card {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0.75rem 1rem 0.75rem;
    border-radius: 4px;
    border: 1px solid rgb(1, 78, 116); // MANUAL OVERRIDE
    outline-offset: 0;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.75rem;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  @media (min-width: 1024px) {

    .card {
      padding: 1.5rem 1.5rem 1.5rem 1.5rem;
      height: 4.3125rem;
    }
    
  }
}
