.searchReturnList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch; // MANUAL OVERRIDE

  .searchReturnItem {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
