.accountMenuCollapsed {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .greetingFirstName {
    display: none; // MANUAL OVERRIDE
  }
  
  .cardInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .capitalOneCard {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 4.3125rem; // MANUAL OVERRIDE
  }
  
  .cardImage {
    display: flex;
    width: 1.25rem;
    height: 0.875rem;
  }
  
  .cardNumber {
    display: flex;
    margin-left: 0.25rem;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .divider {
    display: none;
  }
  
  .loyaltyPoints {
    display: flex;
  }
  
  .expandButton {
    display: none;
  }
  
  @media (min-width: 1024px) {
    align-items: flex-start;
    // padding: 0rem 0rem 0.375rem 0rem; // MANUAL OVERRIDE

    // MANUAL OVERRIDE
    .greetingFirstName {
      display: flex;
      width: unset;
      align-self: stretch;
    }
    
    .cardInfo {
      flex-direction: row;
    }
    
    .divider {
      display: flex;
      width: 0.0625rem;
      height: unset;
      align-self: stretch;
      margin-left: 0.25rem;
    }
    
    .loyaltyPoints {
      margin-left: 0.25rem;
    }
    
    .expandButton {
      display: flex;
      margin-left: 0.5rem;
      align-self: center; // MANUAL OVERRIDE
    }
    
  }
}

.accountMenuExpanded {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // padding: 0.0625rem 0rem 0.3125rem 0rem; // MANUAL OVERRIDE

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset; // MANUAL OVERRIDE
    flex: 1 1 auto; // MANUAL OVERRIDE
  }
  
  .greetingFirstName {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .cardInfo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .capitalOneCard {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .cardImage {
    display: flex;
    width: 1.25rem;
    height: 0.875rem;
  }
  
  .cardNumber {
    display: flex;
    margin-left: 0.25rem;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .divider {
    display: flex;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
    margin-left: 0.25rem;
  }
  
  .loyaltyPoints {
    display: flex;
    margin-left: 0.25rem;
  }
  
  .collapseButton {
    display: none;
  }
  
  .closeButton {
    display: flex;
    margin-left: 0.5rem;
  }
  
  @media (min-width: 1024px) {
    align-items: flex-start;
    // padding: 0rem 0rem 0.375rem 0rem; // MANUAL OVERRIDE

    .content {
      width: unset;
    }
    
    .greetingFirstName {
      width: unset;
    }
    
    .cardInfo {
      width: unset;
    }
    
    .collapseButton {
      display: flex;
      margin-left: 0.5rem;
      align-self: center; // MANUAL OVERRIDE
    }
    
    .closeButton {
      display: none;
    }
    
  }
}

// MANUAL OVERRIDE STARTS
.myTickets {
  cursor: pointer;
  height: 4rem;
  padding: 0.5rem;
  align-self: stretch;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.logOut {
  cursor: pointer;
  height: 4rem;
  padding: 0.5rem;
  align-self: stretch;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
// MANUAL OVERRIDE ENDS