body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;    
}

html, body {    
    height: 100vh;    
}

#root {    
    height: 100vh;    
}

#content {
    scroll-margin-top: 3rem;
}

@media (min-width: 769px) {
    #content {
        scroll-margin-top: 4rem;
    }
}