.costBreakdown {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 0.5rem 1rem 0.5rem;
  background-color: rgb(244, 244, 244);

  .ordarTotal {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .tickets {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .serviceFee {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .delivery {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .promoCodeContainer {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0rem 0.5rem 0rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .button {
    display: flex;
  }
  
}
