.cardSpecificEventsList {
  overflow: auto; // MANUAL OVERRIDE
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; // MANUAL OVERRIDE
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding: 1rem 0rem 1rem 0rem;
  gap: 1rem;

  // MANUAL OVERRIDE STARTS
  .specialEventCard {
    display: flex;
    max-width: calc(100% - 0rem);
    height: 32.4375rem;
    width: 16rem;
  }
  // MANUAL OVERRIDE ENDS
  
  @media (min-width: 768px) {
    gap: 1.5rem;

    // MANUAL OVERRIDE STARTS
    .specialEventCard {
      height: 32.9375rem;
      width: 20.25rem;
      max-width: calc(50% - 0.75rem);
    }
    // MANUAL OVERRIDE ENDS
    
  }

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    overflow: unset;
  }
  // MANUAL OVERRIDE ENDS

  @media (min-width: 1440px) {
    gap: 2rem;
    overflow: unset; // MANUAL OVERRIDE

    .specialEventCard {
      width: calc(50% - 1rem);
    }
    
  }
}
