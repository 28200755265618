.seatMapBlock {
  overflow: hidden;
  display: block;
  background-color: rgb(255, 255, 255);
  // MANUAL OVERRIDE STARTS
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: stretch;
  // MANUAL OVERRIDE ENDS
}
