.selectTicket {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  outline: 1px solid rgb(244, 244, 244);
  outline-offset: 0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  width: 22.5rem;

  .instructionLabel {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .ticketList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
