 // MANUAL OVERRIDE STARTS
.sportsPrecheckout {
  display: none;
}

.breakdown{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 1rem 1rem 1rem;
  > * {
    margin-top: 1rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
}

@media (min-width: 1024px) {
  .sportsPrecheckout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 22.5rem;
  
    .cardNavigation {
      display: flex;
      width: unset;
      align-self: stretch;
    }
    
    .image {
      display: flex;
      width: unset;
      align-self: stretch;
      height: 20rem; // MANUAL OVERRIDE
    }
    
    .ticketInformation {
      display: flex;
      width: unset;
      align-self: stretch;
    }
    
    .ticketQuantitySelection {
      display: flex;
      width: unset;
      align-self: stretch;
    }
    
    .additionalTicketInformationList {
      display: flex;
      width: unset;
      align-self: stretch;
    }
    
  }
}
// MANUAL OVERRIDE ENDS