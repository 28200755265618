.alertModal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: unset;
    align-self: stretch;
  }
  
  .closeButton {
    display: flex;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .icon {
    display: block;
    width: 2.625rem;
    height: 2.625rem;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .message {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .buttonPair {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 3rem;
  }
  
  .secondaryButton {
    display: flex;
    margin-top: 1.5rem;
  }
  
  .primaryButton {
    display: flex;
  }
  
  @media (min-width: 1024px) {
    width: 30rem;

    .buttonPair {
      flex-direction: row;
    }
    
    .secondaryButton {
      margin-top: 0;
    }
    
    .primaryButton {
      margin-left: 1.5rem;
    }
    
  }
}

// MANUAL OVERRIDE STARTS
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modalDialog {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  height: 100%;
  padding: 8.5rem 1.5rem 0rem 1.5rem;
}

.modalContent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 0 0 auto;
}
// MANUAL OVERRIDE ENDS