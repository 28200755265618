.secondaryBanner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem 2rem 2rem 2rem;
  border-radius: 4px;
  background-size: cover;
  // MANUAL OVERRIDE - remved the background-position

  .messageContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
  }
  
  .divider {
    display: flex;
    width: 10rem;
    margin-top: 1.5rem;
  }
  
  .message {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }

  // MANUAL OVERRIDE STARTS
  .button {
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 9px 16px;
    margin-top: 1.5rem;

    p {
      font-family: 'Optimist';
      font-style: normal;
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 1.9rem;
  
      display: flex;
      align-items: center;
      text-align: center;
    }
    
  }
  // MANUAL OVERRIDE ENDS

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    .title {
      h2 {
        font-size: 1.5rem;
      }
    }
  }
  // MANUAL OVERRIDE ENDS
  
}
