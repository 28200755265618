.eventsEmptyStateDark {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: rgb(16, 37, 63);
  width: auto; // MANUAL OVERRIDE
  height: 23.25rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;
    width: unset;
    align-self: stretch;
  }
  
  .icon {
    display: block;
    width: 4.5rem;
    height: 4.5rem;
  }
  
  .mainMessage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 0rem 1rem;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
  }
  
  .message {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
    margin-top: 0.5rem;
  }
  
  @media (min-width: 1024px) {

    .content {
      padding: 1rem 1rem 1rem 1rem;
    }
    
    .mainMessage {
      margin-top: 1rem;
      padding: 0; // MANUAL OVERRIDE
    }
    
    .message {
      margin-top: 0.25rem;
    }
    
  }
}

.eventsEmptyStateLight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: rgb(244, 244, 244);
  height: 23.25rem;
  // MANUAL OVERRIDE STARTS
  align-self: stretch; 
  width: auto;
  // MANUAL OVERRIDE ENDS

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;
    width: unset;
    align-self: stretch;
  }
  
  .icon {
    display: block;
    width: 4.5rem;
    height: 4.5rem;
  }
  
  .mainMessage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 0rem 1rem;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
  }
  
  .message {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
    margin-top: 0.5rem;
  }
  
  @media (min-width: 1024px) {

    .content {
      padding: 1rem 1rem 1rem 1rem;
    }
    
    .mainMessage {
      margin-top: 1rem;
      padding: 0; // MANUAL OVERRIDE
    }
    
    .message {
      margin-top: 0.25rem;
    }
    
  }
}

.eventsEmptyStateLightPerformer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: rgb(244, 244, 244);
  width: auto; // MANUAL OVERRIDE
  height: 14.625rem;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;
    width: unset;
    align-self: stretch;
  }
  
  .icon {
    display: block;
    width: 4.5rem;
    height: 4.5rem;
  }
  
  .mainMessage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 0rem 1rem;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
  }
  
  .message {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
    margin-top: 0.5rem;
  }
  
  @media (min-width: 1024px) {

    .content {
      padding: 1rem 1rem 1rem 1rem;
    }
    
    .mainMessage {
      margin-top: 1rem;
      padding: 0; // MANUAL OVERRIDE
    }
    
    .message {
      margin-top: 0.25rem;
    }
    
  }
}
