.submenu {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(16, 37, 63);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
    height: 0.0625rem; // MANUAL OVERRIDE
  }
  
  .submenuList {
    display: flex;
    width: unset;
    align-self: stretch;
    // MANUAL OVERRIDE STARTS
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    // MANUAL OVERRIDE ENDS
  }
  
}
