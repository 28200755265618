.instructionLabelLargeDefaultSubduedBG {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(244, 244, 244);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    align-self: stretch;
  }

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
  }

}

.instructionLabelLargeDefaultWhiteBG {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    align-self: stretch;
  }

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
  }

}

.instructionLabelMediumDefaultWhiteBG {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    align-self: stretch;
  }

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
  }

}

.instructionLabelMediumWithIconWhiteBG {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    height: 3.5rem;
    align-self: stretch;
  }

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }

  .button {
    display: flex;
  }

  .divider1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    .divider {
      display: none;
    }
  }

  // MANUAL OVERRIDE ENDS

}