.searchCategoriesDividerBottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0rem 1rem 0rem 0.5rem;
    width: unset;
    align-self: stretch;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .divider {
    display: flex;
    width: unset;
    height: 0.1875rem;
    align-self: stretch;
  }
  
}

.searchCategoriesDividerTop {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0rem 1rem 0rem 0.5rem;
    width: unset;
    align-self: stretch;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
}

.searchCategoriesDividerTopWhite {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0rem 0.75rem 0rem 0.75rem;
    width: unset;
    align-self: stretch;
  }
  
  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.5rem 0rem 0.5rem 0rem;
    width: unset;
    flex: 1 1 auto;
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
