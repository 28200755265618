.eventDetailsModal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(229, 229, 229);
  outline-offset: 0;

  // MANUAL OVERRIDE STARTS
  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }
  
  .subHeadingTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  // MANUAL OVERRIDE ENDS

  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .button {
    display: flex;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .bannerImage {
    display: flex;
    width: 6rem;
    height: 6rem;
  }
  
  .rightContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 0 0; // MANUAL OVERRIDE
    margin-left: 1rem;
  }
  
  .description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .eventInfo {
    display: flex;
    width: unset;
    align-self: stretch;

    // MANUAL OVERRIDE STARTS
    & p {
      color: rgb(0, 0, 0);
    }
    // MANUAL OVERRIDE ENDS
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 2rem;
  }
  
  .title1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .description1 {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  @media (min-width: 1024px) {
    width: 62rem; // MANUAL OVERRIDE
    height: unset; // MANUAL OVERRIDE
    padding: 1rem; // MANUAL OVERRIDE

    // MANUAL OVERRIDE STARTS
    .desktop {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      max-width: 61rem;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .mobile {
      display: none;
    }

    .buttonContainer {
      display: flex; // MANUAL OVERRIDE
    }

    .title {
      height: 2rem;
    }

    .subHeadingTitle {
      margin-top: 1.5rem;
    }

    .description {
      margin-top: 0.75rem;
    }
    // MANUAL OVERRIDE ENDS

    .content {
      flex-direction: column; // MANUAL OVERRIDE
    }
    
    .topContent {
      display: none;
      width: unset;
    }
    
    .bannerImage {
      display: flex;
      width: 20rem;
      height: 20rem; // MANUAL OVERRIDES
    }
    
    .bottomContent {
      display: none;
      width: unset;
      margin-top: 0;
    }
    
    .rightContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0rem 1.5rem 0rem 0rem;
      width: unset;
      flex: 1 1 auto;
      margin-left: 2rem;
    }
    
  }
}

// MANUAL OVERRIDE STARTS
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modalDialog {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: calc(100vw - 32px);
  margin: 22vh auto auto auto;

  @media (min-width: 1024px) {
    width: 62.375rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
}
// MANUAL OVERRIDE ENDS
