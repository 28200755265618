.sliderButtonDarkNext {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.6875rem 0.625rem 0.6875rem 0.75rem;
  border-radius: 100px;
  background-color: rgb(228, 229, 229);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.20000000298023224);

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  
  }
}

.sliderButtonDarkPrev {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.6875rem 0.75rem 0.6875rem 0.625rem;
  border-radius: 100px;
  background-color: rgb(228, 229, 229);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.20000000298023224);

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  
  }
}

.sliderButtonLightNext {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.6875rem 0.625rem 0.6875rem 0.75rem;
  border-radius: 100px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.20000000298023224);

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(2, 101, 151);
    outline-offset: 0;
  
  }
}

.sliderButtonLightPrev {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.6875rem 0.75rem 0.6875rem 0.625rem;
  border-radius: 100px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.20000000298023224);

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(2, 101, 151);
    outline-offset: 0;
  
  }
}
