// MANUAL OVERRIDE STARTS
.generalPrecheckout {
  display: none;
}

.breakdown{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 1rem 1rem 1rem;
  > * {
    margin-top: 1rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
}
// MANUAL OVERRIDE ENDS

@media (min-width: 1024px) {
  .generalPrecheckout {
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 8px;
    outline: 1px solid rgb(244, 244, 244);
    outline-offset: 0;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    align-self: flex-start; // MANUAL OVERRIDE
    // MANUAL OVERRIDE STARTS
    min-width: 22rem;
    max-width: 22rem;
    overflow-y: auto;
    overflow-x: hidden;
    // MANUAL OVERRIDE ENDS
  
    .cardNavigation {
      display: flex;
      width: unset;
      align-self: stretch;
    }
    
    .ticketSelectionInformation {
      display: flex;
      width: unset;
      align-self: stretch;
    }
    
    .ticketQuantitySelection {
      display: flex;
      width: unset;
      align-self: stretch;
    }
    
    .additionalTicketInformationList {
      display: flex;
      width: unset;
      align-self: stretch;
    }

    // MANUAL OVERRIDE STARTS
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      height: 20rem;
    }
    // MANUAL OVERRIDE ENDS
    
  }
}
