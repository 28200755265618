.sportsMenuTabList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 11rem; // MANUAL OVERRIDE

  .submenuTabItem {
    display: flex;
    width: 11rem; // MANUAL OVERRIDE
    // height: 1.8125rem; // MANUAL OVERRIDE
    align-self: stretch;
  }
  
}

// MANUAL OVERRIDE STARTS
@media (min-width: 1440px) {
  .sportsMenuTabList {
    width: 16.3125rem; 
  
    .submenuTabItem {
      width: 16.3125rem;
    }
  
  }
}
// MANUAL OVERRIDE ENDS