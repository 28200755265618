.newDropdownMenuItemIconTextSelected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.25rem 1.5rem 1.25rem 1.5rem;
    width: unset;
    align-self: stretch;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .trailingIcon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    background-color: rgb(225, 245, 255);
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(225, 245, 255);
  
  }
}

.newDropdownMenuItemIconTextUnselected {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.25rem 1.5rem 1.25rem 1.5rem;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(225, 245, 255);
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  
    .content {
      align-self: stretch;
    }
    
  }
  &:hover {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(225, 245, 255);
  
    .content {
      align-self: stretch;
    }
    
  }
}

.newDropdownMenuItemTextUnselected {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.25rem 1.5rem 1.25rem 1.5rem;
    width: unset;
    flex: 1 1 auto;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  &:focus-visible {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(225, 245, 255);
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  
    .content {
      align-self: stretch;
    }
    
  }
  &:hover {
    background-color: rgb(225, 245, 255);
  
  }
}

.newDropdownMenuItemTextCriticalUnselected {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.25rem 1.5rem 1.25rem 1.5rem;
    width: unset;
    flex: 1 1 auto;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  &:focus-visible {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(255, 229, 230);
    outline: 2px dashed rgb(204, 36, 39);
    outline-offset: 0;
  
    .content {
      align-self: stretch;
    }
    
  }
  &:hover {
    background-color: rgb(255, 229, 230);
  
  }
}
