.eventCardLight {
  overflow: hidden;
  display: flex;
  position: relative; // MANUAL OVERRIDE
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.10000000149011612);
  width: 16rem;
  flex: 0 0 auto; // MANUAL OVERRIDE
  text-decoration: none; // MANUAL OVERRIDE

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: unset;
    align-self: stretch;
    height: 10.625rem; // MANUAL OVERRIDE
    overflow: hidden; // MANUAL OVERRIDE
  }
  
  .image {
    display: flex;
    width: unset;
    height: 10.625rem;
    align-self: stretch;
    transition: transform 0.3s ease; // MANUAL OVERRIDE
  }
  
  .eventTypeTag {
    display: flex;
    // MANUAL OVERRIDE STARTS
    position: absolute;
    top: 12px;
    right: 12px;
    // MANUAL OVERRIDE ENDS
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 0.5rem 1rem;
    width: unset;
    height: 8.5rem;
    align-self: stretch;
  }
  
  .tag {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0.25rem 0rem;
    width: unset;
    align-self: stretch;
  }
  
  .eventTypeTag1 {
    display: flex;
  }
  
  .status {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0.25rem 0rem;
    width: unset;
    align-self: stretch;
  }
  
  .eventStatusTag {
    display: flex;
  }
  
  .titleText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .eventLogistics {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .dateAndTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .dateAndTimeText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .locationAndPrice {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .locationAndPriceText {
    display: flex;
  }
  
  .actionContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 1rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
  .textIcon {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .actionText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 2rem;
    height: 2rem;
    margin-left: 0.25rem;
  }

  // MANUAL OVERRIDE STARTS
  &:hover {
    .image {
      transform: scale(1.2);
    }
  }
  // MANUAL OVERRIDE ENDS
  
  &:focus-visible {
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  
  }
  @media (min-width: 768px) {

    .topContent {
      height: 10.625rem;
    }
    
    // MANUAL OVERRIDE - removed divider style containing width
    
    &:focus-visible {
      outline: 2px dashed rgb(2, 118, 177);
      outline-offset: 0;
    
    }

    // MANUAL OVERRIDE STARTS
    .textIcon {
      > * {
        margin-left: 0.25rem;
      }
    }

    .divider {
      margin-top: 0.5rem;
    }

    &:hover {
      .image {
        transform: scale(1.2);
      }
    }
    // MANUAL OVERRIDE ENDS
  }

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    width: 19rem;
  }

  @media (min-width: 1440px) {
    width: 19.5rem;
  }
  // MANUAL OVERRIDE ENDS
}
