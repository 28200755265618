.recentlyViewedList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;

  .recentlyViewedContent {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1024px) {
    flex-direction: row;
    align-content: flex-start;
    gap: 1rem;

    .recentlyViewedContent {
      flex: 1 1 auto;
      width: calc(33.333333333333336% - 0.6666666666666666rem);
    }
    
  }
}
