.filterList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  // MANUAL OVERRIDE - removed gap for mobile breakpoint

  .filter {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
  }
  
  @media (min-width: 1024px) {
    flex-direction: row;
    align-content: flex-start;
    gap: 1rem; // MANUAL OVERRIDE
    
  }
}
