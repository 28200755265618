.exclusiveEventInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .tag {
    display: flex;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
    word-break: normal; // MANUAL OVERRIDE
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .eventInfo {
    display: flex;
    width: unset;
    align-self: stretch;
    word-break: normal; // MANUAL OVERRIDE
  }
  
}
