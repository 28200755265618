.footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(244, 244, 244);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 2.5rem 1rem 2.5rem 1rem;
    width: unset;
    align-self: stretch;
  }

  // MANUAL OVERRIDE STARTS
  .divider {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    height: 0.0625rem; // MANUAL OVERRIDE
  }
  // MANUAL OVERRIDE ENDS
  
  .logo {
    display: block;
    width: 4.625rem;
    height: 1.5625rem; // MANUAL OVERRIDE
  }
  
  .footerMenuList {
    display: flex;
    width: unset;
    height: unset;
    align-self: center; // MANUAL OVERRIDE
    flex: 1 1 auto;
  }
  
  @media (min-width: 1024px) {
    align-items: center;

    .logo {
      width: 8.9375rem;
      height: 3.125rem;
    }
    
  }
}
