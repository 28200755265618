.contentDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap; // MANUAL OVERRIDE
  gap: 1rem 0.25rem; // MANUAL OVERRIDE
  min-height: 3rem; // MANUAL OVERRIDE

  .textRedeem {
    display: flex;
  }
  
  .texMiles {
    display: flex;
    // MANUAL OVERRIDE - removed margin-left in favor of gap in parent
  }
  
  .textMilesFor {
    display: flex;
    // MANUAL OVERRIDE - removed margin-left in favor of gap in parent
  }
  
  .textDollar {
    display: flex;
    // MANUAL OVERRIDE - removed margin-left in favor of gap in parent
  }
  
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0rem 0.5rem 0rem 0.5rem;
    // MANUAL OVERRIDE - removed margin-left in favor of gap in parent
  }
  
  .button {
    display: flex;
  }

  // MANUAL OVERRIDE STARTS
  .info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 0 0.25rem;
  }
  // MANUAL OVERRIDE ENDS

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    .textRedeem, .textMilesFor {
      .value {
        font-size: 0.875rem;
        line-height: 1.3125rem;
      }
    }
  }
  // MANUAL OVERRIDE ENDS
  
}

.contentEdit {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap; // MANUAL OVERRIDE
  gap: 1rem 0.25rem; // MANUAL OVERRIDE
  min-height: 3rem; // MANUAL OVERRIDE

  .textRedeem {
    display: flex;
  }
  
  .textInput {
    display: flex;
    width: 4.625rem;
    flex: 0 0 auto; // MANUAL OVERRIDE - to match flex-shrink:0 from figma
    // MANUAL OVERRIDE - removed margin-left in favor of gap in parent
    padding: 0.3125rem 0.5rem 0.375rem 0.5rem;
  }
  
  .textMilesFor {
    display: flex;
    // MANUAL OVERRIDE - removed margin-left in favor of gap in parent
  }
  
  .textDollar {
    display: flex;
    // MANUAL OVERRIDE - removed margin-left in favor of gap in parent
  }
  
  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0rem 0.5rem 0rem 0.5rem;
    // MANUAL OVERRIDE - removed margin-left in favor of gap in parent
    gap: 0.5rem;
  }
  
  .saveButton {
    display: flex;
  }
  
  .divider {
    display: flex;
    width: 0.0625rem;
    // MANUAL OVERRIDE - removed static height
    // MANUAL OVERRIDE - removed margin-left in favor of gap in .actions
  }
  
  .cancelButton {
    display: flex;
    // MANUAL OVERRIDE - removed margin-left in favor of gap in .actions
  }

  // MANUAL OVERRIDE STARTS
  .info {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 0 0.25rem;
  }
  // MANUAL OVERRIDE ENDS

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    .textRedeem, .textMilesFor {
      .value {
        font-size: 0.875rem;
        line-height: 1.3125rem;
      }
    }
  }
  // MANUAL OVERRIDE ENDS
  
}
