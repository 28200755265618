.customAccountMenuBlock {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .myTickets {
    display: flex;
    align-self: stretch;

    // to hide bottom divider for myTickets navMenuItem
    .divider1 {
      display: none;
    }
  }
  
  .logOut {
    display: flex;
    align-self: stretch;
  }
}

.modalDialog {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;

  height: 100%;
  padding: 0rem;
  @media (min-width: 1024px) {
    padding: 4.5rem 8rem 4.5rem 8rem;
  }
  @media (min-width: 1440px) {
    padding: 4.5rem 21rem 4.5rem 21rem;
  }
}

.modalContent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  height: 100%;
}

.modalHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
}

.modalFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  padding: 1rem 1rem 1rem 1rem;
}
