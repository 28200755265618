.sliderFilter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  background-color: rgb(255, 255, 255);

  .value {
    display: flex;
    flex: 0 0 auto; // MANUAL OVERRIDE
    flex-direction: row;
    justify-content: flex-start;
    align-items: center; // MANUAL OVERRIDE
    width: unset;
    align-self: stretch;
  }

  .leftValue {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
    align-self: center; // MANUAL OVERRIDE
    width: 2.5rem; // MANUAL OVERRIDE
  }

  .rightValue {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
    align-self: center; // MANUAL OVERRIDE
    width: 2.5rem; // MANUAL OVERRIDE
    justify-content: flex-end; // MANUAL OVERRIDE
  }

  .slider {
    display: block;
    width: unset;
    align-self: stretch;
    // margin-top: 1rem; // MANUAL OVERRIDE
    align-self: center; // MANUAL OVERRIDE
    flex: 1 1 auto; // MANUAL OVERRIDE
    margin-left: 1rem; // MANUAL OVERRIDE
    margin-right: 1rem; // MANUAL OVERRIDE 
  }

  @media (min-width: 1024px) {

    .value {
      align-items: center;
    }

    .leftValue {
      width: 2.5rem; // MANUAL OVERRIDE
    }

    .slider {
      display: block;
      width: 55.75rem;
    }

    .rightValue {
      width: 2.5rem; // MANUAL OVERRIDE
      justify-content: flex-end; // MANUAL OVERRIDE
    }

  }
}