.eventCardNoImage {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1); // MANUAL OVERRIDE
  flex: 0 0 auto; // MANUAL OVERRIDE
  // MANUAL OVERRIDE - removed fixed width

  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between; // MANUAL OVERRIDE
    align-items: flex-start;
    flex: 1 0 auto; // MANUAL OVERRIDE
    align-self: stretch;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    // height: 10.125rem; // MANUAL OVERRIDE
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
    padding: 1rem 1rem 0.5rem; // MANUAL OVERRIDE
  }

  .tag {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0.25rem 0rem;
    width: unset;
    align-self: stretch;
  }

  .eventTypeTag {
    display: flex;
  }

  .status {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0.25rem 0rem;
    width: unset;
    align-self: stretch;
  }

  .eventStatusTag {
    display: flex;
  }

  .titleText {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .eventLogistics {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;

    // MANUAL OVERRIDE STARTS
    >* {
      margin-top: 0.25rem;
    }

    // MANUAL OVERRIDE ENDS
  }

  .dateAndTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .dateAndTimeText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }

  .locationAndPrice {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }

  .locationAndPriceText {
    display: flex;
  }

  .actionContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 1rem 1rem;
    width: unset;
    align-self: stretch;
  }

  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }

  .textIcon {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    margin-top: 0.5rem;
    width: 100%; // MANUAL OVERRIDE
    padding: 0rem 0.25rem 0rem 0rem; // MANUAL OVERRIDE
  }

  .actionText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }

  .icon {
    display: block;
    width: 2rem;
    height: 2rem;
    margin-left: 0.25rem;
  }

  &:focus-visible {
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  }

  // MANUAL OVERRIDE STARTS
  .eventCardNoImageDesktop {
    display: none;
  }

  .eventCardNoImageMobile {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    min-height: 11.125rem;
  }

  .eventCardNoImageMobile:focus-visible {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    outline: 2px solid rgb(2, 118, 177);
  }

  @media (min-width: 768px) {}

  // MANUAL OVERRIDE ENDS

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    // padding: 0.5rem 1.5rem 0.5rem 1rem; // MANUAL OVERRIDE
    // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.10000000149011612); // MANUAL OVERRIDE
    width: unset;

    // MANUAL OVERRIDE STARTS
    .eventCardNoImageMobile {
      display: none;
    }

    .eventCardNoImageDesktop {
      overflow: hidden;
      display: flex !important;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0.5rem 1.5rem 0.5rem 1rem;
      align-self: stretch;
      flex: 0 0 auto;
      width: 100%;
      min-height: 7.0625rem;
      box-shadow: none; // MANUAL OVERRIDE
    }

    .eventCardNoImageDesktop:focus-visible {
      overflow: hidden;
      display: flex !important;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1rem 1.5rem 1rem 1rem;
      align-self: stretch;
      flex: 0 0 auto;
      width: 100%;
      outline: 2px solid rgb(2, 118, 177);
      box-shadow: none; // MANUAL OVERRIDE
      // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.20000000298023224); // MANUAL OVERRIDE
    }

    // MANUAL OVERRIDE ENDS

    .bottomContent {
      padding: 0.75rem 0rem 0.75rem 1rem; // MANUAL OVERRIDE
      height: unset;
      flex: 1; // MANUAL OVERRIDE
    }

    .content {
      display: none;
      width: unset;
      height: unset;
    }

    .dateContent {
      // MANUAL OVERRIDE
      padding: 0 1rem;
    }

    .actionContent {
      display: none;
      width: unset;
    }

    &:focus-visible {
      outline: 2px dashed rgb(2, 118, 177);
      outline-offset: 0;

    }

    // MANUAL OVERRIDE STARTS
    .dividerTwo {
      display: flex;
      height: 100%;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 0.0625rem;
    }

    .leftContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      padding: 0.75rem 0rem 0.75rem 0rem;
      flex: 0 0 auto;
      min-width: 7.4375rem;
    }

    .weekDayAndTime {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    // MANUAL OVERRIDE ENDS
  }
}