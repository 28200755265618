.iconArenaBaseDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(20, 20, 20);
  }
  
}

.iconArenaWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconArrowLeftActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(2, 118, 177);
  }
  
}

.iconArrowLeftBaseDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(20, 20, 20);
  }
  
}

.iconArrowLeftWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconArrowRightFilledActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(2, 118, 177);
  }
  
}

.iconArrowRightFilledActionHover {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  // MAUAL OVERRIDE STARTS
  & path {
    fill: rgb(2, 101, 151);
  }
  // MAUAL OVERRIDE ENDS

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(2, 101, 151);
  }
  
}

.iconArrowRightFilledWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconArrowRightActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(2, 118, 177);
  }
  
}

.iconArrowRightWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconCalendarIllustrationDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content6], [id=Content6] path {
    display: block;
    fill: rgb(37, 95, 130);
  }
  
  path[id=Content5], [id=Content5] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
  path[id=Content4], [id=Content4] path {
    display: block;
    fill: rgb(192, 231, 243);
  }
  
  path[id=Content3], [id=Content3] path {
    display: block;
    fill: rgb(1, 61, 88);
  }
  
  path[id=Content2], [id=Content2] path {
    display: block;
    fill: rgb(120, 208, 235);
  }
  
  path[id=Content1], [id=Content1] path {
    display: block;
    fill: rgb(249, 198, 6);
  }
  
}

.iconCalendarLargeDigitalGrey30 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(168, 168, 168);
  }
  
}

.iconCalendarActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(2, 101, 151);
  }
  
}

.iconCalenderNewSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }
  
}

.iconCheckboxEmptyDigitalGrey100 {
  display: block;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(105, 105, 105);
  outline-offset: 0;
  width: 1.5rem;
  height: 1.5rem;

}

.iconCheckboxFilledDigitalGrey100 {
  display: block;
  border-radius: 2px;
  background-color: rgb(2, 117, 177);
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconCheckmarkInCircleFilledGreen60 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(30, 106, 11);
  }
  
}

.iconCheckmarkInCircleDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }
  
}

.iconCheckmarkActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(1, 78, 116);
  }
  
}

.iconCheckmarkActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(2, 118, 177);
  }
  
}

.iconCheckmarkDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(61, 61, 61);
  }
  
}

.iconCheckmarkWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconChevronDownFilledDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(20, 20, 20);
  }
  
}

.iconChevronDownActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(1, 78, 116);
  }
  
}

.iconChevronDownActionHover {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(2, 101, 151);
  }
  
}

.iconChevronDownDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(61, 61, 61);
  }
  
}

.iconChevronDownWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconChevronLeftFilledDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(20, 20, 20);
  }
  
}

.iconChevronLeftActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(1, 78, 116);
  }
  
}

.iconChevronLeftActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(2, 118, 177);
  }
  
}

.iconChevronLeftActionHover {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(2, 101, 151);
  }
  
}

.iconChevronLeftDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(20, 20, 20);
  }
  
}

.iconChevronLeftWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconChevronRightFilledDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(20, 20, 20);
  }
  
}

.iconChevronRightActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(2, 101, 151);
  }
  
}

.iconChevronRightActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(2, 118, 177);
  }
  
}

.iconChevronRightDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(20, 20, 20);
  }
  
}

.iconChevronRightWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconChevronUpFilledDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(20, 20, 20);
  }
  
}

.iconChevronUpActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(1, 78, 116);
  }
  
}

.iconChevronUpActionHover {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(2, 101, 151);
  }
  
}

.iconChevronUpDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(61, 61, 61);
  }
  
}

.iconChevronUpWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconClockLargeDigitalGrey60 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(105, 105, 105);
  }
  
}

.iconClockBaseDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(20, 20, 20);
  }
  
}

.iconCloseCircleFillDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(20, 20, 20);
  }
  
}

.iconCloseCircleActionHover {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(2, 101, 151);
  }
  
}

.iconCloseCircleDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(61, 61, 61);
  }
  
}

.iconCloseCircleWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconCloseActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(2, 118, 177);
  }
  
}

.iconCloseDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }
  
}

.iconCloseWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconDeliveryDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }
  
}

.iconFootballDigitalGrey30 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(168, 168, 168);
  }
  
}

.iconInfoFilledDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Icon], [id=Icon] path {
    display: block;
    fill: rgb(20, 20, 20);
  }
  
}

.iconInfoDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(20, 20, 20);
  }
  
}

.iconInfoWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconLocationMarkerSmallWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconLocationMarkerActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(2, 101, 151);
  }
  
}

.iconLocationPinFilledSubduedDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }
  
}

.iconLocationPinActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(2, 118, 177);
  }
  
}

.iconLocationPinDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(20, 20, 20);
  }
  
}

.iconLocationPinWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconMailDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }
  
}

.iconMasterCardDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content1], [id=Content1] path {
    display: block;
    border-radius: 3.200000047683716px;
    fill: rgb(105, 131, 215);
    outline: 0px solid rgb(151, 151, 151);
    outline-offset: 0;
  }
  
  path[id=Content2], [id=Content2] path {
    display: block;
    fill: rgb(246, 99, 95);
    outline: 0px solid rgb(151, 151, 151);
    outline-offset: 0;
  }
  
  path[id=Content3], [id=Content3] path {
    display: block;
    fill: rgb(255, 181, 72);
    outline: 0px solid rgb(151, 151, 151);
    outline-offset: 0;
  }
  
  path[id=Content4], [id=Content4] path {
    display: block;
    fill: rgb(255, 129, 80);
    outline: 0px solid rgb(151, 151, 151);
    outline-offset: 0;
  }
  
}

.iconMenuWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconMinusActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path {
    display: block;
    fill: rgb(2, 118, 177);
  }
  
}

.iconMinusDigitalGrey60 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=A], [id=A] path {
    display: block;
    fill: rgb(108, 114, 117);
  }
  
}

.iconMobileScanDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }
  
}

.iconMobileDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }
  
}

.iconMyLocationActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(2, 118, 177);
  }
  
}

.iconMyLocationDigitalGrey100 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(20, 20, 20);
  }
  
}

.iconMyLocationWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconPlusActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(37, 95, 130);
  }
  
}

.iconPlusActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(2, 118, 177);
  }
  
}

.iconPlusDigitalGrey60 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(105, 105, 105);
  }
  
}

.iconPrinterActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(1, 78, 116);
  }
  
}

.iconPrinterActionBase {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(2, 118, 177);
  }
  
}

.iconProfileDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }
  
}

.iconSearchSmallCharcoal20 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(139, 147, 158);
  }
  
}

.iconSearchSmallWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconSearchBaseDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(20, 20, 20);
  }
  
}

.iconSettingsDigitalGrey60 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(105, 105, 105);
  }
  
}

.iconShirtBaseDark {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(20, 20, 20);
  }
  
}

.iconShirtWhite {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  & path { // MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconThumbtackDigitalGrey30 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=E], [id=E] path {
    display: block;
    fill: rgb(188, 193, 194);
  }
  
}

.iconTicketBigDigitalGrey30 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(168, 168, 168);
  }
  
}

.iconTicketCutDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }
  
}

.iconTicketDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }
  
}

.iconTicketWithLinesDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(68, 70, 73);
  }
  
}

.iconVSActionActive {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(2, 101, 151);
  }
  
}

.iconVisaDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content1], [id=Content1] path {
    display: block;
    border-radius: 3.200000047683716px;
    fill: rgb(255, 255, 255);
    outline: 0px solid rgb(220, 220, 220);
    outline-offset: 0;
  }
  
  path[id=Content2], [id=Content2] path {
    display: block;
    fill: rgb(86, 92, 192);
    outline: 0px solid rgb(213, 216, 219);
    outline-offset: 0;
  }
  
  path[id=Content3], [id=Content3] path {
    display: block;
    fill: rgb(235, 169, 61);
    outline: 0px solid rgb(213, 216, 219);
    outline-offset: 0;
  }
  
  path[id=Content4], [id=Content4] path {
    display: block;
    fill: rgb(85, 101, 175);
  }
  
}

.iconWarningOutlineDigitalGrey60 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(61, 61, 61);
  }
  
}

.iconWarningDigitalGrey80 {
  overflow: hidden;
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path[id=Content], [id=Content] path {
    display: block;
    fill: rgb(20, 20, 20);
  }
  
}
