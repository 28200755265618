.legalBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1.5rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0rem; // MANUAL OVERRIDE
  }
  
  .blockTitle {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .description {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .button {
    display: none;
  }
  
  @media (min-width: 1024px) {
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;

    .description {
      // height: 5.25rem; // MANUAL OVERRIDE
    }
    
    .button {
      display: flex;
      margin-top: 1rem;
    }
    
  }
}
