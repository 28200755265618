.filterGroupTitleTwoPairs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .text {
    display: none;
  }

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .titleFilter {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-left: 0.5rem; // MANUAL OVERRIDE
  }

  .filterList {
    display: flex;
    width: unset;
    align-self: stretch;
    // margin-top: 1rem;
    margin-left: 0.25rem; // MANUAL OVERRIDE
  }

  // MANUAL OVERRIDE STARTS
  .tabList {
    display: none;
  }

  // MANUAL OVERRIDE ENDS

  @media (min-width: 1024px) {

    .text {
      display: flex;
    }

    .content {
      flex-direction: row;
      margin-top: 1.5rem;
    }

    .titleFilter {
      display: none;
      width: unset;
    }

    .filterList {
      width: unset;
      margin-top: 0;
      margin-left: 1.5rem;
      flex: 0 0 auto; // MANUAL OVERRIDE
    }

    // MANUAL OVERRIDE STARTS
    .tabList {
      display: flex;
      width: unset;
      flex: 1 1 auto;
      flex: 0 0 auto;
      gap: 2rem;
    }

    // MANUAL OVERRIDE ENDS

  }
}

.filterGroupTwoPairsOtherFilter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    gap: 1rem; // MANUAL OVERRIDE
  }

  .titleFilter {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-left: 0.5rem; // MANUAL OVERRIDE - for alignment
  }

  .tabList {
    display: none;
  }

  .filterList {
    display: flex;
    width: unset;
    flex-direction: row; // MANUAL OVERRIDE
    justify-content: space-between; // MANUAL OVERRIDE
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

  .otherFilter {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 2rem;
  }

  @media (min-width: 1024px) {

    .content {
      flex-direction: row;
      flex: 0 0 auto; // MANUAL OVERRIDE

      >* {
        margin-top: 0rem; // MANUAL OVERRIDE
      }
    }

    .titleFilter {
      display: none;
      width: unset;
    }

    .tabList {
      display: flex;
      width: unset;
      flex: 1 0 auto; // MANUAL OVERRIDE
    }

    .filterList {
      width: unset;
      margin-top: 0;
      // margin-left: 1.5rem; // MANUAL OVERRIDE
    }

    .otherFilter {
      margin-top: 1.5rem;
    }

  }
}