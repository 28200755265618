.textBodyExtraExtraSmallRegularDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.5rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: center;
    flex: 1 1 auto; // MANUAL OVERRIDE
  }
  
  @media (min-width: 1024px) { // MANUAL OVERRIDE

    .value {
      font-size: 0.625rem;
      font-weight: 600;
    }
    
  }
}

.textBodyExtraExtraSmallRegularSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.5rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: center;
  }
  
  @media (min-width: 1024px) { // MANUAL OVERRIDE

    .value {
      font-size: 0.625rem;
      font-weight: 600;
    }
    
  }
}

.textBodyExtraSmallSemiBoldDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 0.75rem;
    text-align: center;
    flex: 1 1 auto; // MANUAL OVERRIDE
  }
  
}

.textBodyExtraSmallRegularActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 118, 177);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: left;
  }
  
}

.textBodyExtraSmallRegularSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: center;
    flex: 1 1 auto; // MANUAL OVERRIDE
  }
  
}

.textBodyExtraSmallRegularSubduedLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: center;
    flex: 1 1 auto; // MANUAL OVERRIDE
  }
  
}

.textBodyExtraSmallRegularBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(20, 20, 20);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-align: left;
  }
  
}

.textBodyExtraSmallSemiBoldNegativeLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(204, 36, 39);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 0.75rem;
    text-align: left;
  }
  
}

.textBodyExtraSmallSemiBoldSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 0.75rem;
    text-align: center;
  }
  
}

.textBodyExtraSmallSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(30, 106, 11);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 0.75rem;
    text-align: left;
  }
  
}

.textBodyExtraSmallSemiBoldWarningLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(132, 96, 3);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.625rem;
    font-weight: 600;
    line-height: 0.75rem;
    text-align: left;
  }
  
}

.textBodyLargeRegularActionActiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(1, 78, 116);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textBodyLargeRegularActionBaseCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 118, 177);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
  }
  
}

.textBodyLargeRegularActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 118, 177);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textBodyLargeRegularActionBaseRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 118, 177);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: right;
  }
  
}

.textBodyLargeRegularActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 101, 151);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textBodyLargeRegularBaseDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(20, 20, 20);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
  }
  
}

.textBodyLargeRegularBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(20, 20, 20);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textBodyLargeRegularBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
  }
  
}

.textBodyLargeRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem; // MANUAL OVERRIDE
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    .value {
      display: block;
      color: rgb(255, 255, 255);
      font-family: 'Optimist', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
    }
  }
  // MANUAL OVERRIDE ENDS
  
}

.textBodyLargeRegularDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
  }
  
}

.textBodyLargeRegularDisabledLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textBodyLargeRegularNegativeLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(204, 36, 39);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textBodyLargeRegularSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    flex: 1 1 auto; // MANUAL OVERRIDE
  }
  
}

.textBodyLargeRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textBodyLargeRegularSubduedLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
  }
  
}

.textBodyLargeRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textBodyLargeSemiBoldActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 118, 177);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textBodyLargeSemiBoldBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(20, 20, 20);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textBodyLargeSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textBodyLargeSemiBoldDisabledLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textBodyLargeSemiBoldPositiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(30, 106, 11);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textBodyLargeSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textBodyLargeSemiBoldSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: normal; // MANUAL OVERRIDE
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textBodyMediumRegularActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 118, 177);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

// MANUAL OVERRIDE STARTS
.textBodyMediumRegularActionActiveCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 118, 177);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: center;
  }
  
}
// MANUAL OVERRIDE ENDS

// MANUAL OVERRIDE STARTS
.textBodyMediumRegularActionLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(37, 95, 130);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
}
// MANUAL OVERRIDE ENDS

.textBodyMediumRegularActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 101, 151);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

.textBodyMediumRegularBaseDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(20, 20, 20);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: center;
  }
  
}

.textBodyMediumRegularBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(20, 20, 20);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

.textBodyMediumRegularBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: center;
  }
  
}

.textBodyMediumRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

.textBodyMediumRegularBrandLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(16, 37, 63);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

.textBodyMediumRegularDisabledLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

// MANUAL OVERRIDE STARTS
.textBodyMediumRegularNegativeLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(204, 36, 39);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}
// MANUAL OVERRIDE ENDS

.textBodyMediumRegularCharcoal10Left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(188, 191, 199);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
  
  @media (min-width: 1024px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}



.textBodyMediumRegularPositiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(30, 106, 11);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

.textBodyMediumRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }

}

.textBodyMediumRegularSubduedDarkRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: right;
  }
  
}

.textBodyMediumRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value, .valueFree { // MANUAL OVERRIDE
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }

  // MANUAL OVERRIDE STARTS
  .valueFree {
    color: rgb(0, 127, 155);
  }
  // MANUAL OVERRIDE ENDS
  
  @media (min-width: 1024px) { // MANUAL OVERRIDE

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBodyMediumSemiBoldDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.3125rem;
    text-align: center;
  }
  
}

.textBodyMediumSemiBoldSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.3125rem;
    text-align: center;
    flex: 1 1 auto; // MANUAL OVERRIDE
  }
  
}

// MANUAL OVERRIDE
.textBodyMediumRegularSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: center;
    flex: 1 1 auto;
  }
}

// MANUAL OVERRIDE
.textBodyMediumRegularSubduedLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: center;
    flex: 1 1 auto;
  }
}

.textBodyMediumRegularSubduedLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: center;
  }
  
}

.textBodyMediumRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBodyMediumSemiBoldBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(20, 20, 20);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

.textBodyMediumSemiBoldPositiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(30, 106, 11);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

.textBodyMediumSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

.textBodyMediumSemiBoldPositiveLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(0, 129, 64);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

.textBodySmallRegularActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 118, 177);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
  }
  
}

.textBodySmallRegularBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: center;
  }
  
  @media (min-width: 1024px) { // MANUAL OVERRIDE

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBodySmallRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
  }
  
}

.textBodySmallRegularCharcoal20Left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(139, 147, 158);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
  }
  
}

.textBodySmallRegularDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem; // MANUAL OVERRIDE
    font-weight: 400;
    line-height: 1.125rem;
    text-align: center;
    flex: 1 1 auto; // MANUAL OVERRIDE
  }
  
  @media (min-width: 1024px) { // MANUAL OVERRIDE

    .value {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.3125rem;
    }
    
  }
}

.textBodySmallRegularNegativeLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(204, 36, 39);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
  }
  
}

.textBodySmallRegularSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: center;
  }
  
  @media (min-width: 1024px) { // MANUAL OVERRIDE

    .value {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.3125rem;
    }
    
  }
}

.textBodySmallRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
  }
  
}

.textBodySmallRegularSubduedLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: center;
  }
  
}

.textBodySmallRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
  }
  
}

.textBodySmallSemiBoldActionLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 118, 177);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
  }
  
}

.textBodySmallSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
  }
  
}

.textBodySmallSemiBoldDisabledCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: center;
  }
  
}

.textBodySmallSemiBoldNegativeLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(186, 38, 41);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
  }
  
}

.textBodySmallSemiBoldPositiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(30, 106, 11);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
  }
  
}

.textBodySmallSemiBoldSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: center;
  }
  
}

.textBodySmallSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
  }
  
}

.textBodySmallSemiBoldWarningLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(132, 96, 3);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.125rem;
    text-align: left;
  }
  
}

.textButtonTextMediumRegularActionActiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(1, 78, 116);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
  
}

.textButtonTextMediumRegularActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(17, 125, 180);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
  
}

.textButtonTextMediumRegularActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 101, 151);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
  
}

.textButtonTextMediumRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
  
}

.textButtonTextMediumRegularDisabledLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
  
}

.textButtonTextMediumRegularUnderlineActionActiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(1, 78, 116);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
  
}

.textButtonTextMediumRegularUnderlineActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 101, 151);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
  
}

.textCardBodyLargeRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textCardBodyMediumRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

.textCardBodyMediumRegularPositiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(30, 106, 11);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

.textCardBodyMediumRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

.textCardBodyMediumRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

.textCardBodySmallRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
  }
  
}

.textContactBodyLargeRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textContactBodyLargeSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

// MANUAL OVERRIDE STARTS
.textDecorationLargeRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
}
// MANUAL OVERRIDE ENDS

.textDecorationLargeRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61); // MANUAL OVERRIDE
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
    text-decoration: underline; // MANUAL OVERRIDE
    text-decoration-color: rgb(61, 61, 61); // MANUAL OVERRIDE
  }
  
  @media (min-width: 1024px) { // MANUAL OVERRIDE

    .value {
      color: rgb(61, 61, 61);
    }
    
  }
}

.textDecorationMediumRegularActionActiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(1, 78, 116);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textDecorationMediumRegularActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 118, 177);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textDecorationMediumRegularActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 101, 151);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    text-decoration: underline; // MANUAL OVERRIDE
    text-decoration-color: rgb(2, 101, 151); // MANUAL OVERRIDE
  }
  
}

.textDecorationMediumRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textDecorationMediumSemiBoldSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5rem;
    text-align: left;
  }
  
}

.textDecorationSmallRegularActionActiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(1, 78, 116);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

.textDecorationSmallRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

.textMLBCardTitleMediumSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.25rem;
    text-align: left;
  }
  
}

.textNewBannerBodyMediumRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
    
  }
}

.textBannerHeadingSmallSemiBoldActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 118, 177);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: normal; // MANUAL OVERRIDE
    line-height: 1.875rem;
    text-align: left;
  }
  
  @media (min-width: 1024px) { // MANUAL OVERRIDE

    .value {
      font-size: 2rem;
      line-height: 2.625rem;
    }
    
  }
}

// MANUAL OVERRIDE STARTS
.textBannerHeadingLargeLightBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.625rem;
    text-align: left;
  }
}

.textBannerSubheadingSmallRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
  }
}
// MANUAL OVERRIDE ENDS

.textBannerSubheadingLargeLightBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.25rem;
    text-align: left;
  }
  
}

.textHeadingSmallLightBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(20, 20, 20);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.625rem;
    text-align: left;
  }
  
}

.textHeadingSmallRegularBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.625rem;
    text-align: center;
  }
  
}

.textHeadingSmallRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.625rem;
    text-align: left;
  }
  
}

.textNewBannerHeadingMediumRegularBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(20, 20, 20);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.625rem;
    text-align: left;
  }
  
}

.textNewBannerHeadingMediumRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.625rem;
    text-align: left;
  }
  
}

.textNewEventDetailsTitleMediumSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2rem;
    text-align: left;
  }
  
  @media (min-width: 768px) {

    .value {
      line-height: 1.875rem;
    }
    
  }
}

.textSubheadingLargeLightSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.25rem;
    text-align: center;
  }
  
}

.textSubheadingLargeLightSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.25rem;
    text-align: left;
  }
  
}

.textSubheadingLargeRegularBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.25rem;
    text-align: center;
  }
  
  @media (min-width: 768px) {

    .value {
      font-size: 2rem;
      line-height: 2.625rem;
    }
    
  }
}

.textSubheadingLargeRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.25rem;
    text-align: left;
  }
  
  @media (min-width: 1024px) { // MANUAL OVERRIDE

    .value {
      font-size: 2rem;
      line-height: 2.625rem;
    }
    
  }
}

.textSubheadingLargeRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.25rem;
    text-align: left;
  }
  
}

// MANUAL OVERRIDE STARTS
.textSubheadingLargeRegularSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.25rem;
    text-align: center;
  }
  
}
// MANUAL OVERRIDE ENDS

.textSubheadingLargeRegularSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.25rem;
    text-align: left;
  }
  
}

.textSubheadingLargeSemiBoldActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 118, 177);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.25rem;
    text-align: left;
  }
  
}

.textSubheadingLargeSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.25rem;
    text-align: left;
  }
  
}

.textSubheadingMediumLightSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.875rem;
    text-align: left;
  }
  
}

.textSubheadingMediumLightSubduedLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
  
}

.textSubheadingMediumLightSubduedLightRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(105, 105, 105);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: right;
  }
  
}

.textSubheadingMediumRegularActionActiveLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(1, 78, 116);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
  
}

.textSubheadingMediumRegularActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(17, 125, 180);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
  
}

.textSubheadingMediumRegularActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 101, 151);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
  
}

.textSubheadingMediumRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
  
  // MANUAL OVERRIDE - removed changes for larger breakpoints
}

.textSubheadingMediumRegularDisabledLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(125, 125, 125);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
  
}

.textSubheadingMediumRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
  
  @media (min-width: 1024px) { // MANUAL OVERRIDE

    .value {
      font-size: 1.25rem; // MANUAL OVERRIDE
      line-height: 2.25rem;
    }
    
  }
}

.textSubheadingMediumRegularSubduedDarkRight {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: right;
  }
  
}

.textSubheadingMediumRegularUnderlineActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 101, 151);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
  
}

.textSubheadingMediumSemiBoldActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(17, 125, 180);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.875rem;
    text-align: left;
  }
  
}

.textSubheadingSmallRegularBaseLightCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: center;
  }
  
  @media (min-width: 768px) {

    .value {
      line-height: 2rem;
      text-align: left;
    }
    
  }
  @media (min-width: 1024px) {

    .value {
      line-height: 1.875rem;
      text-align: center;
    }
    
  }
}

.textSubheadingSmallRegularSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: center;
    flex: 1 1 auto; // MANUAL OVERRIDE
  }
  
}

.textSubheadingSmallRegularSubduedDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
  
}

.textCardBodyMediumRegularCharcoal10Left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(188, 191, 199);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: left;
  }
  
}

.textBannerSubheadingLargeRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.625rem;
    text-align: left;
  }
  
}

.textHeadingSmallLightBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.625rem;
    text-align: left;
  }
  
}

.textHeadingSmallSemiBoldActionBaseLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 118, 177);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.625rem;
    text-align: left;
  }
  
}

.textHeadingSmallSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.625rem;
    text-align: left;
  }
  
}

// MANUAL OVERRIDE STARTS
.textBodyLargeRegularUnderlineActionHoverLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(2, 101, 151);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    text-decoration: underline;
  }
  
}
// MANUAL OVERRIDE ENDS

.textBodyMediumRegularSubduedDarkCenter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(61, 61, 61);
    font-family: 'Optimist', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3125rem;
    text-align: center;
  }
  
}

.textBodyMediumSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1rem; // MANUAL OVERRIDE
    font-weight: 600;
    line-height: 1.5rem; // MANUAL OVERRIDE
    text-align: left;
  }
  
}

.textNewBannerHeadingLargeRegularBaseDarkLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(20, 20, 20);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.625rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 3rem;
      line-height: 3.875rem;
    }
    
  }
}

.textNewBannerHeadingLargeRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.625rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 3rem;
      line-height: 3.875rem;
    }
    
  }
}

.textNewEventDetailsTitleLargeSemiBoldBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2.25rem;
    text-align: left;
  }
  
}

.textSubheadingSmallRegularBaseLightLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Optimist', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.875rem;
    text-align: left;
  }
  
}
