.exclusivesCardList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem; // MANUAL OVERRIDE
  // MANUAL OVERRIDE STARTS
  overflow: auto;
  flex-wrap: nowrap;
  align-self: stretch;
  flex: 0 0 auto;
  padding: 0rem 0rem 0.2rem 1.5rem;
  // MANUAL OVERRIDE ENDS

  .card {
    display: flex;
    max-width: calc(100% - 0rem); // MANUAL OVERRIDE
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  @media (min-width: 1024px) {
    flex-wrap: wrap; // MANUAL OVERRIDE
    justify-content: center;
    width: 58rem;
    overflow: unset; // MANUAL OVERRIDE
    gap: 1rem; // MANUAL OVERRIDE
    padding: 0rem 0rem 0.2rem 0rem; // MANUAL OVERRIDE

    .card {
      width: calc(33.333333333333336% - 0.6666666666666666rem);
      // MANUAL OVERRIDE - removed static height
    }
    
  }
}
