.secondaryBannerLightBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;

  .secondaryBanner {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 768px) {
    padding: 2rem 2rem 2rem 2rem;

  }
  // MANUAL OVERRIDE - removed height for secondaryBanner in 1024 breakpoint
  @media (min-width: 1440px) {
    padding: 2rem 3rem 2rem 3rem; // MANUAL OVERRIDE

  }
}
