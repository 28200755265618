.allButton:hover {
  background-color: rgba(255, 255, 255, 0.4000000059604645);
}

.allButton:active {
  background-color: rgba(255, 255, 255, 0.4000000059604645);
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.5rem;
  }
  align-self: center;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .divider {
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    background-color: transparent;
    height: auto;
  }

  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(-n + 10) {
      margin-top: 0;
    }
    &:nth-child(10n + 1) {
      margin-left: 0;
    }
  }
}

.content:hover {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  background: linear-gradient(
    1.5708rad,
    rgba(255, 255, 255, 0.4000000059604645) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  &:nth-child(1) {
    margin-top: 0;
  }

  .divider {
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    background-color: white;
    height: auto; // MANUAL OVERRIDE
  }

  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(-n + 10) {
      margin-top: 0;
    }
    &:nth-child(10n + 1) {
      margin-left: 0;
    }
  }
}

.content:active {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }

  .divider {
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
    background-color: transparent;
  }

  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(-n + 10) {
      margin-top: 0;
    }
    &:nth-child(10n + 1) {
      margin-left: 0;
    }
  }
}
