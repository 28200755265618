.recentlyViewedContent {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(212, 212, 212);
  outline-offset: 0;

  // MANUAL OVERRIDE STARTS
  .linkContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
  }
  // MANUAL OVERRIDE ENDS

  .image {
    display: flex;
    width: 6rem; // MANUAL OVERRIDE
    height: 6rem; // MANUAL OVERRIDE
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    // MANUAL OVERRIDE - removed padding
    width: unset;
    flex: 1 1 auto;
    margin-left: 1rem;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
    padding-top: 0.25rem; // MANUAL OVERRIDE
  }
  
  .title {
    display: flex;
    width: unset;
    height: 2.625rem;
    align-self: stretch;

    // MANUAL OVERRIDE STARTS
    margin-bottom: 0.3rem;
    --max-lines: 2;
    --lh: 1.3rem;
    max-height: calc(var(--lh) * var(--max-lines));
    text-overflow: ellipsis;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;

    -webkit-line-clamp: 2;
    display: -webkit-box;
    line-clamp: 2;
    overflow: hidden;
    p {
      font-size: 1rem;
      line-height: 1.3rem;
    }
    // MANUAL OVERRIDE ENDS
  }
  
  .eventLogistics {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .dateAndTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;

    // MANUAL OVERRIDE STARTS
    overflow: hidden;
    --max-lines: 1;
    --lh: 1.3rem;
    max-height: calc(var(--lh) * var(--max-lines));
    // MANUAL OVERRIDE ENDS
  }
  
  .date {
    display: flex;
    // MANUAL OVERRIDE STARTS
    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    // MANUAL OVERRIDE ENDS
  }
  
  .time {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .location {
    display: flex;
    width: unset;
    align-self: stretch;

    // MANUAL OVERRIDE STARTS
    --max-lines: 1;
    --lh: 1.5rem;
    max-height: calc(var(--lh) * var(--max-lines));
    text-overflow: ellipsis;
    overflow: hidden;
    // MANUAL OVERRIDE ENDS
  }
  
  .button {
    display: flex;
    margin-left: 0.5rem;
  }

  // MANUAL OVERRIDE STARTS
  .button:focus-visible {
    outline: 2px solid rgb(2, 101, 151);
  }
  // MANUAL OVERRIDE ENDS
  
}
