.ticketSelectionPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(0, 19, 43);
  flex: 1 1 auto;

  .c1XEventDetailsSliderBlock {
    display: flex;
    width: unset;
    height: 11.25rem;
    align-self: stretch;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .ticketTypeSelectionBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 768px) {

    .c1XEventDetailsSliderBlock {
      height: 20rem;
    }
    
  }
  @media (min-width: 1024px) {

    .c1XEventDetailsSliderBlock {
      height: 23.75rem;
    }
    
    .container {
      height: unset;
    }
    
  }
  @media (min-width: 1440px) {

    .c1XEventDetailsSliderBlock {
      height: 26.875rem;
    }
    
  }
  @media (min-width: 1920px) {

    .c1XEventDetailsSliderBlock {
      height: 32.5rem;
    }
    
    .container {
      width: 90rem;
      align-self: center;  // MANUAL OVERRIDE
    }
    
  }
}
