.dateAndTime {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .icon {
    display: flex; // MANUAL OVERRIDE
    width: 1.25rem; // MANUAL OVERRIDE
    height: 1.25rem; // MANUAL OVERRIDE
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
}
