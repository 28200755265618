.cardInfoNotRequiredBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1.5rem 1rem;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0.2rem; // MANUAL OVERRIDE

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .icon {
    display: block;
    width: 3.5rem;
    height: 3.5rem;
    margin: auto; // MANUAL OVERRIDE
  }
  
  .topContent {
    // MANUAL OVERRIDE - removed display:none
    // MANUAL OVERRIDE STARTS
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    margin-top: 0;
    // MANUAL OVERRIDE ENDS
  }
  
  .blockTitle {
    display: flex;
    width: unset;
    height: 1.875rem;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .button {
    display: none;
  }
  
  .description {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
    margin-top: 0.5rem;
  }
  
  @media (min-width: 1024px) {
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;

    // MANUAL OVERRIDE
    .icon {
      // display: none;
      width: 3rem;
      height: 3rem;
    }
    
    .topContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: unset;
      align-self: stretch;
      gap: 0.5rem; // MANUAL OVERRIDE
    }
    
    .blockTitle {
      // display: none;    // MANUAL OVERRIDE
      width: unset;
      height: unset;
      margin-top: 0;
    }
    
    .button {
      display: flex;
      margin-top: 1.5rem;
      align-self: center; // MANUAL OVERRIDE
    }
    
    .description {
      // display: none;    // MANUAL OVERRIDE
      width: unset;
      margin-top: 0;  
    }
    
  }
}
