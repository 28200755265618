.submenuTabItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  //  MANUAL OVERRIDE - removed background

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:active {
    background: linear-gradient(1.5708rad, rgba(255, 255, 255, 0.23999999463558197) 0%, rgba(255, 255, 255, 0) 100%);
  
  }
  &:hover {
    background: linear-gradient(1.5708rad, rgba(255, 255, 255, 0.23999999463558197) 0%, rgba(255, 255, 255, 0) 100%);
  
  }
}
