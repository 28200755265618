.toggleItemList {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  border-radius: 100px;
  background-color: rgb(244, 244, 244);

  .toggleItem {
    display: flex;
  }
  
}
