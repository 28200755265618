// MANUAL OVERRIDE STARTS
.slider {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex: 1 1 auto;
  :nth-child(2) {
    background: #d4d4d4;
  }
}
.track {
  cursor: default;
  height: 8px;
  background: #026597;
  border: none;
  border-radius: 5px;
}
.dualSlider {
  display: flex;
  align-self: stretch;
  align-items: center;
  flex: 1 1 auto;
  :nth-child(2) {
    background: #026597;
  }
}
.dualTrack {
  cursor: default;
  height: 8px;
  background: #d4d4d4;
  border: none;
  border-radius: 5px;
}

.thumb {
  height: 24px;
  width: 24px;
  background: #ffffff;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
}
.thumb:focus-visible {
  outline: none;
}
// MANUAL OVERRIDE ENDS