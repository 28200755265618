.checkBoxItemSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 1rem;
  }
  
  &:focus-visible {
    border: 1px dashed rgb(0, 0, 0);
    outline-offset: 0;
  
  }
}

.checkBoxItemUnselected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.6rem; // MANUAL OVERRIDE
    outline: none; // MANUAL OVERRIDE
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 1rem;
  }
  
  &:focus-visible {
    border: 1px dashed rgb(20, 20, 20);
    outline-offset: 0;

    // MANUAL OVERRIDE STARTS
    .icon {
      height: 1.5rem;
    }
    // MANUAL OVERRIDE ENDS
  
  }
}
