.secondaryBannerBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(244, 244, 244);

  .secondaryBanner {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.625rem; // MANUAL OVERRIDE
  }
  
}
