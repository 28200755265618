.container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.spinner {
  overflow: hidden; // MANUAL OVERRIDE
  padding: 2rem;
  border: 0.25rem solid #0276B1; 
  border-top: 0.25rem solid transparent; 
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  animation: spin 2s linear infinite;
}

.spinnerLight {
  padding: 2rem;
  border: 0.25rem solid #FFFFFF; 
  border-top: 0.25rem solid transparent; 
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  animation: spin 2s linear infinite;
}

.hideLoadingTextFromScreen{
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}