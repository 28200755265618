.cardInfoBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1.5rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    
    // MANUAL OVERRIDE STARTS
    margin-top: 0;
    > * {
       margin-top: 0.5rem;
    }
    // MANUAL OVERRIDE ENDS
  }
  
  .blockTitle {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .description {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;

    // MANUAL OVERRIDE STARTS
    p {
      font-size: 0.875rem;
    }
    // MANUAL OVERRIDE ENDS
  }
  
  .icons {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .mastercard {
    display: block;
    width: 2rem;
    height: 2rem;
  }
  
  .visa {
    display: block;
    width: 2rem;
    height: 2rem;
    margin-left: 0.5rem;
  }
  
  .cardNumber {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem; // MANUAL OVERRIDE - changed - value
    // MANUAL OVERRIDE STARTS
    height: 3rem;
    border: 1px solid rgb(105, 105, 105);
    border-radius: 4px;
    // MANUAL OVERRIDE ENDS
  }
  
  .fieldGroup1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .expiration {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    // MANUAL OVERRIDE STARTS
    height: 3rem;
    border: 1px solid rgb(105, 105, 105);
    border-radius: 4px;
    margin-top: 0.5rem;
    // MANUAL OVERRIDE ENDS
  }
  
  .securityCode {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 1rem;
    // MANUAL OVERRIDE STARTS
    height: 3rem;
    border: 1px solid rgb(105, 105, 105);
    border-radius: 4px;
    margin-top: 0.5rem;
    // MANUAL OVERRIDE ENDS
  }
  
  .button {
    display: none;
  }
  
  @media (min-width: 1024px) {
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;

    .description {
      height: 1.125rem;
    }
    
    .button {
      display: flex;
      margin-top: 1rem;
    }

    // MANUAL OVERRIDE STARTS
    .topContent {
      & p {
        font-size: 0.875rem;
      }
    }
    // MANUAL OVERRIDE ENDS
    
  }
}
