.venueLandingPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // padding: 1rem 0rem 0rem 0rem; // MANUAL OVERRIDE
  background-color: rgb(244, 244, 244);

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

  .landingPageBannerBlock {
    display: flex;
    width: unset;
    flex-direction: column; // MANUAL OVERRIDE
    justify-content: flex-start; // MANUAL OVERRIDE
    align-items: flex-start; // MANUAL OVERRIDE
    padding: 1rem 1rem 1rem 1rem; // MANUAL OVERRIDE
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

  .venueEvents {
    display: flex;
    flex-direction: column; // MANUAL OVERRIDE
    justify-content: flex-start; // MANUAL OVERRIDE
    align-items: flex-start; // MANUAL OVERRIDE
    padding: 1rem 1rem 2rem 1rem; // MANUAL OVERRIDE
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

}

@media (min-width: 1024px) {
  .venueLandingPage {
    // padding: 1.5rem 0rem 0rem 0rem; // MANUAL OVERRIDE 

    .container {
      align-self: center; // MANUAL OVERRIDE
      width: 60rem;
      flex: 0 0 45.5rem; // MANUAL OVERRIDE
    }

    .landingPageBannerBlock {
      padding: 1.5rem 0rem 1.5rem 0rem; // MANUAL OVERRIDE
    }

    .venueEvents {
      padding: 1.5rem 0rem 1.5rem 0rem; // MANUAL OVERRIDE
    }

  }
}