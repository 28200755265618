.dropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: auto;
  flex: 0 0 auto;
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    align-self: auto;
    flex: 1 1 auto;
  }
}

.dropdownMenu {
  display: none;
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    z-index: 1;
  }
}