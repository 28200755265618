.phoneNumberGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  margin-top: 1rem;
}

.phoneNumberField {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}

.phoneNumberSave {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  flex: 0 0 auto;
  margin-left: 0.2rem;
}

.phoneNumberEdit {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.1rem;
  flex: 0 0 auto;
}

.toolTipIcon {
  height: 1.5rem;
}

.checkBoxItemError {
  margin-top: 1rem;
}