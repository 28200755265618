.ticketQuantitySelection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(228, 229, 229);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .dropdown {
    display: flex;
    width: unset;
    align-self: stretch;
    flex-direction: column; // MANUAL OVERRIDE

    // MANUAL OVERRIDE
    select {
      margin-top: 0.5rem;
    }
  }
  
  .button {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
  @media (min-width: 1024px) {

    .content {
      padding: 0.625rem 1rem 1rem 1rem;
    }
    
  }
}
