.receiptBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .title {
    margin-left: 0.15rem; //MANUAL OVERRIDE
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .orderDetails {
    display: flex;
    // width: unset; //MANUAL OVERRIDE
    align-self: stretch;
    margin-top: 1.5rem;
  }

  @media (min-width: 1024px) {
    background-color: rgb(244, 244, 244);
    width: 60rem; //MANUAL OVERRIDE
    height: unset; //MANUAL OVERRIDE
    flex: 0 0 52.1875rem; //MANUAL OVERRIDE

    .orderDetails {
      padding: 1.5rem 3rem 3rem; //MANUAL OVERRIDE
    }
  }
}