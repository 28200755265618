.cardDefaultLong {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.10000000149011612);

  .image {
    display: flex;
    width: unset;
    height: 10rem;
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0rem 0.5rem 0rem 0rem;
    width: unset;
    align-self: stretch;
  }

  .eventInfo {
    display: flex;
    width: 16rem; // MANUAL OVERRIDE
    flex: 1 1 auto;
  }

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }

  .button {
    display: none;
  }

  &:focus-visible {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

  }

  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

  }

  @media (min-width: 1024px) {
    flex-direction: row;
    flex: 1 1 auto;
    align-items: center;
    padding: 0rem 1.5rem 0rem 0rem;
    outline: 1px solid rgb(229, 229, 229);
    outline-offset: 0;
    height: 9.75rem; //MANUAL OVERRIDE

    .image {
      width: 9.75rem; //MANUAL OVERRIDE
      height: 9.75rem; //MANUAL OVERRIDE
    }

    .content {
      justify-content: flex-start;
      height: unset;
      flex: 1 1 auto;
      padding: 0rem // MANUAL OVERRIDE
    }

    .eventInfo {
      height: unset;
      align-items: flex-start; //MANUAL OVERRIDE
      align-self: center; //MANUAL OVERRIDE
    }

    .icon {
      display: none;
      width: unset;
      height: unset;
    }

    .button {
      display: flex;
      flex: 0 0 auto;
    }

    &:focus-visible {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

    }

    &:hover {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

    }
  }
}

.cardDefaultSquare {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.10000000149011612);

  .image {
    display: flex;
    width: unset;
    height: 10rem;
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0rem 0.5rem 0rem 0rem;
    width: unset;
    align-self: stretch;
  }

  .eventInfo {
    display: flex;
    width: unset;
    flex: 0 0 auto; // MANUAL OVERRIDE
    padding: 1rem 1rem 1rem 1rem; // MANUAL OVERRIDE
  }

  &:focus-visible {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

  }

  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

  }

  @media (min-width: 1024px) {
    width: 20rem;
    // MANUAL OVERRIDE - removed static height

    .content {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      height: unset;
      flex: 1 1 auto;
    }

    .eventInfo {
      height: unset;
      align-self: stretch;
      padding: 1.5rem 1.5rem 1.5rem 1.5rem; // MANUAL OVERRIDE
    }

    // MANUAL OVERRIDE STARTS
    .icon {
      display: none;
    }

    // MANUAL OVERRIDE ENDS

    &:focus-visible {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

    }

    &:hover {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

    }
  }
}

.cardExclusiveLong {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.10000000149011612);

  .image {
    display: flex;
    width: unset;
    height: 10rem;
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0rem 0.5rem 0rem 0rem;
    width: unset;
    align-self: stretch;
  }

  .eventInfo {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    padding: 1rem 1rem 1rem 1rem; // MANUAL OVERRIDE
  }

  .icon {
    display: flex; // MANUAL OVERRIDE
    width: 1.5rem;
    height: 1.5rem;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

  .button {
    display: none;
  }

  &:focus-visible {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

  }

  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

  }

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    padding: 0rem 1.5rem 0rem 0rem;
    outline: 1px solid rgb(229, 229, 229);
    outline-offset: 0;
    height: 9.75rem;

    .image {
      width: 9.75rem;
      height: 9.75rem;
    }

    .content {
      justify-content: flex-start;
      height: unset;
      flex: 1 1 auto;
    }

    .icon {
      display: none;
      width: unset;
      height: unset;
    }

    .button {
      display: flex;
    }

    // MANUAL OVERRIDE STARTS
    .eventInfo {
      padding: 1.25rem 1rem 1.25rem 1rem;
    }

    // MANUAL OVERRIDE ENDS

    &:focus-visible {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

    }

    &:hover {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

    }
  }
}

.cardNoThumbnailImageLong {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(229, 229, 229);
  outline-offset: 0;

  .leftContent {
    display: none;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }

  .performerInfo {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }

  .button {
    display: none;
  }

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;
  }

  &:focus-visible {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

  }

  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

  }

  @media (min-width: 1024px) {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    height: 7.6875rem;

    .leftContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: unset;
      align-self: stretch;
      text-wrap: nowrap; // MANUAL OVERRIDE
    }

    .content {
      height: unset;
      align-self: center; // MANUAL OVERRIDE
      margin-left: 3rem;
    }

    .performerInfo {
      height: unset;
      align-self: stretch;
    }

    .button {
      display: flex;
      margin-left: 3rem;
      text-wrap: nowrap; // MANUAL OVERRIDE
    }

    .icon {
      display: none;
      width: unset;
      height: unset;
    }

    &:focus-visible {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

    }

    &:hover {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

    }
  }
}

.cardTeamsLong {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.10000000149011612);

  .teamHomeAwayImage {
    display: flex;
    width: 20rem;
    height: 11.125rem;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0rem 0.5rem 0rem 0rem;
    width: unset;
    align-self: stretch;
  }

  .eventInfo {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }

  .button {
    display: none;
  }

  &:focus-visible {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

  }

  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

  }

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    padding: 0rem 1.5rem 0rem 0rem;
    outline: 1px solid rgb(229, 229, 229);
    outline-offset: 0;
    height: 9.25rem;

    .teamHomeAwayImage {
      width: 9.25rem;
      height: 9.25rem;
    }

    .content {
      justify-content: flex-start;
      height: unset;
      flex: 1 1 auto;
    }

    .eventInfo {
      height: unset;
      align-self: stretch;
    }

    .icon {
      display: none;
      width: unset;
      height: unset;
    }

    .button {
      display: flex;
    }

    &:focus-visible {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

    }

    &:hover {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

    }
  }
}

.cardTicketStatusLong {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.10000000149011612);

  .image {
    display: flex;
    width: unset;
    height: 10rem;
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .ticketStatus {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  &:focus-visible {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

  }

  &:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

  }

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
    outline: 1px solid rgb(229, 229, 229);
    outline-offset: 0;
    height: 8.75rem;

    .image {
      width: 8.75rem;
      height: 8.75rem;
    }

    .content {
      height: unset;
      flex: 1 1 auto;
    }

    &:focus-visible {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

    }

    &:hover {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.23999999463558197);

    }
  }
}