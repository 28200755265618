.sportsMenuTabList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  .submenuTabItem {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .submenuTabItemActive {
    display: flex;
    width: unset;
    align-self: stretch;
    background: linear-gradient(4.71239rad, rgba(255, 255, 255, 0.24) 0%, rgba(255, 255, 255, 0) 100%);
  }
  
}
