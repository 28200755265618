.customerInfoReadOnlyBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1.5rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0; // MANUAL OVERRIDE
    flex: 0 0 auto;
  }
  
  .blockTitle {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .edit {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .textItem {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  @media (min-width: 1024px) {
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;

    // MANUAL OVERRIDE - removed static height for .topContent
    
  }
}
