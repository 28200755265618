.dropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  flex: 0 0 auto;
  margin-left: 1rem;
  @media (min-width: 1023px) {
    display: none;
  }

  .dropdownMenu {
    z-index: 5;
    transform: translate3d(0, 2.75rem, 0) !important;
    width: 100%;
  }
}

.desktopDropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    display: none;
  }

  .dropdownMenu {
    z-index: 5;
    transform: translate3d(0, 4.75rem, 0) !important;
    width: 100%;
  }

  .dropdownToggle {
    display: flex;
    align-self: flex-end;
    width: 100%;
  }
}