.searchReturnItemDarkBackground {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .description {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }

  // MANUAL OVERRIDE START
  .nextButton {
    display: none;
  }
  // MANUAL OVERRIDE END
  
  &:focus-visible {
    background-color: rgb(244, 244, 244);
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(244, 244, 244);
  
  }
  &:pressed {
    background-color: rgb(235, 235, 235);
  
  }
}

.searchReturnItemLightBackground {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.6875rem 1rem 0.75rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .description {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .nextButton {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .divider1 {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
}
