.specialEventCardDark {
  overflow: hidden;
  display: flex;
  position: relative; // MANUAL OVERRIDE
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  background-color: rgb(228, 229, 229);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.10000000149011612);
  width: 16rem;
  flex: 0 0 auto; // MANUAL OVERRIDE
  text-decoration: none; // MANUAL OVERRIDE
  align-self: stretch; // MANUAL OVERRIDE

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: unset;
    align-self: stretch;
    overflow: hidden; // MANUAL OVERRIDE
  }

  .image {
    display: flex;
    width: 16rem;
    height: 10.625rem;
    transition: transform 0.3s ease; // MANUAL OVERRIDE
  }

  .eventTypeTag {
    display: flex;
    position: absolute; // MANUAL OVERRIDE
    top: 12px; // MANUAL OVERRIDE
    right: 12px; // MANUAL OVERRIDE
    width: auto; // MANUAL OVERRIDE
  }

  .bottomContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: unset;
    align-self: stretch;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    flex: 1 1 auto;
  }

  .eventStatusTag {
    display: flex;
  }

  .titleText {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;

    // MANUAL OVERRIDE STARTS
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      width: 14rem; // MANUAL OVERRIDE
    }

    // MANUAL OVERRIDE ENDS
  }

  .descriptionText {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
    height: 3rem; // MANUAL OVERRIDE

    // MANUAL OVERRIDE STARTS
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      line-height: 1.5rem;
    }

    // MANUAL OVERRIDE ENDS

  }

  .eventLogistics {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.25rem 0rem 0.25rem 0rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }

  .dateAndTime {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .location {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }

  .actionContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.25rem 0rem 0rem 0rem;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }

  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }

  .textIcon {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }

  .acttionText {
    display: none;
  }

  .actionText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }

  .icon {
    display: block;
    width: 2rem;
    height: 2rem;
    margin-left: 0.25rem;
  }

  &:focus-visible {
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  }

  // MANUAL OVERRIDE STARTS
  &:hover {
    .image {
      transform: scale(1.2);
      background: rgba(228, 229, 229, 1);
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    }
  }

  // MANUAL OVERRIDE ENDS

  @media (min-width: 768px) {

    .actionContent {
      padding: 0.25rem 0rem 0.25rem 0rem;
    }

    .textIcon {
      margin-top: 0.75rem;
    }

    .acttionText {
      display: flex;
      width: unset;
      flex: 1 1 auto;
    }

    .actionText {
      display: none;
      width: unset;
    }

    &:focus-visible {
      outline: 2px dashed rgb(2, 118, 177);
      outline-offset: 0;

    }
  }

  @media (min-width: 1024px) {
    // MANUAL OVERRIDE - removed border-radius 
    width: 29.25rem; // MANUAL OVERRIDE

    .image {
      width: 29.25rem; // MANUAL OVERRIDE
      height: 14.0625rem;
      align-self: stretch;
    }

    .titleText {
      margin-top: 0.75rem;
      p {
        width: 27rem; // MANUAL OVERRIDE
      }
    }

    .descriptionText {
      margin-top: 0.75rem;
    }

    .eventLogistics {
      margin-top: 0.75rem;
    }

    .actionContent {
      margin-top: 0.75rem;
    }

    .acttionText {
      display: none;
      width: unset;
    }

    .actionText {
      display: flex;
      width: unset;
      flex: 1 1 auto;
      margin-left: 0.25rem; // MANUAL OVERRIDE
    }

    &:focus-visible {
      outline: 2px dashed rgb(2, 118, 177);
      outline-offset: 0;

    }
  }

  @media (min-width: 1440px) {
    width: 41rem;

    .image {
      width: 41rem;
      height: 19.6875rem;
    }

    .titleText {
      p {
        width: 39rem;
      }
    }

    .descriptionText {
      p {
        width: 39rem;
      }
    }
  }
}