.searchFieldSearchBubble {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 100px;
  background-color: rgb(16, 37, 63);
  outline: 1px solid rgb(96, 106, 119);
  outline-offset: 0;
  max-height: 2.4375rem; // MANUAL OVERRIDE

  .icon {
    display: block;
    width: 1.125rem;
    height: 1.125rem;
    align-self: center; // MANUAL OVERRIDE
  }
  
  .textInput {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
    // MANUAL OVERRIDE STARTS
    > input {
      caret-color: rgb(255, 255, 255);
      color: rgb(255, 255, 255);
      align-self: center;
      height: 1.3125rem; // to avoid larger searchfield in safari
      margin-left: 0rem; // MANUAL OVERRIDE
    }
    align-self: center;
    // MANUAL OVERRIDE ENDS
  }
  
  @media (min-width: 1024px) {
    align-items: center;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
    width: 15.4375rem; // MANUAL OVERRIDE

    .textInput {
      width: 12.3125rem; // MANUAL OVERRIDE
    }
  }
}

.searchFieldSearchLine {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 100px;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .textInput {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .divider {
    display: flex;
    width: unset;
    height: 0.1875rem;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  @media (min-width: 1024px) {
    width: 40rem;

  }
}
