.eventCardVenueList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 0.125rem 1rem 0.125rem; // MANUAL OVERRIDE
  width: unset; // MANUAL OVERRIDE
  gap: 1rem;

  .eventCardVenue {
    display: flex;
    width: unset;
    height: unset; // MANUAL OVERRIDE
    align-self: stretch;
  }

  @media (min-width: 768px) {
    width: unset;

    .eventCardVenue {
      height: unset;
    }

  }

  @media (min-width: 1024px) {
    overflow: hidden;
    // width: 64rem;// MANUAL OVERRIDE

  }

  @media (min-width: 1440px) {
    // width: 90rem; // MANUAL OVERRIDE

  }
}