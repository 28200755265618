.stepperBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 0rem 0rem 0rem;

  .stepperList {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  @media (min-width: 1024px) {
    padding: 0.5rem 0rem 0rem 0rem;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(212, 212, 212); // MANUAL OVERRIDE

    .divider {
      display: none;
      width: unset;
    }

  }
}