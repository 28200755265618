.hideIcons {
  display: none; //MANUAL OVERRIDE
}

.receiptPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(244, 244, 244);
  padding: 1.5rem 1rem 1.5rem 1rem; //MANUAL OVERRIDE

  // MANUAL OVERRIDE STARTS
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 0 0 auto;
  }

  // MANUAL OVERRIDE ENDS

  .receiptBlock {
    display: flex;
    // width: 60rem; //MANUAL OVERRIDE
    // height: unset; //MANUAL OVERRIDE
    // flex: 1 1 auto; //MANUAL OVERRIDE
  }
}