.cardInfoHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    height: 3.75rem;
    align-self: stretch;
  }
  
  .details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .dateAndTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .date {
    display: flex;
  }
  
  .time {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .location {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
