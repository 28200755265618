.customCardInfoBlock {
  .disable {
    pointer-events: none;
    opacity: 0.4;
  }
  
  .errorMessage {
    display: inline-block;
    color: rgb(204, 36, 39);
    font-size: 12px;
    margin-top: 0.8rem;
  }
  
  .errorBoundary {
    border: 2px solid rgb(204, 36, 39) !important;
    margin-left: 0;
  }
  
  .cardInfoBlockLabels {
    margin-left: 0;
  }
  
  .cardInfoBlockLabelsSecurity {
    margin-left: 1rem;
  }
}