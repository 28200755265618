.venueLandingPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .container {
    overflow: hidden; // MANUAL OVERRIDE
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }

  .imageBlock {
    display: flex;
    width: unset;
    align-self: stretch;
    flex-direction: column; // MANUAL OVERRIDE
    overflow: hidden; // MANUAL OVERRIDE
    padding: 1rem; // MANUAL OVERRIDE
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

  .filtersBlock {
    display: flex;
    width: 20rem;
  }

  .eventCardsBlock {
    display: flex;
    width: unset;
    align-self: stretch;
    overflow: hidden; // MANUAL OVERRIDE
    flex: 0 0 auto; // MANUAL OVERRIDE
    justify-content: flex-start; // MANUAL OVERRIDE
  }

  @media (min-width: 768px) {

    .container {
      height: unset;
    }

    .filtersBlock {
      width: unset;
      align-self: stretch;
    }

    .imageBlock {
      padding: 1rem 1.5rem 1rem 1.5rem; // MANUAL OVERRIDE
    }

    .eventCardsBlock {
      width: 48rem;
    }

  }

  @media (min-width: 1024px) {

    // MANUAL OVERRIDE STARTS
    .imageBlock {
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.5rem 2rem 1.5rem 2rem;
    }

    .filtersBlock {
      overflow: hidden;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0.5rem 2rem 0.5rem 2rem;
      align-self: flex-start;
      width: 60rem;
      flex: 0 0 auto;
    }

    .eventCardsBlock {
      flex-direction: column;
      align-items: flex-start;
      width: auto; // MANUAL OVERRIDE
    }

    .container {
      flex: 0 0 auto;
    }

    // MANUAL OVERRIDE ENDS
  }

  @media (min-width: 1440px) {

    .container {
      width: 90rem;
      align-self: center; // MANUAL OVERRIDE
    }

    .filtersBlock {
      width: unset;
      align-self: stretch;
      padding: 0.5rem 3rem 0.5rem 3rem; // MANUAL OVERRIDE

    }

    .eventCardsBlock {
      width: 90rem;
    }

    .imageBlock {
      padding: 1.5rem 3rem 1.5rem 3rem; // MANUAL OVERRIDE
    }

  }
}