.searchResult {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .label {
    display: flex;
  }

  .searchedValue {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }

  .numberOfSearchMatches {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    > * {
      margin-left: 0.25rem; // MANUAL OVERRIDE
    }
  }

  .numberOfMatches {
    margin-left: 0rem;
  }

  .eventsMatchString {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .recommendationContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .recommendationString {
    display: flex;
  }

  .button {
    display: flex;
    margin-left: 0.25rem;

  }

  .filter {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem; //MANUAL OVERRIDE
  }

  @media (min-width: 1024px) {

    .title {
      flex-direction: row;
    }

    .searchedValue {
      flex: 1 1 auto;
      margin-left: 0.5rem;
    }

    .numberOfMatches {
      display: flex;
      >* {
        margin-left: 0rem; //MANUAL OVERRIDE
      }
    }

    .eventsMatchString {
      margin-left: 0.25rem;
    }

    .content {
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 1.5rem;
    }

    .recommendationContent {
      width: unset;
      flex: 1 1 auto;
    }

    .filter {
      // flex: 1 1 auto; //MANUAL OVERRIDE
      margin-top: 0;
      margin-left: 1.5rem;
    }

  }
}