.ticketSelectionPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(244, 244, 244);
  align-self: stretch; // MANUAL OVERRIDE
  overflow: auto; // MANUAL OVERRIDE
  height: 100%; // MANUAL OVERRIDE

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch; // MANUAL OVERRIDE
    // width: 60rem; // MANUAL OVERRIDE
  }
  
  .ticketTypeSelectionBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    .container {
      width: 60rem;
      align-self: center;
    }
  }
  // MANUAL OVERRIDE ENDS
  
}

// MANUAL OVERRIDE STARTS
.hidePreCheckoutImage {
  display: none;
}
// MANUAL OVERRIDE ENDS