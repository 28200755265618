.cXEventInfoHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .eventTypeTag {
    display: flex;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0rem 1rem 0rem;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .dateAndTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .date {
    display: flex;
  }
  
  .time {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .location {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1024px) {
    
    .details {
      margin-top: 0;
    }
    
  }
}
