.performerLandingPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // padding: 1rem 0rem 0rem 0rem;  // MANUAL OVERRIDE
  background-color: rgb(244, 244, 244);
  margin-top: 0; // MANUAL OVERRIDE

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    // width: unset; // MANUAL OVERRIDE
    // height: unset; // MANUAL OVERRIDE
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

  .landingPageBannerBlock {
    display: flex;
    // width: unset; // MANUAL OVERRIDE
    flex-direction: column; // MANUAL OVERRIDE
    justify-content: flex-start; // MANUAL OVERRIDE
    align-items: flex-start; // MANUAL OVERRIDE
    padding: 1rem 1rem 1rem 1rem; // MANUAL OVERRIDE
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

  .performerEvents {
    display: flex;
    flex-direction: column; // MANUAL OVERRIDE
    justify-content: flex-start; // MANUAL OVERRIDE
    align-items: flex-start; // MANUAL OVERRIDE
    padding: 1rem 1rem 2rem 1rem; // MANUAL OVERRIDE
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

}

// MANUAL OVERRIDE STARTS
@media (min-width: 1024px) {
  .performerLandingPage {

    .container {
      align-self: center;
      width: 60rem;
      flex: 0 0 42.625rem;
    }

    .landingPageBannerBlock {
      padding: 1.5rem 0rem 1.5rem 0rem;
    }

    .performerEvents {
      padding: 1.5rem 0rem 1.5rem 0rem;
    }

    // MANUAL OVERRIDE ENDS
  }
}