.dividerHorizontalThickWhite {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  height: 0.1875rem;

}

.dividerHorizontalThin2pxCP1Red {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(205, 34, 40);
  height: 0.125rem;

}

.dividerHorizontalThinCP1Red {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(205, 34, 40);

}

.dividerHorizontalThinCoreBlue70 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(0, 19, 43);
  height: 0.0625rem;

}

.dividerHorizontalThinDigitalGrey10 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(229, 229, 229);
  height: 0.0625rem;

}

.dividerHorizontalThinDigitalGrey15 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(212, 212, 212);
  height: 0.0625rem;

}

.dividerHorizontalThinDigitalGrey5 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(244, 244, 244);
  height: 0.0625rem;

}

.dividerHorizontalThinPrimaryBlue60 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(16, 37, 63);
  height: 0.0625rem;

}

.dividerHorizontalThinWhite {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  height: 0.0625rem;

}

.dividerVerticalThickCP1Red {
  display: block;
  background-color: rgb(204, 36, 39);
  width: 0.1875rem;
  height: 4.125rem;

}

.dividerVerticalThickClear {
  display: block;
  background-color: rgb(255, 255, 255);
  width: 0.25rem;
  height: 4.125rem;

}

.dividerVerticalThickCoreBlue70 {
  display: block;
  background-color: rgb(0, 19, 43);
  width: 0.1875rem;
  height: 4.125rem;

}

.dividerVerticalThickInteractionBlue50 {
  display: block;
  background-color: rgb(2, 118, 177);
  width: 0.1875rem;
  height: 4.125rem;

}

.dividerVerticalThickWhite {
  display: block;
  background-color: rgb(255, 255, 255);
  width: 0.1875rem;
  height: 4.125rem;

}

.dividerVerticalThinDigitalGrey10 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(229, 229, 229);
  width: 0.0625rem;
  height: 2rem;

}

.dividerVerticalThinDigitalGrey15 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(212, 212, 212);
  width: 0.0625rem;
  height: 2rem;

}

.dividerVerticalThinDigitalGrey5 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(244, 244, 244);
  width: 0.0625rem;
  height: 2rem;

}

.dividerVerticalThinPrimaryBlue60 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(16, 37, 63);
  width: 0.0625rem;
  height: 2rem;

}

.dividerVerticalThinWhite {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  width: 0.0625rem;
  height: 2rem;

}
