.searchMatchPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // padding: 1rem 1rem 0rem 1rem; //MANUAL OVERRIDE
  background-color: rgb(244, 244, 244);

  // MANUAL OVERRIDE START
  .searchContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 2rem 1rem;

    >* {
      margin-top: 1.5rem;
    }

    align-self: stretch;
    flex: 0 0 auto;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  // MANUAL OVERRIDE END

  .searchMatchesBlock {
    display: flex;
    flex-direction: column; //MANUAL OVERRIDE
    justify-content: flex-start; //MANUAL OVERRIDE
    align-items: flex-start; //MANUAL OVERRIDE
    padding: 1rem 0rem 1rem 0rem; //MANUAL OVERRIDE


    align-self: stretch;
    flex: 1 1 auto;
  }

  .searchBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

}

//MANUAL OVERRIDE START
@media (min-width: 1024px) {
  .searchMatchPage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(244, 244, 244);

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 60rem;
      flex: 0 0 32.75rem;
    }

    .searchMatchesBlock {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.5rem 0rem 1.5rem 0rem;

      align-self: stretch;
      flex: 0 0 auto;
    }

    .searchBlock {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    //MANUAL OVERRIDE END
  }
}