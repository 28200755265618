.additionalTicketInformationList {
  overflow: hidden;
  display: flex;
  flex-direction: column; // MANUAL OVERRIDE
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 0px 0px 12px 12px;
  background-color: rgb(228, 229, 229);
  gap: 1rem;

  .iconTextDescription {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
