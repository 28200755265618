.customSearchField {

  .customTextInput {

    .customButton {
      display: flex;
      align-self: center;
      padding: 0;

      &:hover {
        background-color: rgb(1, 61, 88);
        border-radius: 100px;
      }
    }
  }
}