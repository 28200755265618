.checkoutFlowBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0.625rem 1rem 0 1rem;
  padding: 1rem 1rem 1.5rem 1rem;
  > * {
    margin-top: 1rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 1023px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .customCheckbox {
    margin-top: 1rem;
  
    .phoneNumberPrivacyCheckboxIconError {
      border: 0.0625rem solid rgb(204, 36, 39);
    }
    
    .phoneNumberPrivacyCheckboxIcon {
      display: flex;
      flex: 0 0 auto;
    }
  }

  .phoneNumberPrivacyErrorMessage {
    display: none;
  }
  
  .billingInfoButton {
    margin-top: 1.5rem;
  }

  // to apply style of bodyMediumSemiBoldBaseDarkLeft
  .boldTitle {
    color: rgb(20, 20, 20);
    font-weight: 600;
  }

  @media (min-width: 1024px) {
    width: 39rem;
    margin: 0;
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;
    &:nth-child(1) {
      margin-top: 0;
    }
  }

}

.orderInfoMobile {
  align-self: stretch;
  margin: 0.625rem 1rem 0 1rem;

  @media (min-width: 1024px) {
    display: none;
  }
}

@media (min-width: 1024px) {
  .orderInfo {
    .topContent {
      margin-top: 0;
    }
  }
}
