.imageBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;

  .performerPageBanner {
    display: flex;
    width: unset;
    // height: 11.875rem; // MANUAL OVERRIDE
    align-self: stretch;
  }
  
  @media (min-width: 768px) {
    padding: 1rem 1.5rem 1rem 1.5rem;

    // MANUAL OVERRIDE
    // .performerPageBanner {
    //   height: 17.375rem;
    // }
    
  }
  @media (min-width: 1024px) {
    padding: 1.5rem 2rem 1.5rem 2rem;

    // MANUAL OVERRIDE
    // .performerPageBanner {
    //   height: 23.25rem;
    // }
    
  }
  @media (min-width: 1440px) {
    padding: 1.5rem 3rem 1.5rem 3rem;

    // MANUAL OVERRIDE
    // .performerPageBanner {
    //   height: 35rem;
    // }
    
  }
}
