.sportsLandingPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0rem 0rem 0rem;
  background-color: rgb(244, 244, 244);

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .landingPageBannerBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .sportTeamsNearLocationBlock {
    // display: flex; // MANUAL OVERRIDE
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
    padding-left: 1rem; // MANUAL OVERRIDE
  }

  .sportTeamsListBlock {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }

  @media (min-width: 1024px) {
    padding: 1.5rem 0rem 0rem 0rem;

    .container {
      width: 60rem;
      height: unset;
      flex: 1 1 auto;
      align-self: center; // MANUAL OVERRIDE
    }

    .sportTeamsNearLocationBlock {
      margin-top: 1.5rem;
    }

    .sportTeamsListBlock {
      height: unset;
      flex: 1 1 auto;
      margin-top: 1.5rem;
    }

  }
}