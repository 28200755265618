.dropdownDefault {
  // MANUAL OVERRIDE FOR DEFAULT STATE
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .label {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .dropdownSelect {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
    // MANUAL OVERRIDE STARTS
    padding-right: 2rem;
    line-height: 1.25rem;
    // MANUAL OVERRIDE ENDS
  }

}

.dropdownError {
  // MANUAL OVERRIDE FOR ERROR STATE
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  >* {
    margin-top: 0.5rem; // MANUAL OVERRIDE
  }
  // MANUAL OVERRIDE STARTS
  &:nth-child(1) {
    margin-top: 0;
  }
  // MANUAL OVERRIDE ENDS

  .label {
    display: flex;
    width: unset;
    align-self: stretch;
    // MANUAL OVERRIDE STARTS
    &:nth-child(1) {
      margin-top: 0;
    }
    // MANUAL OVERRIDE ENDS
  }

  .dropdownSelect {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
    border: 2px solid #CC2427;
  }

}