// @import '../../../styles/tokens';
.map {
  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
  }
  path {
    stroke-width: 0.125;
    &:nth-of-type(-n + 2) {
      fill: rgb(245, 245, 245);
    }
    &[fill='#000000'],
    &[fill='#999999'] {
      fill: orange;
    }
    &:hover {
      cursor: pointer;
    }
    &:focus {
      cursor: pointer;
    }
    &:active {
      cursor: grabbing;
    }
  }
  text {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    &:not([fill='#ffffff']):not([fill='#cccccc']) {
      fill: black;
    }
  }
}

@media (orientation:landscape) and (max-height: 27rem) {
  .map {
    overflow-y: hidden;
  }
}
