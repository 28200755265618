.stepperItemSelectedIncomplete {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .indicator {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(1, 61, 88); // MANUAL OVERRIDE
    width: unset;
    height: 0.1875rem;
    align-self: stretch;
  }
  
  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    width: unset;
    align-self: stretch;
  }
  
  .stepNumber {
    display: flex;
    width: unset;
    align-self: stretch;
    white-space: nowrap; // MANUAL OVERRIDE
  }
  
  .stepLabel {
    display: flex;
    width: unset;
    align-self: stretch;
    // MANUAL OVERRIDE STARTS
    @media (max-width: 430px) {
      p {
        font-size: 0.75rem;
        line-height: 1.125rem;
      }
    }
    // MANUAL OVERRIDE ENDS
  }
  
  @media (min-width: 1024px) {
    width: unset;

    .indicator {
      background-color: rgb(1, 61, 88);
      height: 0.5rem;
    }
    
  }
}

.stepperItemUnselectedComplete {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .indicator {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(125, 125, 125);
    width: unset;
    height: 0.1875rem;
    align-self: stretch;
  }
  
  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    width: unset;
    align-self: stretch;
  }
  
  .stepNumber {
    display: flex;
    width: unset;
    align-self: stretch;
    white-space: nowrap; // MANUAL OVERRIDE
  }
  
  .stepLabel {
    display: flex;
    width: unset;
    align-self: stretch;
    // MANUAL OVERRIDE STARTS
    @media (max-width: 430px) {
      p {
        font-size: 0.75rem;
        line-height: 1.125rem;
      }
    }
    // MANUAL OVERRIDE ENDS
  }
  
  @media (min-width: 1024px) {
    width: unset;

    .indicator {
      height: 0.5rem;
    }
    
  }
}

.stepperItemUnselectedIncomplete {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .indicator {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(212, 212, 212);
    width: unset;
    height: 0.1875rem;
    align-self: stretch;
  }
  
  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    width: unset;
    align-self: stretch;
  }
  
  .stepNumber {
    display: flex;
    width: unset;
    align-self: stretch;
    white-space: nowrap; // MANUAL OVERRIDE
  }
  
  .stepLabel {
    display: flex;
    width: unset;
    align-self: stretch;
    // MANUAL OVERRIDE STARTS
    @media (max-width: 430px) {
      p {
        font-size: 0.75rem;
        line-height: 1.125rem;
      }
    }
    // MANUAL OVERRIDE ENDS
  }
  
  @media (min-width: 1024px) {
    width: unset;

    .indicator {
      height: 0.5rem;
    }
    
  }
}
