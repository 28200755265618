.topNavCheckout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  background-color: rgb(16, 37, 63);

  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }

  .logo {
    display: block;
    width: 7.625rem; // MANUAL OVERRIDE
    height: 1.25rem;
  }

  .accountMenu {
    display: none;
  }

  .menuButton {
    display: flex;
    margin-left: 1rem;
  }

  @media (min-width: 1024px) {
    justify-content: flex-start;
    align-items: center;

    .logoContainer {
      justify-content: flex-start;
      padding: 1.0625rem 0rem 1.75rem 0rem;
    }

    .logo {
      width: 11.875rem;
      height: 1.9375rem;
    }

    .accountMenu {
      display: flex;
      margin-left: 1.5rem;
    }

    .menuButton {
      display: none;
    }

  }
}

.topNavSearchInNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  // padding: 0rem 1rem 0rem 1rem; // MANUAL OVERRIDE
  background-color: rgb(0, 19, 43);
  height: 3rem;
  align-self: stretch; // MANUAL OVERRIDE

  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    padding-left: 1rem; // MANUAL OVERRIDE
  }

  .logo {
    display: block;
    width: 7.625rem; // MANUAL OVERRIDE
    height: 1.25rem;
  }

  .searchButton {
    display: flex;
    align-self: center; // MANUAL OVERRIDE
  }

  .menuButton {
    display: flex;
    align-self: center; // MANUAL OVERRIDE
    margin: 0rem 1rem 0rem 0.5rem; // MANUAL OVERRIDE
  }

  .accountMenu {
    display: none;
  }

  @media (min-width: 1024px) {
    background-color: rgb(16, 37, 63);
    height: unset;

    .logoContainer {
      justify-content: flex-start;
      padding: 1.0625rem 0rem 0rem 0rem;
      width: unset;
    }

    .logo {
      width: 11.875rem;
      height: 1.9375rem;
    }

    .searchButton {
      margin-left: 1.5rem;
    }

    .menuButton {
      display: none;
    }

    .accountMenu {
      display: flex;
      margin-left: 1.5rem;
    }

  }
}

.topNavSignedOut {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  background-color: rgb(16, 37, 63);

  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0.875rem 0rem 0.875rem 0rem;
    width: unset;
    flex: 1 1 auto;
  }

  .logo {
    display: block;
    width: 7.625rem; // MANUAL OVERRIDE
    height: 1.25rem;
  }

  @media (min-width: 1024px) {
    justify-content: flex-start;
    align-items: center;

    .logoContainer {
      justify-content: flex-start;
      padding: 1.0625rem 0rem 1.75rem 0rem;
      height: unset;
      align-self: stretch;
    }

    .logo {
      width: 11.875rem;
      height: 1.9375rem;
    }

  }
}

.topNavTicketSeatSelection {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 1rem 1rem 0.5rem;
  background-color: rgb(16, 37, 63);

  .logoContainer {
    display: none;
  }

  .backButton {
    display: flex;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }

  .topTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }

  .button {
    display: flex;
    margin-left: 0.25rem;
  }

  .description {
    display: flex;
  }

  .searchField {
    display: none;
  }

  .accountMenu {
    display: none;
  }

  @media (min-width: 1024px) {
    background-color: rgb(0, 19, 43);

    .logoContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.0625rem 0rem 1.75rem 0rem;
      height: unset;
      align-self: stretch;
    }

    .backButton {
      display: none;
    }

    .content {
      margin-left: 1.5rem;
    }

    .title {
      width: unset;
    }

    .searchField {
      display: flex;
      width: 10rem;
      margin-left: 1.5rem;
    }

    .accountMenu {
      display: flex;
      margin-left: 1.5rem;
    }

  }
}

.topNavWithoutSearchInNav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: rgb(0, 19, 43);
  // MANUAL OVERRIDE - removed flex-direction 
  // it was overriding topNav variant flex-direction
  padding: 0; // MANUAL OVERRIDE
  align-self: stretch;
  flex: 0 0 3rem;

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 0.5rem 0rem 0rem; // MANUAL OVERRIDE - to keep logo in same place for both initial header and scrolled header
    width: unset;
    align-self: stretch;
    flex: 1 1 auto; // MANUAL OVERRIDE
  }

  .leftContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 10.1875rem;
    height: 3rem;

    // MANUAL OVERRIDE
    >* {
      margin-left: 1rem;
    }

    // MANUAL OVERRIDE

    flex: 0 0 auto;
  }

  .logo {
    display: block;
    width: 7.625rem; // MANUAL OVERRIDE
    height: 1.25rem;
  }

  .searchField {
    display: none;
  }

  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    >* {
      // MANUAL OVERRIDE
      margin-left: 0.5rem;
    }
  }

  .accountMenu {
    display: flex;
  }

  .divider {
    display: flex;
    width: 0.0625rem;
    height: 2rem;
    margin-left: 0.5rem;
  }

  .menuButton {
    display: flex;
    margin-left: 0.5rem;
  }

  .divider1 {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }

  .bottomContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    width: unset;
    align-self: stretch;
  }

  .searchField {
    display: flex;
    width: unset;
    // height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }

  @media (min-width: 1024px) {
    justify-content: flex-start;

    .topContent {
      padding: 0.5rem 1.5rem 0.5rem 1.5rem;
      height: 4rem; // MANUAL OVERRIDE
    }

    .leftContent {
      width: unset;
      height: unset;
    }

    .logo {
      width: 11.875rem;
      height: 1.9375rem;
    }

    .searchField {
      display: flex;
      width: 15.4375rem;
      margin-left: 1rem;
      flex: 0 0 15.4375rem; // MANUAL OVERRIDE
    }

    .rightContent {
      // justify-content: flex-start; // MANUAL OVERRIDE
      align-items: flex-start;
      width: unset;
      flex: 0 0 auto; // MANUAL OVERRIDE
    }

    .divider {
      display: none;
      width: unset;
      height: unset;
    }

    .menuButton {
      display: none;
    }

    .divider1 {
      display: none;
      width: unset;
      height: unset;
    }

    .bottomContent {
      display: none;
      width: unset;
    }

  }
}