.eventDetailsModal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(229, 229, 229);
  outline-offset: 0;

  .buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .button {
    display: flex;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .bannerImage {
    display: flex;
    width: 6rem;
    height: 6rem;
  }
  
  .rightContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 1rem;
  }
  
  .description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .eventInfo {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .bannerImage {
    display: none;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 2rem;
  }
  
  .title1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .description1 {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .rightContent {
    display: none;
  }
  
  @media (min-width: 1024px) {
    width: 60rem;

    .content {
      flex-direction: row;
    }
    
    .topContent {
      display: none;
      width: unset;
    }
    
    .bannerImage {
      display: flex;
      width: 20rem;
      height: 20rem;
    }
    
    .bottomContent {
      display: none;
      width: unset;
      margin-top: 0;
    }
    
    .rightContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0rem 1.5rem 0rem 0rem;
      width: unset;
      flex: 1 1 auto;
      margin-left: 2rem;
    }
    
  }
}

// MANUAL OVERRIDE STARTS
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modalDialog {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: calc(100vw - 32px);
  margin: 22vh auto auto auto;

  @media (min-width: 1024px) {
    width: 62.375rem;
  }
}
// MANUAL OVERRIDE ENDS