.submenuItemList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 20.25rem; // MANUAL OVERRIDE
  min-width: 13.53125rem; // MANUAL OVERRIDE

  .submenuItem {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1440px) {
    width: 15.3125rem;

    .submenuItem {
      width: 15.3125rem;
    }
  }
  // MANUAL OVERRIDE ENDS
  
}
