.accountMenuBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(62, 75, 89);

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    background-color: rgb(16, 37, 63);
    width: unset;
    height: 5rem;
    align-self: stretch;
  }
  
  .accountMenu {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .navMenuList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

// MANUAL OVERRIDE STARTS
@media (min-width: 1024px) {
  .accountMenuBlock {
    visibility: hidden;
  }
}
// MANUAL OVERRIDE ENDS
