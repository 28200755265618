.submenuList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  padding: 1rem 0rem 1rem 0rem;
  gap: 1.5rem;

  .button {
    display: flex;
  }
  
}
