.ticketQuantitySelection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(244, 244, 244);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.125rem 1rem 1.5rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .dropdown {
    display: flex;
    width: unset;
    align-self: stretch;
    flex-direction: column; // MANUAL OVERRIDE

    // MANUAL OVERRIDE
    select {
      margin-top: 0.5rem;
      padding-right: 3rem;
    }
  }
  
  .button {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1024px) {
    background-color: rgb(255, 255, 255);

    .content {
      padding: 0.625rem 1rem 1rem 1rem;
    }
    
  }
}
