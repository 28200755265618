.containerWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;

  .hideSlideButton {
    display: none;
  }

  .slideBackwardButton {
    position: absolute;
    top: 50%; 
    left: -1.4375rem; 
    transform: translate(0, -50%);
  }
  
  .slideForwardButton {
    position: absolute;
    top: 50%; 
    right: -1.4375rem;
    transform: translate(0, -50%);
  }
}

.snapItemListContainer {
  scroll-snap-type: x mandatory;
  overflow: hidden;
  width: 100%;

  .snapItemContainer {
    scroll-snap-stop: always;
    scroll-snap-align: end;
    display: flex;
  }
}

.titleText {
  p {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
  }
}

.locationAndPriceText {
  display: -webkit-box !important;
  width: 100% !important;
  p {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
  }
}

.performerTitleText {
  flex: 1 1 auto;
  margin: auto;

  p {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
  }
}

.buttonContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  width: 100%;
}

.showMoreButton {
  & p {
    text-decoration: none !important; // to override css from Button component
  }
}

.eventCardsBlockCommon {
  .eventsEmptyState {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
