.navMenuList {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch; // MANUAL OVERRIDE

  .navMenuItem {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  // MANUAL OVERRIDE STARTS
  .tab {
    display: none;
  }
  // MANUAL OVERRIDE ENDS

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;

      .tab {
        display: flex;
        width: unset;
        align-self: stretch;
      }

      .navMenuItem {
        display: none;
      }
    }

  // MANUAL OVERRIDE ENDS

}
