.submenuWithoutTabs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50rem;
    margin-top: 0; // MANUAL OVERRIDE
  }
  
  .submenuItemList {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .divider1 {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
    margin-top: 1rem;
  }
  
}
