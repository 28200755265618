.accountMenuBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    background-color: rgb(0, 19, 43);
    width: unset;
    height: 5rem;
    align-self: stretch;
  }
  
  .accountMenu {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .navMenuList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
  .profileMenuList {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    flex-direction: column; // MANUAL OVERRIDE
  }
  
}
