.container {
  display: none;
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;

  .field {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    padding: 0 1rem 1.625rem 0;

    h1 {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2.25rem;
    }

    h4 {
      font-size: 1rem;
      font-weight: 400;
    }

    &:nth-child(4n) {
      padding-bottom: 0;
    }
  }
}

@media (min-width: 1023px) {
  .mobileContainer {
    display: none;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;

    .fieldGroup {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      .field {
        padding: 0 1rem 0 0;
      }
    }

    .fieldColumn {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      padding: 0 2rem 0 0;
    }

    .field {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      padding: 0 1rem 1.625rem 0;

      &:nth-child(2n) {
        padding-top: 1.5rem;
      }
      
      h1 {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 2.25rem;
      }
  
      h4 {
        font-size: 1rem;
        font-weight: 400;
      }
    }
  }
}