.sportTeamsLandingPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0rem 0rem 0rem;
  background-color: rgb(244, 244, 244);

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 2rem 0rem;
    align-self: stretch;
    flex: 1 1 auto;
  }

  .landingPageBannerBlock {
    display: flex;
    // MANUAL OVERRIDE STARTS
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    // MANUAL OVERRIDE ENDS
  }

  .sportTeamsOpponent {
    display: flex;
    // MANUAL OVERRIDE STARTS
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;

    >* {
      margin-top: 1.5rem;
    }

    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  // MANUAL OVERRIDE ENDS

  .sportTeamsSchedule {
    display: flex;
    // MANUAL OVERRIDE STARTS
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;

    >* {
      margin-top: 1.5rem;
    }

    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}

@media (min-width: 1024px) {
  .sportTeamsLandingPage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0rem;
    background-color: rgb(244, 244, 244);

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      >* {
        margin-top: 1.5rem;
      }

      align-self: center;
      width: 58rem;
      flex: 0 0 75.5625rem;
    }

    .landingPageBannerBlock {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .sportTeamsOpponent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      >* {
        margin-top: 1.5rem;
      }

      align-self: stretch;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .sportTeamsSchedule {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      >* {
        margin-top: 1.5rem;
        margin-bottom: 0rem;
      }

      align-self: stretch;
      flex: 0 0 auto;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    // MANUAL OVERRIDE ENDS
  }
}
