.navMenuItemBack {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(16, 37, 63);

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
    height: 0.0625rem; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.6875rem 1rem 0.75rem 0.5rem;
    width: unset;
    align-self: stretch;
  }
  
  .backButton {
    display: flex;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.navMenuItemDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(62, 75, 89);

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
    height: 0.0625rem; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.6875rem 1rem 0.75rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .divider1 {
    display: flex;
    width: unset;
    align-self: stretch;
    height: 0.0625rem; // MANUAL OVERRIDE
  }
  
}

.navMenuItemNext {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(62, 75, 89);

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
    height: 0.0625rem; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.6875rem 1rem 0.75rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .nextButton {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .divider1 {
    display: flex;
    width: unset;
    align-self: stretch;
    height: 0.0625rem; // MANUAL OVERRIDE
  }
  
}
