.primaryBannerBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .primaryBanner {
    display: flex;
    width: unset;
    height: 17.375rem; // MANUAL OVERRIDE
    align-self: stretch;
    padding: 0rem 1rem 1rem 1rem; // MANUAL OVERRIDE
    margin-top: 0; // MANUAL OVERRIDE
  }
  
  @media (min-width: 768px) {

    .primaryBanner {
      height: 25rem;
    }
    
  }
  @media (min-width: 1024px) {
    background-color: rgb(244, 244, 244);

  }
  @media (min-width: 1440px) {

    .primaryBanner {
      height: 37.5rem;
    }
    
  }
}
