.recentlyViewedBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 0rem 1.5rem 0rem;

  // MANUAL OVERRIDE - not hiding title in mobile breakpoint
  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.25rem;
  }
  
  .recentlyViewedList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  @media (min-width: 1024px) {
    padding: 3rem 0rem 3rem 0rem; // MANUAL OVERRIDE
    background-color: rgb(244, 244, 244);

    .title {
      display: flex;
      width: unset;
      align-self: stretch;
    }

    // MANUAL OVERRIDE STARTS
    .icon {
      display: none;
    }
    // MANUAL OVERRIDE ENDS
    
    // MANUAL OVERRIDE - removed style hiding topContent in desktop breakpoint
    
    .recentlyViewedList {
      margin-top: 1.5rem;
    }
    
  }
}
