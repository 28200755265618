.primaryBannerBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .primaryBanner {
    display: flex;
    width: unset;
    height: 17.5rem;
    align-self: stretch;
  }
  
  @media (min-width: 1024px) {
    background-color: rgb(244, 244, 244);

    .primaryBanner {
      height: 22.5rem;
    }
    
  }
}
