.exclusivesEventsLandingPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(244, 244, 244);

  .topContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .primaryBannerBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .topBanner {
    display: none;
  }
  

  // heroCTABlock is hidden in legacy version for all breakpoints
  .heroCTABlock {
    display: none; // MANUAL OVERRIDE
    width: unset;
    align-self: stretch;
  }
  
  .container {
    display: flex;
    flex-direction: column; // MANUAL OVERRIDE
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    width: unset;
    align-self: stretch;
    // MANUAL OVERRIDE - removed margin-top
  }
  
  .eventListingBlock {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    padding: 1rem 0; // MANUAL OVERRIDE
    align-self: stretch; // MANUAL OVERRIDE
  }
  
  .secondaryBannerBlock {
    // MANUAL OVERRIDE STARTS - showing secondaryBannerBlock in mobile breakpoint
    display: flex;
    width: unset;
    align-self: stretch;
    // MANUAL OVERRIDE ENDS
  }
  
  @media (min-width: 1024px) {

    .primaryBannerBlock {
      display: none;
      width: unset;
    }
    
    .topBanner {
      display: flex;
      width: unset;
      align-self: stretch;
    }
    
    .container {
      flex-direction: column;
      width: 60rem;
      height: unset;
      flex: 1 1 auto;
      margin-top: 1.5rem;
      align-self: center; // MANUAL OVERRIDE
    }
    
    .eventListingBlock {
      align-self: stretch;
      padding: 1.5rem 0; // MANUAL OVERRIDE
    }
    
    .secondaryBannerBlock {
      display: flex;
      width: unset;
      align-self: stretch;
      // MANUAL OVERRIDE - removed margin-top
      padding: 0rem 0rem 3rem 0rem; // MANUAL OVERRIDE
    }
    
  }
}
