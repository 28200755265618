.hideErrorButton {
  display: none;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.errorIcon {
  background-size: contain;
  background-position: center;
}

.notAccessibleErrorBlockTitle {
  display: flex;
}

.errorBlock {
  height: unset;
  flex: 1 1 auto;
}
@media (min-width: 1024px) {
  .navContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    background-color: rgb(16, 37, 63);
  }

  .topNav {
    padding-left: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    width: 64rem;
    flex: 0 0 auto;
  }

  .notAccessibleErrorBlockTitle {
    width: 21.063rem;
    flex: 0 0 auto;
  }
}