.secondaryBanner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 12px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 18.375rem; // MANUAL OVERRIDE

  .messageContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin: auto; // MANUAL OVERRIDE
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: center; // MANUAL OVERRIDE
  }
  
  .message {
    display: flex;
    width: unset;
    align-self: stretch;
    margin: auto; // MANUAL OVERRIDE
    // MANUAL OVERRIDE - removed margin-top
    max-width: 33rem; // MANUAL OVERRIDE
  }
  
  .button {
    display: flex;
    margin-top: 0.75rem;
  }
  
  @media (min-width: 768px) {
    justify-content: center;
    align-items: center;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    height: 14.25rem; // MANUAL OVERRIDE

    // MANUAL OVERRIDE STARTS
    .message {
      margin-top: 0.75rem;
    }
    // MANUAL OVERRIDE ENDS

  }
  @media (min-width: 1024px) {
    padding: 2rem 2rem 2rem 2rem;

    .message {
      margin-top: 1rem;
    }
    
    .button {
      margin-top: 1rem;
    }
    
  }
}
