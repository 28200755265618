.performerCardDark {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  background-color: rgb(228, 229, 229);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.10000000149011612);
  width: 16rem; // MANUAL OVERRIDE
  flex: 0 0 auto; // MANUAL OVERRIDE

  .topContent {
    display: flex; // MANUAL OVERRIDE
    width: unset;
    align-self: stretch;
    overflow: hidden; // MANUAL OVERRIDE
  }
  
  .image {
    display: flex;
    height: 10.625rem;
    transition: transform 0.3s ease; // MANUAL OVERRIDE
    flex: 1 1 auto; // MANUAL OVERRIDE
    align-self: stretch; // MANUAL OVERRIDE
  }
  
  .bottomContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    flex: 1 1 auto;
    // MANUAL OVERRIDE
    > * {
      margin-left: 0.25rem;
    }
  }
  
  .titleText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: flex; // MANUAL OVERRIDE
    width: 2rem;
    height: 2rem;
    margin-left: 0.25rem;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

  // MANUAL OVERRIDE STARTS
  &:hover {
    .image {
      transform: scale(1.2);
    }
  }
  // MANUAL OVERRIDE ENDS
  
  &:focus-visible {
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  
  }

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    width: 19rem;
  }
  @media (min-width: 1440px) {
    width: 19.5rem;
  }
  // MANUAL OVERRIDE ENDS
}

.performerCardLight {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.10000000149011612);
  width: 16rem; // MANUAL OVERRIDE
  flex: 0 0 auto; // MANUAL OVERRIDE

  .topContent {
    display: flex; // MANUAL OVERRIDE
    width: unset;
    align-self: stretch;
    overflow: hidden; // MANUAL OVERRIDE
  }
  
  .image {
    display: flex;
    height: 10.625rem;
    transition: transform 0.3s ease; // MANUAL OVERRIDE
    flex: 1 1 auto; // MANUAL OVERRIDE
    align-self: stretch; // MANUAL OVERRIDE
  }
  
  .bottomContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    flex: 1 1 auto;
    // MANUAL OVERRIDE
    > * {
      margin-left: 0.25rem;
    }
  }
  
  .titleText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: flex; // MANUAL OVERRIDE
    width: 2rem;
    height: 2rem;
    margin-left: 0.25rem;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  
  }
  &:hover {
    .bottomContent {
      display: none;
      width: unset;
    }


    // MANUAL OVERRIDE STARTS
    .image {
      transform: scale(1.2);
    }
    // MANUAL OVERRIDE ENDS
    
  }

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    width: 19rem;
  }
  @media (min-width: 1440px) {
    width: 19.5rem;
  }
  // MANUAL OVERRIDE ENDS
}

.performerCardDarkDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  background-color: rgb(228, 229, 229);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.10000000149011612);
  width: 16rem; // MANUAL OVERRIDE
  flex: 0 0 auto; // MANUAL OVERRIDE

  .topContent {
    display: flex; // MANUAL OVERRIDE
    width: unset;
    align-self: stretch;
  }
  
  .image {
    display: flex;
    height: 10.625rem;
    flex: 1 1 auto; // MANUAL OVERRIDE
    transition: transform 0.3s ease; // MANUAL OVERRIDE
    align-self: stretch; // MANUAL OVERRIDE
  }
  
  .bottomContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    flex: 1 1 auto;
    // MANUAL OVERRIDE
    > * {
      margin-left: 0.25rem;
    }
  }
  
  .titleText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: flex; // MANUAL OVERRIDE
    width: 2rem;
    height: 2rem;
    margin-left: 0.25rem;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  
  }

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    width: 19rem; // MANUAL OVERRIDE
  }
  @media (min-width: 1440px) {
    width: 19.5rem; // MANUAL OVERRIDE
  }
  // MANUAL OVERRIDE ENDS
}

.performerCardLightDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.10000000149011612);
  width: 16rem; // MANUAL OVERRIDE
  flex: 0 0 auto; // MANUAL OVERRIDE

  .topContent {
    display: flex; // MANUAL OVERRIDE
    width: unset;
    align-self: stretch;
    overflow: hidden; // MANUAL OVERRIDE
  }
  
  .image {
    display: flex;
    flex: 1 1 auto; // MANUAL OVERRIDE
    transition: transform 0.3s ease; // MANUAL OVERRIDE
    align-self: stretch; // MANUAL OVERRIDE
    height: 10.625rem;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    flex: 1 1 auto;
    // MANUAL OVERRIDE
    > * {
      margin-left: 0.25rem;
    }
  }
  
  .titleText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: flex; // MANUAL OVERRIDE
    width: 2rem;
    height: 2rem;
    margin-left: 0.25rem;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  
  }
  
  // MANUAL OVERRIDE STARTS
  &:hover {
    .image {
      transform: scale(1.2);
    }
  }

  @media (min-width: 1024px) {
    width: 19rem;
  }
  @media (min-width: 1440px) {
    width: 19.5rem;
  }
  // MANUAL OVERRIDE ENDS
}
