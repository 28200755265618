.dropdownDefault {
  // MANUAL OVERRIDE FOR DEFAULT STATE
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .label {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .dropdownSelect {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
    line-height: 1.25rem; // MANUAL OVERRIDE
    padding-right: 2rem; // MANUAL OVERRIDE
  }

}

.dropdownError {
  // MANUAL OVERRIDE FOR ERROR STATE
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .label {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .dropdownSelect {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
    border: 2px solid #CC2427;
    line-height: 1.25rem; // MANUAL OVERRIDE
    padding-right: 2rem; // MANUAL OVERRIDE
  }

}