.homepage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .topContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 1rem 0rem;
    background-color: rgb(0, 19, 43);
    width: unset;
    align-self: stretch;
  }
  
  .primaryBannerBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .exclusiveEvents {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .mLBBanner {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .filtersBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .highlightedEvents {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .musicEvents {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .sportsEvents {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .comedyTheaterEvents {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .secondaryBannerLightBlock {
    display: flex;
    width: unset;
    align-self: stretch;
    padding: 2rem 1rem 1rem 1rem; // MANUAL OVERRIDE
  }
  
  @media (min-width: 768px) {

    .filtersBlock {
      width: 45rem; // MANUAL OVERRIDE
    }

    // MANUAL OVERRIDE STARTS
    .secondaryBannerLightBlock {
      padding: 3rem 2rem 2rem 2rem;
    }
    // MANUAL OVERRIDE ENDS
    
  }
  @media (min-width: 1024px) {

    .exclusiveEvents {
      width: 64rem;
      align-self: center; // MANUAL OVERRIDE
    }
    
    .container {
      width: 64rem;
      align-self: center; // MANUAL OVERRIDE
    }
    
    .filtersBlock {
      width: 60rem; // MANUAL OVERRIDE
    }

    // MANUAL OVERRIDE STARTS
    .secondaryBannerLightBlock {
      padding: 2rem 2rem 2rem 2rem;
    }
    // MANUAL OVERRIDE ENDS
    
  }
  @media (min-width: 1440px) {

    .topContainer {
      padding: 0rem 0rem 1.5rem 0rem;
    }
    
    .exclusiveEvents {
      width: 90rem;
    }
    
    .container {
      width: 90rem;
    }
    
    .filtersBlock {
      width: unset;
      align-self: stretch;
    }

    // MANUAL OVERRIDE STARTS
    .secondaryBannerLightBlock {
      padding: 2rem 3rem 2rem 3rem;
    }
    // MANUAL OVERRIDE ENDS
    
  }
  @media (min-width: 1920px) {

    .topContainer {
      align-items: center;
      padding: 0rem 0rem 2rem 0rem;
    }
    
    .container {
      padding: 1.5rem 0rem 0rem 0rem;
    }
    
    .secondaryBannerLightBlock {
      // MANUAL OVERRIDE - removed static height
      padding: 2rem 3rem 2rem 3rem; // MANUAL OVERRIDE
    }
    
  }
}
