.ticketAvailabilityCardSoldOut {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  white-space: nowrap; // MANUAL OVERRIDE

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    align-self: stretch;
    // MANUAL OVERRIDE STARTS
    flex: 0 0 auto;
    @media (min-width: 1024px) {
      height: 10.125rem;
      width: 22rem;
    }
    // MANUAL OVERRIDE ENDS
  }

  .tag {
    display: flex;
    // MANUAL OVERRIDE STARTS
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.1875rem 0.5rem 0.1875rem 0.5rem;
    align-self: center;
    // MANUAL OVERRIDE ENDS
  }

  .mainMessage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    margin-top: 0.5rem; // MANUAL OVERRIDE
    align-self: stretch;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0.5rem; // MANUAL OVERRIDE
  }

  .message {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }

    // MANUAL OVERRIDE STARTS
    .button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      margin-top: 1rem;
      p {
        text-decoration: none;
      }
    }
    // MANUAL OVERRIDE ENDS

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
  }  
}
