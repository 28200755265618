.imageList {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    height: 22.6875rem;
    flex: 0 0 16rem;
    max-width: calc(100% - 0rem);
    @media (max-width: 767px) {
      &:nth-child(-n+1) {
        margin-top: 0;
      }
      &:nth-child(1n+1) {
        margin-left: 0;
      }
    }
  }

}

@media (min-width: 768px) {
  .imageList {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      height: 22.6875rem;
      flex: 0 0 16rem;
      max-width: calc(50% - 0.75rem);
      @media (max-width: 1023px) {
        &:nth-child(-n+2) {
          margin-top: 0;
        }
        &:nth-child(2n+1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1024px) {
  .imageList {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      max-width: calc(33.333333333333336% - 1rem);
      @media (max-width: 1439px) {
        &:nth-child(-n+3) {
          margin-top: 0;
        }
        &:nth-child(3n+1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 1440px) {
  .imageList {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      max-width: calc(25% - 1.5rem);
      &:nth-child(-n+4) {
        margin-top: 0;
      }
      &:nth-child(4n+1) {
        margin-left: 0;
      }
    }
  
  }
}