.modalDialog {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;

  height: 100%;
  padding: 0rem;
  @media (min-width: 1024px) {
    padding: 4.5rem 8rem 4.5rem 8rem;
  }
  @media (min-width: 1440px) {
    padding: 4.5rem 21rem 4.5rem 21rem;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  height: 100%;
}

.modalBody {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  height: calc(100vh - 4.375rem); // total height - modalHeaderHeight
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  overflow-y: auto;
}

.modalHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
}

.modalFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  padding: 1rem 1rem 1rem 1rem;
}

.searchReturn {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  padding: 0 !important; // To override inline style applied on modal container
}

@media (min-width: 1024px) {
  .searchReturn {
    visibility: hidden;
  }
}