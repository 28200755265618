.ticketQuantity {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0.5rem 1rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .label {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .quantity {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  // MANUAL OVERRIDE
  .minusButton {
    display: flex;
    width: 2rem;
    height: 2rem;
  }
  
  .label1 {
    display: flex;
    width: 3.5rem;

    // MANUAL OVERRIDE
    & p {
      width: 100%;
      text-align: center;
    }
  }
  
  // MANUAL OVERRIDE
  .plusButton {
    display: flex;
    width: 2rem;
    height: 2rem;
  }
  
  .divider1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    .divider1 {
      display: none;
    }
  }
  // MANUAL OVERRIDE ENDS
  
}
