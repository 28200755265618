.ticketSelectionInformation {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 0rem 1rem;
    background-color: rgb(228, 229, 229);
    width: unset;
    align-self: stretch;
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .priceDetails {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  // MANUAL OVERRIDE
  .location {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .pointsCost {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .pointsAmount {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    width: unset;
    align-self: stretch;
  }
  
  .points {
    display: flex;
  }
  
  .text1 {
    display: flex;
    margin-left: 0.25rem;
  }
  
  .value {
    display: flex;
    margin-left: 0.25rem;
  }
  
  .text2 {
    display: flex;
    margin-left: 0.25rem;
  }
  
  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
}
