.submenuWithTabs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset; // MANUAL OVERRIDE
  }
  
  .leftContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0rem 0rem; // MANUAL OVERRIDE
    // width: 12.5rem; // MANUAL OVERRIDE
    flex: 0 0 auto;// MANUAL OVERRIDE
  }
  
  .sportsMenuTabList {
    display: flex;
    // width: unset;  // MANUAL OVERRIDE
    flex: 1 1 auto;
  }
  
  .divider1 {
    display: flex;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }
  
  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0rem 1rem; // MANUAL OVERRIDE
    width: unset;
    flex: 1 1 auto;
  }
  
  // MANUAL OVERRIDE - using only one submenuItemList
  .submenuItemList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
  }
  
  .divider2 {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
}
