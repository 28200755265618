.mlbCardLight {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.10000000149011612);
  width: 16rem;
  height: 23.25rem;
  // MANUAL OVERRIDE STARTS
  flex: 0 0 auto;
  align-self: stretch;
  background-image: linear-gradient(0deg, rgba(0, 19, 43, 0.4), rgba(0, 19, 43, 0.4)), url('../../../resources/images/MLBCard.png');
  // MANUAL OVERRIDE ENDS

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1.25rem 1rem 1.25rem;
    width: unset;
    align-self: stretch;
  }
  
  .titleText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .bodyText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .actionContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 1.25rem 1rem 1.25rem;
    width: unset;
    align-self: stretch;
  }
  
  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
  .textIcon {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .actionText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 2rem;
    height: 2rem;
    margin-left: 0.25rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(255, 255, 255);
    outline-offset: 0;
  }
  @media (min-width: 768px) {
    width: 19.5rem; // MANUAL OVERRIDE

    // MANUAL OVERRIDE STARTS
    .actionText {
      margin-left: 0.25rem;
    }
    // MANUAL OVERRIDE ENDS
  }
}
