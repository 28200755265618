.paymentInformationReadOnlyBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1.5rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0; // MANUAL OVERRIDE
  }
  
  .blockTitle {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .edit {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .leftContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .address {
    display: flex;
    width: unset;
    align-self: stretch;
    white-space: pre-line; // MANUAL OVERRIDE - to use /n to break lines
  }
  
  .phoneNumber {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .rightContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .paymentMethod {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .rewards {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  @media (min-width: 1024px) {
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;

    // MANUAL OVERRIDE - removed static height for .topContent
    
    .bottomContent {
      flex-direction: row;

      // MANUAL OVERRIDE STARTS - added margin-top for both .leftContent and .rightContent
      > * {
        margin-top: 1rem;
      }
      // MANUAL OVERRIDE ENDS
    }
    
    // MANUAL OVERRIDE - legacy version for this section's children were growing to a certain length
    // v2 exporter components for the children were not growing.
    // So, added flex-basis which can grow as its parent.
    .leftContent {
      flex: 0 0 44.20%;
    }
    
    .rightContent {
      flex: 1 1 auto;
      // MANUAL OVERRIDE - removed margin-top
      margin-left: 1rem;
    }
    
    .paymentMethod {
      height: 2.6875rem;
    }
    
  }
}
