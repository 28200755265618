.exclusiveEventBanner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: 11rem;

  .tag {
    display: flex;
  }
  
}
