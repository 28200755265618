.submenuItemList {
  max-height: 22.25rem;
  display: flex;
  flex-wrap: wrap;
  min-width: 13.53125rem;
  column-gap: 0.25rem;
  > * {
    align-self: stretch;
    flex: 0 0 auto !important;
  }
}

@media (min-width: 1440px) {
  .submenuItemList {
    max-height: 20.25rem;
    display: flex;
    flex-wrap: wrap;
    min-width: 15.3125rem;
    > * {
      align-self: stretch;
      flex: 0 0 auto !important;
      width: 15.3125rem;
    }
  }
}