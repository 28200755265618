.performerPageBanner {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 12px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  // background: linear-gradient(1.5708rad, rgba(0, 19, 43, 0) 0%, rgba(0, 19, 43, 1) 100%); // MANUAL OVERRIDE
  height: 11.875rem;

  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;

    // MANUAL OVERRIDE STARTS
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    // MANUAL OVERRIDE ENDS
  }
  
  @media (min-width: 768px) {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    height: 17.375rem;

  }
  @media (min-width: 1024px) {
    padding: 2rem 2rem 2rem 2rem;
    height: 23.25rem;

  }
  @media (min-width: 1440px) {
    padding: 3rem 3rem 3rem 3rem;
    height: 32.6875rem;
  }
}
