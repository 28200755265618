.eventListingBlockEmpty {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(244, 244, 244);

  .title {
    display: none; // MANUAL OVERRIDE
  }

  .titleMobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .filterGroup {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .emptyStateBanner {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }

  .divider {
    display: none;
  }

  @media (min-width: 1024px) {

    // MANUAL OVERRIDE - removed styles that hid title in desktop breakpoint
    .title {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: unset;
      align-self: stretch;
      margin-top: 1.5rem; // MANUAL OVERRIDE

      // MANUAL OVERRIDE STRATS
      h2 {
        font-size: 1.25rem;
        line-height: 2.25rem;
      }

      // MANUAL OVERRIDE ENDS
    }

    .titleMobile {
      display: none;
    }

    .filterGroup {
      margin-top: 0;
    }

    .divider {
      display: flex;
      width: unset;
      align-self: stretch;
      margin-top: 1.5rem;
    }

  }
}

.eventListingBlockFilled {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(244, 244, 244);

  .title {
    display: none;
  }

  .titleMobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .filterGroup {
    display: flex;
    width: unset;
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

  .eventsCardList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }

  .showMoreButton {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;

    // MANUAL OVERRIDE STARTS
    & p {
      text-decoration: underline;
      text-decoration-color: rgb(2, 118, 177);
    }

    // MANUAL OVERRIDE ENDS
  }

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0; // MANUAL OVERRIDE
  }

  @media (min-width: 1024px) {

    // MANUAL OVERRIDE - removed styles that hid title in desktop breakpoint
    .title {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: unset;
      align-self: stretch;
      margin-top: 1.5rem; // MANUAL OVERRIDE

      // MANUAL OVERRIDE STRATS
      h2 {
        font-size: 1.25rem;
        line-height: 2.25rem;
      }

      // MANUAL OVERRIDE ENDS
    }

    .titleMobile {
      display: none;
    }

    .filterGroup {
      // margin-top: 2rem; // MANUAL OVERRIDE
    }

    // MANUAL OVERRIDE STARTS
    .divider {
      margin-top: 1.5rem;
    }

    // MANUAL OVERRIDE ENDS

  }
}