// MANUAL OVERRIDE
.generalPrecheckout {
  align-self: stretch;
  
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 8px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  
    .cardNavigation {
      display: flex;
      width: unset;
      align-self: stretch;
    }
    
    .ticketSelectionInformation {
      display: flex;
      width: unset;
      align-self: stretch;

      // MANUAL OVERRIDE STARTS
      .content {
        gap: 1rem;
      }
      // MANUAL OVERRIDE ENDS
    }
    
    .ticketQuantitySelection {
      display: flex;
      width: unset;
      align-self: stretch;
    }
    
    .additionalTicketInformationList {
      display: flex;
      width: unset;
      align-self: stretch;
    }
    
    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      height: 20rem;
      width: 100%;
    }
  }

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1440px) {
    width: 26.6875rem;
  }
  // MANUAL OVERRIDE ENDS
}