.eventStatusTagJustAnnounced {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  .label {
    display: flex;
  }
  
}

.eventStatusTagLowTickets {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  .label {
    display: flex;
  }
  
}

.eventStatusTagSoldOut {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  .label {
    display: flex;
  }
  
}
