.footerMenuList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: flex-start;
  // MANUAL OVERRIDE STARTS
  > * {
    margin-left: 1rem;
  }
  // MANUAL OVERRIDE ENDS

  .button {
    display: flex;
    padding: 0; // MANUAL OVERRIDE

    // MANUAL OVERRIDE STARTS
    &:nth-child(9n+1) {
      margin-left: 0;
    }
    p {
      text-decoration: underline;
    }
    // MANUAL OVERRIDE ENDS
  }

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1024px) {
    > * {
      margin-left: 1.5rem;
    }
  }
  // MANUAL OVERRIDE ENDS
  
}
