.toggleItemDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem 1.25rem 0.3125rem 1.25rem;
  border-radius: 100px;
  background-color: rgb(244, 244, 244);

  .label {
    display: flex;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
  
  }
}

.toggleItemSelected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.3125rem 1.25rem 0.3125rem 1.25rem;
  border-radius: 100px;
  background-color: rgb(2, 118, 177);

  .label {
    display: flex;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(255, 255, 255);
    outline-offset: 0;
  
  }
}
