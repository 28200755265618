.submenuWithoutTabs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(16, 37, 63);

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
    height: 0.0625rem; // MANUAL OVERRIDE
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50rem;
    margin-top: 1rem;
  }
  
  .submenuItemList {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    // MANUAL OVERRIDE STARTS
    flex-direction: column;
    flex-wrap: wrap;
    height: 18rem;
    justify-content: flex-start;
    align-items: stretch;
    align-self: flex-start;
    // MANUAL OVERRIDE ENDS
  }
  
  .divider1 {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
}
