.cardNavigationDefaultLightBackground {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .button {
    display: flex;
    width: unset;
    align-self: flex-start; // MANUAL OVERRIDE
  }
  
  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.cardNavigationWithCloseOptionDarkBackground {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(16, 37, 63);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.5rem 0.75rem 0.5rem;
    width: unset;
    align-self: stretch;
  }
  
  .backButton {
    display: flex;
  }
  
  .title {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .closeButton {
    display: flex;
    margin-left: 0.5rem;
  }
  
}

.cardNavigationWithCloseOptionLightBackground {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    width: unset;
    align-self: stretch;
  }
  
  .button {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    justify-content: flex-start; // MANUAL OVERRIDE
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }

  // MANUAL OVERRIDE STARTS
  .closeButton {
    display: flex;
    margin-left: 0.5rem;
  }
  // MANUAL OVERRIDE ENDS

  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
