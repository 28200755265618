.ticketInformationDefaultDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
  }
  
  .description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .location {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .availability {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .cost {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    height: unset;
    align-self: stretch;
  }
  
  .cost1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .value {
    display: flex;
  }
  
  .valueType {
    display: flex;
    margin-left: 0.25rem;
  }
  
  .other {
    display: flex;
  }
  
  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
}

.ticketInformationDefaultRounded {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  width: 20rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    background-color: rgb(228, 229, 229);
    width: unset;
    align-self: stretch;
  }
  
  .description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .location {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .availability {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .cost {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    height: unset;
    align-self: stretch;
  }
  
  .miles {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .value {
    display: flex;
  }
  
  .valueType {
    display: flex;
    margin-left: 0.25rem;
  }
  
  .dollar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .value1 {
    display: flex;
  }
  
  .valueType1 {
    display: flex;
    margin-left: 0.25rem;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
}

.ticketInformationLargeTitleDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
  }
  
  .description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .frame1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .location {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .cost {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .cost1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .value {
    display: flex;
  }
  
  .valueType {
    display: flex;
    margin-left: 0.25rem;
  }
  
  .other {
    display: flex;
  }
  
  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
}

.ticketInformationMoreDetailsDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
  }
  
  .description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .location {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .availability {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .cost {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .cost1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .value {
    display: flex;
  }
  
  .valueType {
    display: flex;
    margin-left: 0.25rem;
  }
  
  .pointsValue {
    display: flex;
  }
  
  .other {
    display: flex;
  }
  
  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
}

.ticketInformationWithPinDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 20rem;

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0.5rem 1rem 1rem;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
  }
  
  .description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }
  
  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .availability {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .cost {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 3.625rem;
  }
  
  .cost1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .value {
    display: flex;
  }
  
  .valueType {
    display: flex;
    margin-left: 0.25rem;
  }
  
  .other {
    display: flex;
  }
  
  .button {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
}
