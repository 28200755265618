.sportTeamsLandingPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem 0rem 0rem 0rem;
  background-color: rgb(255, 255, 255);

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 2rem 0rem;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  .imageBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  .toggleBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .filtersBlock {
    display: flex;
    width: auto;  // MANUAL OVERRIDE

    >* {
      margin-top: 0.25rem; // MANUAL OVERRIDE
    }

    align-self: stretch; // MANUAL OVERRIDE
    width: auto; // MANUAL OVERRIDE

  }

  .eventCardsBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  @media (min-width: 768px) {

    .imageBlock {  // MANUAL OVERRIDE
      .imageBlockText {
        margin-top: 2rem;
      }
    }

    .container {
      height: unset;
    }

    .filtersBlock {
      width: unset;
      align-self: stretch;
    }

    .eventCardsBlock {
      width: auto; // MANUAL OVERRIDE
      align-self: stretch; // MANUAL OVERRIDE
      width: auto; // MANUAL OVERRIDE
      flex: 0 0 auto; // MANUAL OVERRIDE
      padding: 0rem 1.5rem 0rem 1.5rem; // MANUAL OVERRIDE
      margin-top: 1rem; // MANUAL OVERRIDE
    }

  }

  @media (min-width: 1024px) {

    .imageBlock {  // MANUAL OVERRIDE
      .imageBlockText {
        margin-top: 2rem;
      }
    }

    .toggleBlock {
      margin-top: 0.5rem; // MANUAL OVERRIDE

    }

    .filtersBlock {
      width: 64rem;

      >* {
        margin-top: 0.5rem; // MANUAL OVERRIDE
      }
    }

    .eventCardsBlock {
      width: auto; // MANUAL OVERRIDE
      align-self: stretch; // MANUAL OVERRIDE
      width: auto; // MANUAL OVERRIDE
      flex: 0 0 auto; // MANUAL OVERRIDE
      padding: 0rem 2rem 0rem 2rem; // MANUAL OVERRIDE
      margin-top: 0.75rem; // MANUAL OVERRIDE
    }

  }

  @media (min-width: 1440px) {

    .container {
      align-self: center;  // MANUAL OVERRIDE
      width: 90rem;
    }

    .imageBlock {  // MANUAL OVERRIDE
      .imageBlockText {
        margin-top: 2rem;
      }
    }

    .toggleBlock {
      margin-top: 0.5rem; // MANUAL OVERRIDE

    }

    .filtersBlock {
      width: unset;
      align-self: stretch;

      >* {
        margin-top: 1rem; // MANUAL OVERRIDE
      }
    }

    .eventCardsBlock {
      width: 90rem;
      margin-top: 1rem; // MANUAL OVERRIDE
      padding: 0 3rem;  // MANUAL OVERRIDE
    }

  }
}
