.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.modalDialog {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: calc(100vw - 32px);
  margin: 30vh auto auto auto;

  @media (min-width: 1024px) {
    width: 30rem;
  }
}

.alertModal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  > * {
    margin-top: 1.5rem;
  }
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  @media (max-width: 1023px) and (min-width: 576px) {
    width: 30rem;
  }

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .closeButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    align-self: flex-end;
    flex: 0 0 auto;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
  }

  .icon {
    overflow: hidden;
    align-self: flex-start;
    height: 2.625rem;
    flex: 0 0 2.625rem;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    > * {
      margin-top: 3rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .message {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .primaryButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5625rem 1rem 0.5625rem 1rem;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }
}

@media (min-width: 1024px) {
  .alertModal {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    > * {
      margin-top: 1.5rem;
    }
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    width: 30rem;

    .topContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .closeButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      align-self: flex-end;
      flex: 0 0 auto;
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .icon {
      overflow: hidden;
      align-self: flex-start;
      height: 2.625rem;
      flex: 0 0 2.625rem;
      &:nth-child(1) {
        margin-left: 0;
      }
    }

    .title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }

    .bottomContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      > * {
        margin-top: 3rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .message {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .buttonPair {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      > * {
        margin-left: 1.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .primaryButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5625rem 1rem 0.5625rem 1rem;
      align-self: center;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }
}
