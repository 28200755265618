.eventCardsBlockCardSpecificEventsDarkBlockDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  align-self: stretch; // MANUAL OVERRIDE
  flex: 0 0 auto; // MANUAL OVERRIDE
  background-color: rgb(0, 19, 43);

  .blockHeader {
    // MANUAL OVERRIDE STARTS
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0rem;
    align-self: stretch;
    flex: 0 0 auto;
    // MANUAL OVERRIDE ENDS
  }

  .container {
    display: flex;
    overflow: auto; // MANUAL OVERRIDE
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .cardSpecificEventsList {
    // MANUAL OVERRIDE STARTS
    overflow: unset;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0rem 1rem 0rem;
    gap: 1rem;
    align-self: flex-start;
    flex: 1 1 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }

    // MANUAL OVERRIDE ENDS
  }

  .sliderInfo {
    display: none;
  }

}
// MANUAL OVERRIDE STARTS
.eventCardsBlockCardSpecificEventsDarkBlockLoading {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  align-self: stretch;
  flex: 0 0 auto;
  background-color: rgb(0, 19, 43);

  .blockHeader {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0rem;

    >* {
      margin-left: 0.5rem;
    }

    align-self: stretch;
    flex: 0 0 auto;
  }

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    >* {
      margin-left: 0.625rem;
    }

    align-self: stretch;
    flex: 0 0 34.4375rem;
  }

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    height: 2.5rem;
    flex: 0 0 2.5rem;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}

.eventCardsBlockEventsLightBlockDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  align-self: stretch;
  flex: 0 0 auto;

  .blockHeader {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .container {
    overflow: auto; // MANUAL OVERRIDE
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .eventCardLightList {
    overflow: unset; // MANUAL OVERRIDE
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; // MANUAL OVERRIDE
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0rem 1rem 0rem;
    min-width: 100%; // MANUAL OVERRIDE
    gap: 1rem; // MANUAL OVERRIDE
    align-self: flex-start;
    flex: 1 1 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}

.eventCardsBlockEventsLightBlockEmpty {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  align-self: stretch;
  flex: 0 0 auto;

  .blockHeader {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0rem 1rem 0rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .eventsEmptyState {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}

.eventCardsBlockEventsLightBlockLoading {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  align-self: stretch;
  flex: 0 0 auto;

  .blockHeader {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0rem;

    >* {
      margin-left: 0.5rem;
    }

    align-self: stretch;
    flex: 0 0 auto;
  }

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;

    >* {
      margin-left: 0.625rem;
    }

    align-self: stretch;
    flex: 0 0 22.6875rem;
  }

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    height: 2.5rem;
    flex: 0 0 2.5rem;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}

.eventCardsBlockEventsDarkBlockDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  align-self: stretch;
  flex: 0 0 auto;
  background-color: rgb(0, 19, 43);

  .blockHeader {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .container {
    display: flex;
    overflow: auto;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .eventCardDarkList {
    overflow: unset;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0rem 1rem 0rem;
    min-width: 100%;
    gap: 1rem;
    align-self: flex-start;
    flex: 1 1 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}

.eventCardsBlockEventsDarkBlockEmpty {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  align-self: stretch;
  flex: 0 0 auto;
  background-color: rgb(0, 19, 43);

  .blockHeader {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0rem 1rem 0rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .eventsEmptyState {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}

.eventCardsBlockEventsDarkBlockLoading {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  align-self: stretch;
  flex: 0 0 auto;
  background-color: rgb(0, 19, 43);

  .blockHeader {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0rem;

    >* {
      margin-left: 0.5rem;
    }

    align-self: stretch;
    flex: 0 0 auto;
  }

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;

    >* {
      margin-left: 0.625rem;
    }

    align-self: stretch;
    flex: 0 0 22.6875rem;
  }

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    height: 2.5rem;
    flex: 0 0 2.5rem;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}

.eventCardsBlockEventsVenueBlockDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  align-self: stretch;
  flex: 0 0 auto;

  .container {
    display: flex;
    overflow: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 1rem 0rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .eventCardVenueList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0.125rem 1rem 0.125rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .showMoreButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    margin-top: 1.25rem;
  }

}

.eventCardsBlockEventsVenueBlockEmpty {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  align-self: stretch;
  flex: 0 0 auto;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0rem 1rem 0rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .eventsEmptyState {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}

.eventCardsBlockEventsVenueBlockLoading {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  align-self: stretch;
  flex: 0 0 auto;

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;

    >* {
      margin-left: 0.625rem;
    }

    align-self: stretch;
    flex: 0 0 22.6875rem;
  }

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    height: 2.5rem;
    flex: 0 0 2.5rem;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}

.eventCardsBlockEventsNoImageBlockDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  align-self: stretch;
  flex: 0 0 auto;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 1rem 0rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .eventCardNoImageList {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0rem 1rem 0rem;

    >* {
      margin: 0.75rem 0.125rem 0rem 0.125rem;
    }

    align-self: stretch;
    flex: 0 0 auto;
  }

  .showMoreButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    margin-top: 1.25rem;
  }

}

.eventCardsBlockEventsNoImageBlockEmpty {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  align-self: stretch;
  flex: 0 0 auto;

  .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0rem 1rem 0rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .eventsEmptyState {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}

.eventCardsBlockEventsNoImageBlockLoading {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  align-self: stretch;
  flex: 0 0 auto;

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;

    >* {
      margin-left: 0.625rem;
    }

    align-self: stretch;
    flex: 0 0 12.6875rem;
  }

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    height: 2.5rem;
    flex: 0 0 2.5rem;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}

.eventCardsBlockPerformersLightBlockDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  align-self: stretch;
  flex: 0 0 auto;

  .blockHeader {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .container {
    display: flex;
    overflow: auto;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .performerCardList {
    overflow: unset;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0rem 1rem 0rem;
    min-width: 100%;
    gap: 1rem;
    align-self: flex-start;
    flex: 1 1 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}

.eventCardsBlockPerformersLightBlockEmpty {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  align-self: stretch;
  flex: 0 0 auto;

  .blockHeader {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0rem 1rem 0rem;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .eventsEmptyState {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}

.eventCardsBlockPerformersLightBlockLoading {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem 1rem 0rem 1rem;
  align-self: stretch;
  flex: 0 0 auto;

  .blockHeader {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 0rem 0rem 0rem;

    >* {
      margin-left: 0.5rem;
    }

    align-self: stretch;
    flex: 0 0 auto;
  }

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;

    >* {
      margin-left: 0.625rem;
    }

    align-self: stretch;
    flex: 0 0 14.625rem;
  }

  .image {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    height: 2.5rem;
    flex: 0 0 2.5rem;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

}

@media (min-width: 768px) {
  .eventCardsBlockCardSpecificEventsDarkBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    background-color: rgb(0, 19, 43);

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .cardSpecificEventsList {
      overflow: unset;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      min-width: 100%;
      gap: 1.5rem;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

  }
}

@media (min-width: 768px) {
  .eventCardsBlockCardSpecificEventsDarkBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    background-color: rgb(0, 19, 43);

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;

      >* {
        margin-left: 0.5rem;
      }

      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 0rem 1rem 0rem;

      >* {
        margin-top: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 32.9375rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 2.5rem;
      flex: 0 0 2.5rem;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 768px) {
  .eventCardsBlockEventsLightBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventCardLightList {
      overflow: unset;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      min-width: 100%;
      gap: 1.5rem;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 768px) {
  .eventCardsBlockEventsLightBlockEmpty {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventsEmptyState {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 768px) {
  .eventCardsBlockEventsLightBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;

      >* {
        margin-left: 0.5rem;
      }

      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 0rem 1rem 0rem;

      >* {
        margin-top: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 22.6875rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 2.5rem;
      flex: 0 0 2.5rem;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 768px) {
  .eventCardsBlockEventsDarkBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    background-color: rgb(0, 19, 43);

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventCardDarkList {
      overflow: unset;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      min-width: 100%;
      gap: 1.5rem;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 768px) {
  .eventCardsBlockEventsDarkBlockEmpty {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    background-color: rgb(0, 19, 43);

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventsEmptyState {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 768px) {
  .eventCardsBlockEventsDarkBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    background-color: rgb(0, 19, 43);

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;

      >* {
        margin-left: 0.5rem;
      }

      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 0rem 1rem 0rem;

      >* {
        margin-top: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 22.6875rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 2.5rem;
      flex: 0 0 2.5rem;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 768px) {
  .eventCardsBlockEventsVenueBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0rem 0rem 1rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventCardVenueList {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0.125rem 1rem 0.125rem;


      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .showMoreButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      margin-top: 1.5rem;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 768px) {
  .eventCardsBlockEventsVenueBlockEmpty {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventsEmptyState {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 768px) {
  .eventCardsBlockEventsVenueBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 0rem 1rem 0rem;

      >* {
        margin-top: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 22.6875rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 2.5rem;
      flex: 0 0 2.5rem;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 768px) {
  .eventCardsBlockEventsNoImageBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0rem 0rem 1rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventCardNoImageList {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0rem 0rem 0rem 0rem;

      >* {
        margin: 0.75rem 0.125rem 0rem 0.125rem;
      }

      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .showMoreButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      margin-top: 1.5rem;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 768px) {
  .eventCardsBlockEventsNoImageBlockEmpty {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventsEmptyState {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 768px) {
  .eventCardsBlockEventsNoImageBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 0rem 1rem 0rem;

      >* {
        margin-top: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 22.6875rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 2.5rem;
      flex: 0 0 2.5rem;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 768px) {
  .eventCardsBlockPerformersLightBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .performerCardList {
      overflow: unset;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      min-width: 100%;
      gap: 1.5rem;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 768px) {
  .eventCardsBlockPerformersLightBlockEmpty {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventsEmptyState {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 767px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 768px) {
  .eventCardsBlockPerformersLightBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;

      >* {
        margin-left: 0.5rem;
      }

      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1rem 0rem 1rem 0rem;

      >* {
        margin-top: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 14.625rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 2.5rem;
      flex: 0 0 2.5rem;

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1024px) {
  .eventCardsBlockCardSpecificEventsDarkBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    background-color: rgb(0, 19, 43);

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .cardSpecificEventsList {
      overflow: unset;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      min-width: 100%;
      gap: 1.5rem;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .sliderInfo {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

  }
}

@media (min-width: 1024px) {
  .eventCardsBlockCardSpecificEventsDarkBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    background-color: rgb(0, 19, 43);

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 33.625rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 4rem;
      flex: 0 0 4rem;

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1024px) {
  .eventCardsBlockEventsLightBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;

      // MANUAL OVERRIDE - removed margin-left for all children of .blockHeader

      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      // MANUAL OVERRIDE - removed margin-top for all children of .container

      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventCardLightList {
      overflow: unset;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      min-width: 100%;
      gap: 1.5rem;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .sliderInfo {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1024px) {
  .eventCardsBlockEventsLightBlockEmpty {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventsEmptyState {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1024px) {
  .eventCardsBlockEventsLightBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;

      >* {
        margin-left: 0.5rem;
      }

      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >* {
        margin-top: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 24.6875rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 4rem;
      flex: 0 0 4rem;

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1024px) {
  .eventCardsBlockEventsDarkBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    background-color: rgb(0, 19, 43);

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventCardDarkList {
      overflow: unset;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      min-width: 100%;
      gap: 1.5rem;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .sliderInfo {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1024px) {
  .eventCardsBlockEventsDarkBlockEmpty {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    background-color: rgb(0, 19, 43);

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow: hidden;
      padding: 1rem 0rem 1rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventsEmptyState {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1024px) {
  .eventCardsBlockEventsDarkBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    background-color: rgb(0, 19, 43);

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;

      >* {
        margin-left: 0.5rem;
      }

      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >* {
        margin-top: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 24.6875rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 4rem;
      flex: 0 0 4rem;

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1024px) {
  .eventCardsBlockEventsVenueBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0rem 0rem 1rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventCardVenueList {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0.125rem 1rem 0.125rem;


      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .showMoreButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      margin-top: 1.5rem;

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1024px) {
  .eventCardsBlockEventsVenueBlockEmpty {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventsEmptyState {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1024px) {
  .eventCardsBlockEventsVenueBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >* {
        margin-top: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 24.6875rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 4rem;
      flex: 0 0 4rem;

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1024px) {
  .eventCardsBlockEventsNoImageBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0rem 0rem 1rem 0rem;
      // > * {
      //   margin-top: 0.625rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventCardNoImageList {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;

      >* {
        margin: 1rem 0.125rem 0rem 0.125rem; // MANUAL OVERRIDE
      }

      align-self: flex-start;
      width: 100%; // MANUAL OVERRIDE
      flex: 0 0 auto; // MANUAL OVERRIDE

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .showMoreButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      margin-top: 1.5rem; // MANUAL OVERRIDE

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1024px) {
  .eventCardsBlockEventsNoImageBlockEmpty {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      // > * {
      //   margin-top: 0.625rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventsEmptyState {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1024px) {
  .eventCardsBlockEventsNoImageBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >* {
        margin-top: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 24.6875rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 4rem;
      flex: 0 0 4rem;

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1024px) {
  .eventCardsBlockPerformersLightBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1023px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;
      // > * {
      //   margin-left: 0.5rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      overflow: hidden;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      // > * {
      //   margin-top: 0.625rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .performerCardList {
      overflow: unset; // MANUAL OVERRIDE
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap; // MANUAL OVERRIDE
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      min-width: 100%; // MANUAL OVERRIDE
      gap: 1.5rem; // MANUAL OVERRIDE
      // > * {
      //   margin-top: 1.5rem;
      //   margin-left: 1.5rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .sliderInfo {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1024px) {
  .eventCardsBlockPerformersLightBlockEmpty {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;
      // > * {
      //   margin-left: 0.5rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      // > * {
      //   margin-top: 0.625rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventsEmptyState {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1023px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1024px) {
  .eventCardsBlockPerformersLightBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 2rem 0rem 2rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 1.5rem 0rem 0rem 0rem;

      >* {
        margin-left: 0.5rem;
      }

      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >* {
        margin-top: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 16.625rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 4rem;
      flex: 0 0 4rem;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1440px) {
  .eventCardsBlockCardSpecificEventsDarkBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 3rem 0rem 3rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    background-color: rgb(0, 19, 43);

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 2rem 0rem 0rem 0rem;
      // > * {
      //   margin-left: 0.5rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      // > * {
      //   margin-top: 0.625rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .cardSpecificEventsList {
      overflow: unset; // MANUAL OVERRIDE
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap; // MANUAL OVERRIDE
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      min-width: 100%; // MANUAL OVERRIDE
      gap: 2rem; // MANUAL OVERRIDE
      // > * {
      //   margin-top: 2rem;
      //   margin-left: 2rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .sliderInfo {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

  }
}

@media (min-width: 1440px) {
  .eventCardsBlockCardSpecificEventsDarkBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 3rem 0rem 3rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    background-color: rgb(0, 19, 43);

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 2rem 0rem 0rem 0rem;

      >* {
        margin-left: 0.5rem;
      }

      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >* {
        margin-top: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 33.625rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 4rem;
      flex: 0 0 4rem;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1440px) {
  .eventCardsBlockEventsLightBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 3rem 0rem 3rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 2rem 0rem 0rem 0rem;
      // > * {
      //   margin-left: 0.5rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      overflow: hidden; // MANUAL OVERRIDE
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      // > * {
      //   margin-top: 0.625rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventCardLightList {
      overflow: unset; // MANUAL OVERRIDE
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap; // MANUAL OVERRIDE
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      min-width: 100%; // MANUAL OVERRIDE
      gap: 2rem; // MANUAL OVERRIDE
      // > * {
      //   margin-top: 2rem;
      //   margin-left: 2rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .sliderInfo {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1440px) {
  .eventCardsBlockEventsLightBlockEmpty {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 3rem 0rem 3rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 2rem 0rem 0rem 0rem;
      // > * {
      //   margin-left: 0.5rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      // > * {
      //   margin-top: 0.625rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventsEmptyState {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1440px) {
  .eventCardsBlockEventsLightBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 3rem 0rem 3rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 2rem 0rem 0rem 0rem;

      >* {
        margin-left: 0.5rem;
      }

      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >* {
        margin-top: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 24.6875rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 4rem;
      flex: 0 0 4rem;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1440px) {
  .eventCardsBlockEventsDarkBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 3rem 0rem 3rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    background-color: rgb(0, 19, 43);

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 2rem 0rem 0rem 0rem;
      // > * {
      //   margin-left: 0.5rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      // > * {
      //   margin-top: 0.625rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventCardDarkList {
      overflow: unset; // MANUAL OVERRIDE
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap; // MANUAL OVERRIDE
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      min-width: 100%; // MANUAL OVERRIDE
      gap: 2rem; // MANUAL OVERRIDE
      // > * {
      //   margin-top: 2rem;
      //   margin-left: 2rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .sliderInfo {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1440px) {
  .eventCardsBlockEventsDarkBlockEmpty {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 3rem 0rem 3rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    background-color: rgb(0, 19, 43);

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 2rem 0rem 0rem 0rem;
      // > * {
      //   margin-left: 0.5rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      // > * {
      //   margin-top: 0.625rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventsEmptyState {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1440px) {
  .eventCardsBlockEventsDarkBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 3rem 0rem 3rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    background-color: rgb(0, 19, 43);

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 2rem 0rem 0rem 0rem;

      >* {
        margin-left: 0.5rem;
      }

      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >* {
        margin-top: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 24.6875rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 4rem;
      flex: 0 0 4rem;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1440px) {
  .eventCardsBlockEventsVenueBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 3rem 0rem 3rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0rem 0rem 1rem 0rem;
      // > * {
      //   margin-top: 0.625rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventCardVenueList {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0.125rem 1rem 0.125rem; // MANUAL OVERRIDE

      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .showMoreButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      margin-top: 1.5rem; // MANUAL OVERRIDE

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1440px) {
  .eventCardsBlockEventsVenueBlockEmpty {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 3rem 0rem 3rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      // > * {
      //   margin-top: 0.625rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventsEmptyState {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1440px) {
  .eventCardsBlockEventsVenueBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 3rem 0rem 3rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >* {
        margin-top: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 24.6875rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 4rem;
      flex: 0 0 4rem;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1440px) {
  .eventCardsBlockEventsNoImageBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 3rem 0rem 3rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0rem 0rem 1rem 0rem;
      // > * {
      //   margin-top: 0.625rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventCardNoImageList {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;

      >* {
        margin: 1rem 0.125rem 0rem 0.125rem; // MANUAL OVERRIDE
      }

      align-self: flex-start;
      width: 100%; // MANUAL OVERRIDE
      flex: 0 0 auto; // MANUAL OVERRIDE

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .showMoreButton {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      margin-top: 1.5rem; // MANUAL OVERRIDE

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1440px) {
  .eventCardsBlockEventsNoImageBlockEmpty {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 3rem 0rem 3rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      // > * {
      //   margin-top: 0.625rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventsEmptyState {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1440px) {
  .eventCardsBlockEventsNoImageBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 3rem 0rem 3rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >* {
        margin-top: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 24.6875rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 4rem;
      flex: 0 0 4rem;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .blockHeader {
      display: none;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1440px) {
  .eventCardsBlockPerformersLightBlockDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 3rem 0rem 3rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 2rem 0rem 0rem 0rem;
      // > * {
      //   margin-left: 0.5rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      // > * {
      //   margin-top: 0.625rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .performerCardList {
      overflow: unset; // MANUAL OVERRIDE
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap; // MANUAL OVERRIDE
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      min-width: 100%; // MANUAL OVERRIDE
      gap: 2rem; // MANUAL OVERRIDE
      // > * {
      //   margin-top: 2rem;
      //   margin-left: 2rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .sliderInfo {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1440px) {
  .eventCardsBlockPerformersLightBlockEmpty {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 3rem 0rem 3rem;
    align-self: stretch;
    flex: 0 0 auto;

    @media (max-width: 1439px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 2rem 0rem 0rem 0rem;
      // > * {
      //   margin-left: 0.5rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1rem 0rem 1rem 0rem;
      // > * {
      //   margin-top: 0.625rem;
      // }
      align-self: stretch;
      flex: 0 0 auto;
    }

    .eventsEmptyState {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;

      @media (max-width: 1439px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}

@media (min-width: 1440px) {
  .eventCardsBlockPerformersLightBlockLoading {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 3rem 0rem 3rem;
    align-self: stretch;
    flex: 0 0 auto;

    &:nth-child(1) {
      margin-top: 0;
    }

    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 2rem 0rem 0rem 0rem;

      >* {
        margin-left: 0.5rem;
      }

      align-self: stretch;
      flex: 0 0 auto;
    }

    .container {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >* {
        margin-top: 0.625rem;
      }

      align-self: stretch;
      flex: 0 0 16.625rem;
    }

    .image {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      width: 4rem;
      flex: 0 0 4rem;

      &:nth-child(1) {
        margin-top: 0;
      }
    }

    .cardSpecificEventsList {
      display: none;
    }

  }
}