.secondaryBannerDarkBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  background-color: rgb(0, 19, 43);

  .secondaryBanner {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 768px) {
    padding: 2rem 2rem 2rem 2rem;

  }
  @media (min-width: 1024px) {

    .secondaryBanner {
      height: 14.25rem; // MANUAL OVERRIDE
    }
    
  }
  @media (min-width: 1440px) {
    padding: 1rem 3rem 1rem 3rem;

  }
}
