.containerWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;

  .hideSlideButton {
    display: none;
  }

  .slideBackwardButton {
    position: absolute;
    top: 50%; 
    left: 1.5rem; 
    transform: translate(0, -50%);
  }
  
  .slideForwardButton {
    position: absolute;
    top: 50%; 
    right: 1.5rem;
    transform: translate(0, -50%);
  }
}

.snapItemListContainer {
  scroll-snap-type: x mandatory;
  overflow: auto;
  width: 100vw;
  display: flex;
  align-self: stretch;

  .singleImageContainer {
    scroll-snap-stop: always;
    scroll-snap-align: end;
    display: flex;
    height: 100%;
    width: 100vw;
  }

  .doubleImageContainer {
    scroll-snap-stop: always;
    scroll-snap-align: end;
    display: flex;
    height: 100%;
    width: 50vw;
  }

  .multipleImageContainer {
    scroll-snap-stop: always;
    scroll-snap-align: end;
    display: flex;
    height: 100%;
    width: 50vw;

    @media (min-width: 1024px) {
      width: 33.333333333333336vw;
    }
  }
}