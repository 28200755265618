.c1XEventDetailsSliderBlock {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 11.25rem;

  .imageList {
    overflow: unset; // MANUAL OVERRIDE
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; // MANUAL OVERRIDE
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 100%; // MANUAL OVERRIDE
    // gap: 0.625rem; // MANUAL OVERRIDE
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  // MANUAL OVERRIDE STARTS
  .container {
    display: flex;
    overflow: scroll;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }
  // MANUAL OVERRIDE ENDS
  
  // MANUAL OVERRIDE
  .slideBackwardButton {
    display: none;
  }
  
  // MANUAL OVERRIDE
  .slideForwardButton {
    display: none;
  }
  
  @media (min-width: 768px) {
    height: 20rem;

  }
  @media (min-width: 1024px) {
    background-color: rgb(244, 244, 244);
    height: 23.75rem;

    // MANUAL OVERRIDE
    .slideBackwardButton {
      display: flex;
      margin-left: 0.0625rem;
    }
    
    // MANUAL OVERRIDE
    .slideForwardButton {
      display: flex;
      margin-left: 0.0625rem;
    }

    // MANUAL OVERRIDE STARTS
    .container {
      overflow: hidden;
    }
    // MANUAL OVERRIDE ENDS
    
  }
  @media (min-width: 1440px) {
    height: 26.875rem;

  }
  @media (min-width: 1920px) {
    height: 32.5rem;

  }
}
