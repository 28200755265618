.searchResultsBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch; // MANUAL OVERRIDE 

  .menuContainer {
    display: none;
  }

  // MANUAL OVERRIDE STARTS
  .menuContainerMobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
  }

  .leftContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .rightContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }
  // MANUAL OVERRIDE ENDS
  
  // MANUAL OVERRIDE STARTS - renamed classes
  .upcomingCategory {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .upcomingReturnList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .venueCategory {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .venueReturnList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .performerCategory {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .performerReturnList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  // MANUAL OVERRIDE ENDS

  // MANUAL OVERRIDE - removed cities search results
  
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: center;
    padding: 0rem 0rem 3rem 0rem;
    background-color: rgb(16, 37, 63);

    .menuContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch; // MANUAL OVERRIDE
      flex: 1 1 auto; // MANUAL OVERRIDE
    }

    // MANUAL OVERRIDE STARTS
    .menuContainerMobile {
      display: none;
    }

    .leftContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      max-width: 28.5rem;
    }

    .rightContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      max-width: 28.5rem;
      margin-left: 1rem;
    }
    // MANUAL OVERRIDE ENDS

    // MANUAL OVERRIDE - removed display none css for categories and return lists
  }

  // MANUAL OVERRIDE STARTS
  @media (min-width: 1440px) {
    flex-direction: row;
    justify-content: center;
    padding: 0rem 0rem 3rem 0rem;
    background-color: rgb(16, 37, 63);

    .menuContainerMobile {
      display: none;
    }

    .leftContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      max-width: 34.25rem;
    }

    .rightContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      max-width: 34.25rem;
      margin-left: 1.5rem;
    }
  }
  // MANUAL OVERRIDE ENDS
}
