.exclusivesEventsLandingPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(0, 19, 43);

  .topContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .primaryBannerBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .exclusiveEvents {
    display: flex;
    width: unset; // MANUAL OVERRIDE
  }
  
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .filtersBlock {
    display: flex;
    width: unset; // MANUAL OVERRIDE
    align-self: stretch; // MANUAL OVERRIDE
  }
  
  .sportsEvents {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .musicEvents {
    display: flex;
    width: unset; // MANUAL OVERRIDE
    align-self: stretch; // MANUAL OVERRIDE
  }
  
  .diningEvents {
    display: flex;
    width: unset; // MANUAL OVERRIDE
    align-self: stretch; // MANUAL OVERRIDE
  }
  
  .getawaysEvents {
    display: flex;
    width: unset; // MANUAL OVERRIDE
    align-self: stretch; // MANUAL OVERRIDE
  }
  
  .artTheaterEvents {
    display: flex;
    width: unset; // MANUAL OVERRIDE
    align-self: stretch; // MANUAL OVERRIDE
  }
  
  .secondaryBannerDarkBlock {
    display: flex;
    width: unset;
    align-self: stretch;
    padding: 2rem 1rem 1rem 1rem; // MANUAL OVERRIDE
  }
  
  @media (min-width: 768px) {

    .topContainer {
      background-color: rgb(0, 19, 43);
    }
    
    .exclusiveEvents {
      width: unset;
      align-self: stretch;
    }
    
    .filtersBlock {
      width: 45rem; // MANUAL OVERRIDE
    }
    
    .musicEvents {
      width: unset;
      align-self: stretch;
    }
    
    .diningEvents {
      width: unset;
      align-self: stretch;
    }
    
    .getawaysEvents {
      width: unset;
      align-self: stretch;
    }
    
    .artTheaterEvents {
      width: unset;
      align-self: stretch;
    }

    // MANUAL OVERRIDE STARTS
    .secondaryBannerDarkBlock {
      padding: 3rem 2rem 2rem 2rem;
    }
    // MANUAL OVERRIDE ENDS
    
  }
  @media (min-width: 1024px) {

    .container {
      width: 64rem;
      // MANUAL OVERRIDE - removed margin-top
      align-self: center; // MANUAL OVERRIDE
    }
    
    .filtersBlock {
      width: 64rem;
    }

    // MANUAL OVERRIDE STARTS
    .exclusiveEvents {
      width: 64rem;
      align-self: center;
    }
    // MANUAL OVERRIDE ENDS
    
  }
  @media (min-width: 1440px) {

    .topContainer {
      background-color: rgb(0, 19, 43);
    }
    
    .exclusiveEvents {
      width: 90rem;
    }
    
    .container {
      width: 90rem;
      margin-top: 0;
      padding: 1.5rem 0rem 1rem 0rem; // MANUAL OVERRIDE
    }
    
    .filtersBlock {
      width: unset;
      align-self: stretch;
    }

    // MANUAL OVERRIDE STARTS
    .secondaryBannerDarkBlock {
      padding: 2rem 3rem 1rem 3rem;
    }
    // MANUAL OVERRIDE ENDS
    
  }
  @media (min-width: 1920px) {

    .topContainer {
      align-items: center;
    }
    
    .container {
      padding: 1.5rem 0rem 1rem 0rem; // MANUAL OVERRIDE
    }
    
  }
}
