.homepage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(244, 244, 244);

  .topContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .searchBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .primaryBannerBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .exclusivesEventBlock {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    width: unset;
    align-self: stretch;
    margin-top: 0; // MANUAL OVERRIDE
  }

  // MANUAL OVERRIDE - removed styles that hid exclusivesEventBlock
  
  .eventListingBlock {
    display: flex;
    width: unset;
    align-self: stretch;
    margin: 1.5rem 0rem; // MANUAL OVERRIDE
  }
  
  @media (min-width: 1024px) {

    // MANUAL OVERRIDE - showing exclusivesEventBlock in desktop breakpoint
    
    .container {
      padding: 0rem 0rem 0rem 0rem; // MANUAL OVERRIDE
      width: 60rem;
      height: unset;
      flex: 1 1 auto;
      margin-top: 0;
      align-self: center; // MANUAL OVERRIDE
    }
    
    .exclusivesEventBlock {
      display: flex;
      width: unset;
      align-self: stretch;
      margin-top: 1.5rem; // MANUAL OVERRIDE
    }
    
    .eventListingBlock {

     > * { // MANUAL OVERRIDE
        margin-top: 1.5rem;
      }
    }
    
  }
}
