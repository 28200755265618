.dropdownSelectEmpty {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.84rem 1rem 0.84rem 1rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(105, 105, 105);
  outline-offset: 0;

  .text {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.625rem;
  }
  
  &:active {
    outline: 2px solid rgb(2, 101, 151);
  
    .text {
      height: unset;
    }
    
  }
  &:disabled {
    background-color: rgb(244, 244, 244);
    outline: 1px solid rgb(212, 212, 212);
  
    .text {
      height: unset;
    }
    
  }
  &:error {
    outline: 2px solid rgb(204, 36, 39);
  
    .text {
      height: unset;
    }
    
  }
  &:focus-visible {
    outline: 2px solid rgb(2, 101, 151); // MANUAL OVERRIDE
  
    .text {
      height: unset;
    }
    
  }
}

.dropdownSelectFilled {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.84rem 1rem 0.84rem 1rem; // MANUAL OVERRIDE
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(105, 105, 105);
  outline-offset: 0;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.625rem;
  }
  
  &:active {
    outline: 2px solid rgb(2, 101, 151);
  
  }
  &:disabled {
    background-color: rgb(244, 244, 244);
    outline: 1px solid rgb(212, 212, 212);
  
  }
  &:error {
    outline: 2px solid rgb(204, 36, 39);
  
  }
  &:focus-visible {
    outline: 2px solid rgb(2, 101, 151); // MANUAL OVERRIDE
  
  }
}
