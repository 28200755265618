// this class is used for custom styles for primary banner in HomePage
.primaryBannerHomePage {
  display: flex;
  justify-content: flex-start !important;
  background-image: linear-gradient(0deg, rgba(0, 19, 43, 0.6), rgba(0, 19, 43, 0.6)), url('../../../resources/images/HomeImage.png'); // MANUAL OVERRIDE
  background-size: cover;
  background-position-x: right;

  @media (min-width: 768px) {
    background-image: url('../../../resources/images/HomeImage.png');
    background-size: cover;
    background-position: right;
  }
}

// this class is used for custom styles for primary banner in ExclusivesEventLandingPage
.primaryBannerC1XLandingPage {
  background-image: linear-gradient(0deg, rgba(0, 19, 43, 0.6), rgba(0, 19, 43, 0.6)), url("../../../resources/images/C1XLandingPageHero1.png"); // MANUAL OVERRIDE
  background-size: cover;
  background-position-x: 67%;
  background-repeat: no-repeat;
  justify-content: flex-start;

  .content {
    height: 14.5rem;
  }

  .textContainer {
    margin-top: 0.625rem;
    height: 100%;
  }

  .messageContent {
    margin-left: 0 !important;
    margin-bottom: auto;
    margin-top: auto;
  }

  @media (min-width: 768px) {
    background-image: url("../../../resources/images/C1XLandingPageHero1.png");
    background-size: cover;
    background-position-x: 96%;
    background-repeat: no-repeat;
  }

  @media (min-width: 1024px) {
    background-image: url("../../../resources/images/C1XLandingPageHero1.png");
    background-size: cover;
    background-position-x: right;
    background-repeat: no-repeat;
  }
}