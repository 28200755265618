.performerLandingPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }

  .imageBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .filtersBlock {
    display: flex;
    align-self: stretch; // MANUAL OVERRIDE
    width: auto; // MANUAL OVERRIDE
  }

  .eventCardsBlock {
    display: flex;
    width: auto; // MANUAL OVERRIDE
    align-self: stretch;
  }

  @media (min-width: 768px) {

    .container {
      height: unset;
    }

    .filtersBlock {
      width: unset;
      align-self: stretch;
    }

    .eventCardsBlock {
      align-self: stretch; // MANUAL OVERRIDE
      width: auto; // MANUAL OVERRIDE
      flex: 0 0 auto; // MANUAL OVERRIDE
    }

  }

  @media (min-width: 1024px) {

    .filtersBlock {
      width: 64rem;
    }

    .eventCardsBlock {
      width: auto; // MANUAL OVERRIDE
    }

  }

  @media (min-width: 1440px) {

    .container {
      width: 90rem;
      align-self: center; // MANUAL OVERRIDE
      flex: 0 0 auto; // MANUAL OVERRIDE
    }

    .filtersBlock {
      width: unset;
      align-self: stretch;
    }

    .eventCardsBlock {
      width: auto; // MANUAL OVERRIDE
    }

  }
}