.ticketListDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch; // MANUAL OVERRIDE
  overflow: auto; // MANUAL OVERRIDE

  .ticketInformation {
    display: flex;
    flex-direction: column; // MANUAL OVERRIDE
    width: unset;
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }

  // MANUAL OVERRIDE STARTS
  [role=button] {
    width: 100%;
  }
  // MANUAL OVERRIDE ENDS
}

.ticketListWithSpace {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;

  .ticketInformation {
    display: flex;
    width: 100%; // MANUAL OVERRIDE
    align-self: stretch;
  }

  // MANUAL OVERRIDE STARTS
  [role=button] {
    width: 100%;
  }
  // MANUAL OVERRIDE ENDS
}

