.blockHeaderDarkNoButton {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem 0rem 0rem 0rem;
  flex: 0 0 auto; // MANUAL OVERRIDE

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  @media (min-width: 1440px) {
    padding: 2rem 0rem 0rem 0rem;

  }
}

.blockHeaderDarkWithButton {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem 0rem 0rem 0rem;
  flex: 0 0 auto; // MANUAL OVERRIDE

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    margin-left: 0.5rem;
  }

  // MANUAL OVERRIDE STARTS
  .icon {
    overflow: visible;
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;

    & svg {
      height: 1rem !important; // To override height: 100% from icon component
      margin-top: 0.25rem; // To keep icon exactly in the middle
    }
  }
  // MANUAL OVERRIDE ENDS
  
  @media (min-width: 1440px) {
    padding: 2rem 0rem 0rem 0rem;

  }
}

.blockHeaderLightNoButton {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem 0rem 0rem 0rem;
  flex: 0 0 auto; // MANUAL OVERRIDE

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  @media (min-width: 1440px) {
    padding: 2rem 0rem 0rem 0rem;

  }
}

.blockHeaderLightWithButton {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem 0rem 0rem 0rem;
  flex: 0 0 auto; // MANUAL OVERRIDE

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    margin-left: 0.5rem;
  }
  
  @media (min-width: 1440px) {
    padding: 2rem 0rem 0rem 0rem;

  }
}

.blockHeaderLightWithToolTip {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem 0rem 0rem 0rem;
  flex: 0 0 auto; // MANUAL OVERRIDE
  max-height: 4rem; // MANUAL OVERRIDE
  gap: 0.5rem; // MANUAL OVERRIDE

  .text {
    display: flex;
    // MANUAL OVERRIDE STARTS
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
    // MANUAL OVERRIDE ENDS
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }

  // MANUAL OVERRIDE STARTS
  .tooltip {
    overflow: hidden;
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  @media (min-width: 768px) {
    .tooltip {
      display: initial;
    }
  }
  // MANUAL OVERRIDE ENDS
  
  @media (min-width: 1440px) {
    padding: 2rem 0rem 0rem 0rem;

  }
}

// MANUAL OVERRIDE STARTS
.blockHeaderDarkWithToolTip {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem 0rem 0rem 0rem;
  max-height: 4rem;

  .text {
    display: flex;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }

  .tooltip {
    overflow: hidden;
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  @media (min-width: 768px) {
    .tooltip {
      display: initial;
    }
  }
  
  @media (min-width: 1440px) {
    padding: 2rem 0rem 0rem 0rem;

  }
}
// MANUAL OVERRIDE ENDS

// MANUAL OVERRIDE STARTS
.toolTipIcon {
  cursor: pointer;
}
// MANUAL OVERRIDE ENDS