.submenuWithDoubleTabs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);
  // MANUAL OVERRIDE STARTS
  align-self: stretch;
  // MANUAL OVERRIDE ENDS

  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50rem;
    align-self: flex-start; // MANUAL OVERRIDE
    width: auto; // MANUAL OVERRIDE
    margin-left: 2rem; // MANUAL OVERRIDE
  }
  
  .leftContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0rem 1rem 0rem;
    // width: 12.5rem;  // MANUAL OVERRIDE
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .sportsMenuTabList {
    display: flex;
    // width: unset; // MANUAL OVERRIDE
    flex: 1 1 auto;
  }
  
  .dividerOne {
    display: flex;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }
  
  .spacerContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0rem 1rem 0rem;
    width: 0.5rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0rem 1rem 0rem;
    width: 12.5rem;
  }
  
  .sportsMenuTabList1 {
    display: flex;
    // width: unset; // MANUAL OVERRIDE
    flex: 1 1 auto;
  }
  
  .dividerTwo {
    display: flex;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }
  
  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 0rem 1rem 0.75rem; // MANUAL OVERRIDE
    width: unset;
    flex: 1 1 auto;
  }
  
  .submenuItemListOne {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .submenuItemListTwo {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .divider1 {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }
  
}
