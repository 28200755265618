.eventCardVenue {
  overflow: hidden;
  display: flex;
  position: relative; // MANUAL OVERRIDE
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.10000000149011612);
  width: 16rem;
  height: 22.6875rem;
  text-decoration: none; // MANUAL OVERRIDE

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: unset;
    align-self: stretch;
    height: 10.625rem; // MANUAL OVERRIDE
    overflow: hidden; // MANUAL OVERRIDE
  }

  .image {
    display: flex;
    width: unset;
    height: 10.625rem;
    align-self: stretch;
    transition: transform 0.3s ease; // MANUAL OVERRIDE
  }

  .eventTypeTag {
    display: flex;
    // MANUAL OVERRIDE STARTS
    position: absolute;
    margin-right: 0.75rem;
    top: 12px;
    // MANUAL OVERRIDE ENDS
  }

  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 0.5rem 1rem; // MANUAL OVERRIDE
    width: unset;
    // height: 8.5rem; // MANUAL OVERRIDE
    align-self: stretch;
    flex: 1; // MANUAL OVERRIDE
  }

  .tag {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0.25rem 0rem;
    width: unset;
    align-self: stretch;
  }

  .status {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0.25rem 0rem;
    width: unset;
    align-self: stretch;
  }

  .eventStatusTag {
    display: flex;
  }

  .titleText {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .eventLogistics {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem; // MANUAL OVERRIDE
  }

  .dateAndTime {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .dateAndTimeText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }

  .locationAndPrice {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem; // MANUAL OVERRIDE
  }

  .locationAndPriceText {
    display: flex;
  }

  .actionContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 1rem 1rem;
    width: unset;
    align-self: stretch;
  }

  .divider {
    display: flex;
    width: unset;
    height: 0.0625rem;
    align-self: stretch;
  }

  .textIcon {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
    padding: 0 0.25rem 0 0; // MANUAL OVERRIDE
  }

  .actionText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }

  .icon {
    display: block;
    width: 2rem;
    height: 2rem;
    margin-left: 0.25rem;
  }

  // MANUAL OVERRIDE STARTS
  .button {
    display: none;
  }

  // MANUAL OVERRIDE ENDS

  &:focus-visible {
    outline: 2px dashed rgb(2, 118, 177);
    outline-offset: 0;
    height: unset;

  }

  &:hover {
    height: unset;

    // MANUAL OVERRIDE STARTS
    .image {
      transform: scale(1.2);
    }

    // MANUAL OVERRIDE ENDS

  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    padding: 0rem 1.5rem 0rem 0rem;
    width: unset;
    height: unset;

    .topContent {
      width: 15.875rem;
      height: 8.75rem;
      flex: 0 0 15.875rem; // MANUAL OVERRIDE
    }

    .content {
      width: unset; // MANUAL OVERRIDE
    }

    .bottomContent {
      height: unset;
      flex: 1 1 auto;
    }

    .actionContent {
      display: none;
      width: unset;
    }

    &:focus-visible {
      outline: 2px dashed rgb(2, 118, 177);
      outline-offset: 0;

    }

    .button {
      display: flex; // MANUAL OVERRIDE
      flex: 0 0 auto; // MANUAL OVERRIDE
    }

    // MANUAL OVERRIDE STARTS
    &:hover {
      .image {
        transform: scale(1.2);
      }
    }

    // MANUAL OVERRIDE ENDS
  }

  @media (min-width:1044px) {
    .eventLogistics {
      margin-top: 0; // MANUAL OVERRIDE
    }

    .dateAndTime {
      margin-top: 0.3125rem; // MANUAL OVERRIDE
    }

    .locationAndPrice {
      margin-top: 0.3125rem; // MANUAL OVERRIDE
    }
  }
}