.iconTextDescriptionDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .icon {
    display: flex; // MANUAL OVERRIDE
    width: 1.5rem;
    height: 1.5rem;
    align-self: flex-start; // MANUAL OVERRIDE
    flex: 0 0 1.5rem; // MANUAL OVERRIDE
  }
  
  .description {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.iconTextDescriptionWithLogo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .icon {
    display: flex; // MANUAL OVERRIDE
    width: 1.5rem;
    height: 1.5rem;
    align-self: center; // MANUAL OVERRIDE
  }
  
  .description {
    display: flex;
    width: unset;
    // flex: 1 1 auto; // MANUAL OVERRIDE
    margin-left: 0.5rem;
  }
  
  .logoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: unset;
    align-self: stretch;
    margin-left: 0.5rem;
  }
  
  .logo {
    display: block;
    max-width: 4rem; // MANUAL OVERRIDE
  }
  
}
