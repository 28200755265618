.submenuTabItemDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0rem 0rem 0.5rem;
  height: 2.25rem;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .divider {
    display: none; // MANUAL OVERRIDE - hide divider for default variant
  }
  
  &:focus-visible {
    background-color: rgb(244, 244, 244);
    outline: 2px dashed rgb(16, 37, 63);
    outline-offset: 0;
    // MANUAL OVERRIDE - removed height: unset;
  
    .text {
      height: auto; // MANUAL OVERRIDE
    }
    
    .divider {
      height: 2.25rem;
    }
    
  }
  &:hover {
    background-color: rgb(244, 244, 244);
  
    .text {
      height: auto; // MANUAL OVERRIDE
    }
    
    .divider {
      height: 2.25rem;
    }
    
  }
}

.submenuTabItemSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 0rem 0rem 0.5rem;
  background-color: rgb(244, 244, 244);

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .divider {
    display: block;
    width: 0.1875rem;
    height: 2.25rem;
    margin-left: 0.625rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(16, 37, 63);
    outline-offset: 0;
  
  }
}
