.orderInfo {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 1rem 1.5rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(212, 212, 212); // MANUAL OVERRIDE

  .titleContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between; // MANUAL OVERRIDE
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
  }

  .title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
  }

  .title1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .price {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .amount {
    display: flex;
  }

  .miles {
    display: flex;
  }

  .costBreakdown {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }

  .title2 {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .eventInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }

  .dateAndTime {
    display: flex;
    width: unset;
    // MANUAL OVERRIDE - removed static height
    align-self: stretch;
  }

  .venue {
    display: flex;
    width: unset;
    // MANUAL OVERRIDE - removed static height
    align-self: stretch;
  }

  .seatInfo {
    display: flex;
    width: unset;
    height: 2.875rem;
    align-self: stretch;
    margin-top: 1.5rem;
  }

  .sellerNotes {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }

  .deliveryMethod {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }

  .logo {
    display: block;
    width: 6.625rem; // MANUAL OVERRIDE
    height: 1.6875rem;
    margin-top: 1.5rem;
  }

  // MANUAL OVERRIDE STARTS
  .faceValue {
    margin-top: 1.5rem;
    align-self: stretch;
  }

  @media (max-width: 1023px) {
    .faceValue {
      padding: 0.5rem 0 0.5rem;
      border-top: 1px solid rgb(212, 212, 212);
      border-bottom: 1px solid rgb(212, 212, 212);
    }
  }

  // MANUAL OVERRIDE ENDS

}