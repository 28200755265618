.primaryBanner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0rem 1rem 2.5rem 1rem;
  background-size: cover;
  // MANUAL OVERRIDE - removed background position and repeat properties
  height: 17.5rem;

  .textContainer {
    display: none;
  }
  
  // MANUAL OVERRIDE STARTS
  .messageContentMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: unset;
    align-self: stretch;
  }

  .messageContent {
    display: none;
  }

  .content {
    display: none;
  }

  .leftContent {
    display: none;
  }
  // MANUAL OVERRIDE ENDS
  
  .title {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .divider {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
    height: 0.0625rem; // MANUAL OVERRIDE
  }
  
  .message {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: flex-end;
    padding: 3rem 0rem 4.5rem 0rem;
    height: 22.5rem;

    .textContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: unset;
      flex: 1 1 auto;
    }
    
    // MANUAL OVERRIDE STARTS
    .messageContentMobile {
      display: none;
      width: unset;
    }

    .messageContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: unset;
      align-self: stretch;
      flex: 1 1 auto;
      width: 50%;
      > * {
        margin-top: 1.5rem;
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
      margin-top: 0.5rem;
    }

    .leftContent {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 1.5rem;
      }
      align-self: flex-start;
      flex: 1 1 auto;
      width: 50%;
    }
    // MANUAL OVERRIDE ENDS
    
  }
}
