.buttonPair {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 100px;

  .primary {
    display: flex;
    // MANUAL OVERRIDE STARTS
    overflow: hidden;
    flex-direction: row;
    justify-content: stretch;
    align-items: flex-start;
    align-self: flex-start;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
      div:nth-child(1) {
        overflow: hidden;
        flex: 1 1 auto;
        > p {
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
        }
      }
    }
    // MANUAL OVERRIDE ENDS
  }

  .secondary {
    display: flex;
    margin-left: 0.0625rem;
    // MANUAL OVERRIDE STARTS
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.25rem 0.25rem 0.25rem;
    align-self: flex-start;
    flex: 0 0 auto;
    // MANUAL OVERRIDE ENDS
  }
  
  // MANUAL OVERRIDE STARTS
  .primary:focus-visible {
    border-radius: 100px 0 0 100px;
    outline-offset: -2px;
  }
  .secondary:focus-visible {
    border-radius: 0 100px 100px 0;
    outline-offset: -2px;
  }
  // MANUAL OVERRIDE ENDS

}