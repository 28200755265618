.stepperList {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap; // MANUAL OVERRIDE
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;

  .stepperItem {
    display: flex;
    width: unset;
    flex: 1 1 100%; // MANUAL OVERRIDE
  }
  
}
