.mainLayout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .navContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    background-color: rgb(16, 37, 63);
  }

  .topNav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: stretch;
    flex: 0 0 3rem;
  }
  .topNavDisable {
    @media (max-width: 1023px) {
      display: none;
      visibility: hidden;
    }
  }

  .homepage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 1 1 auto;
  }

  .footerContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: stretch;
    flex: 0 0 auto;
    background-color: rgb(244, 244, 244);
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }
}

@media (min-width: 1024px) {
  .mainLayout {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .navContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      background-color: rgb(16, 37, 63);
    }

    .topNav {
      padding-left: 2rem;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      align-self: center;
      width: 64rem;
      flex: 0 0 auto;
    }

    .topNavDisable {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      background-color: rgb(16, 37, 63);
    }

    .homepage {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 1 1 auto;
    }

    .footerContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      flex: 0 0 auto;
      background-color: rgb(244, 244, 244);
    }

    .footer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: center;
      flex: 0 0 auto;
      width: 60rem;
    }
  }
}
