.filterSetting {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 1rem 1rem 1rem;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
  }
  
  .label {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
  }
  
  .ticketQuantity {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .sliderFilter {
    display: flex;
    width: unset;
    align-self: stretch;
    // MANUAL OVERRIDE STARTS
    height: 4rem;
    justify-content: center;
    // MANUAL OVERRIDE ENDS
  }
  
  .toggleSetting {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1024px) {

    .topContent {
      display: none;
      width: unset;
    }
    
  }
}
