.toggleBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1rem 0.5rem 1rem;

  .toggleItemList {
    display: flex;
  }
  
  @media (min-width: 768px) {
    flex-direction: row;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;

  }
  @media (min-width: 1024px) {
    padding: 0.5rem 2rem 0.5rem 2rem;

  }
  @media (min-width: 1440px) {
    padding: 0.5rem 3rem 0.5rem 3rem;

  }
}
