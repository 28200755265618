.customerInfoBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1.5rem 1rem;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  .blockTitle {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0; // MANUAL OVERRIDE
  }
  
  .content {
    // MANUAL OVERRIDE STARTS - showing content in mobile breakpoint
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    // MANUAL OVERRIDE ENDS
  }
  
  .emailField {
    display: flex;
    width: unset;
    align-self: stretch;
    // MANUAL OVERRIDE - removed margin-top
  }

  // MANUAL OVERRIDE STARTS
  .icon {
    height: unset;
  }
  .button {
    display: none;
  }
  .emailLabel {
    > * {
      margin-top: 0.5rem;
    }
  }
  // MANUAL OVERRIDE ENDS
  
  @media (min-width: 1024px) {
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;

    .content {
      display: flex;
      flex-direction: row; // MANUAL OVERRIDE
      justify-content: flex-start;
      align-items: flex-start;
      width: unset;
      align-self: stretch;
      margin-top: 1rem;
    }
    
    .emailField {
      display: flex; // MANUAL OVERRIDE - showing emailField in desktop breakpoint
      width: unset;
      margin-top: 0;
    }

    // MANUAL OVERRIDE STARTS
    .button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5625rem 1rem 0.5625rem 1rem;
      align-self: flex-start;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    // there was a manual override for text variant in legacy version
    .blockTitle {
      h2 {
        font-size: 1.25rem;
      }
    }
    // MANUAL OVERRIDE ENDS
    
  }
}
